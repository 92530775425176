/**
 * Created by osirvent on 29/03/2017.
 */
angular
    .module('annexaApp')
    .controller('SecOrgansController',['$scope', '$rootScope', 'Language', '$filter', 'CommonAdminModals', 'SecFactory', 'RestService', 'AnnexaModalFactory', 'CommonService', 'AnnexaFormlyFactory', 'ErrorFactory', 'GlobalDataFactory', 'DialogsFactory', 'CacheFactory', 
    	function ($scope, $rootScope, Language, $filter, CommonAdminModals, SecFactory, RestService, AnnexaModalFactory, CommonService, AnnexaFormlyFactory, ErrorFactory, GlobalDataFactory, DialogsFactory, CacheFactory) {

        $scope.headButtons = [
            new HeadButton('btn primary breadDivButton mr-sm-pt',undefined,'#sec-organs','fa fa-plus','global.sec.literals.newCollegiateOrgan',undefined,'newCollegiateOrgan')
        ];

        var initializeModalModel = function () {
            return {
                row_name_location: {},
                row_location: {archiveClassification:{}},
                row_session_pattern: {globalVote: false}
            }
        }
        $scope.classifications = GlobalDataFactory.archiveClassifications;
        $scope.classificationTree = CommonService.getTreeData($scope.classifications, Language.getActiveColumn());
        $scope.modalSecOrgans;

        $scope.newCollegiateOrgan = function() {
            var modal =  angular.copy(CommonAdminModals.organCollegiateNew);
            modal.tabs[0].annexaFormly.model = initializeModalModel();
            modal.tabs[0].annexaFormly.options = { watchAllExpressions: true };
            modal.tabs[0].annexaFormly.fields[1].fieldGroup[1].templateOptions.options = $scope.classificationTree;
            AnnexaFormlyFactory.showModal('modalNewcollegiateOrgan', modal, $scope.saveOrgan, false)
        }

        $scope.addedAgendaPointTypes = [];

        $scope.addedMembers = [];

        $scope.saveOrgan = function(organ) {
            var modal = this;
            
            var model = {
                language1: organ.tabs[0].annexaFormly.model.row_name_location.language1,
                language2: organ.tabs[0].annexaFormly.model.row_name_location.language2 ? organ.tabs[0].annexaFormly.model.row_name_location.language2 : 'NotTranslated',
                language3: organ.tabs[0].annexaFormly.model.row_name_location.language3 ? organ.tabs[0].annexaFormly.model.row_name_location.language3 : 'NotTranslated',
                acronym: organ.tabs[0].annexaFormly.model.row_name_location.acronym,
                organType: organ.tabs[0].annexaFormly.model.row_name_location.organType,
                meetingLocation: organ.tabs[0].annexaFormly.model.row_location.meetingLocation ? { id: organ.tabs[0].annexaFormly.model.row_location.meetingLocation } : null,
                profiles: 	[],
                resolutionType: organ.tabs[0].annexaFormly.model.row_location.resolutionType ? organ.tabs[0].annexaFormly.model.row_location.resolutionType : null,
                organSessionPattern: organ.tabs[0].annexaFormly.model.row_session_pattern.organSessionPattern ? organ.tabs[0].annexaFormly.model.row_session_pattern.organSessionPattern : null, 
                organSessionDigits: organ.tabs[0].annexaFormly.model.row_session_pattern.organSessionDigits ? organ.tabs[0].annexaFormly.model.row_session_pattern.organSessionDigits : null,
                globalVote: organ.tabs[0].annexaFormly.model.row_session_pattern.globalVote,
                members: [],
                agendaPointTypes: [],
                viewProposalProfiles: [],
                viewProposalUsers: [],
                reviewProposalProfiles: [],
                reviewProposalUsers: [],
                validateProposalProfiles: [],
                validateProposalUsers: [],
                acceptProposalProfiles: [],
                acceptProposalUsers: [],
                everyoneCanView: false
            };
            if(organ.tabs[0].annexaFormly.model.row_location.archiveClassification && organ.tabs[0].annexaFormly.model.row_location.archiveClassification.$selected && organ.tabs[0].annexaFormly.model.row_location.archiveClassification.$selected.id){
            	model.archiveClassification = { id: organ.tabs[0].annexaFormly.model.row_location.archiveClassification.$selected.id };
            }
            if(organ.tabs[0].annexaFormly.model.row_location.profiles && organ.tabs[0].annexaFormly.model.row_location.profiles.length > 0){
            	_.forEach(organ.tabs[0].annexaFormly.model.row_location.profiles, function(profile){
            		model.profiles.push({profile:{id: profile}});
            	});
            }
            if(organ.tabs[1].annexaFormly.fields && organ.tabs[1].annexaFormly.fields.length > 0 && organ.tabs[1].annexaFormly.fields[0] && organ.tabs[1].annexaFormly.fields[0].fieldGroup && organ.tabs[1].annexaFormly.fields[0].fieldGroup.length > 0 && organ.tabs[1].annexaFormly.fields[0].fieldGroup[0].data && organ.tabs[1].annexaFormly.fields[0].fieldGroup[0].data.members){
	            _.forEach(organ.tabs[1].annexaFormly.fields[0].fieldGroup[0].data.members, function(value) {
	            	var member = {
	            		organMemberGlobal:((value.organMemberGlobal)?{id:value.organMemberGlobal.id}:undefined),
	            		attendeeAddressConvene:((value.attendeeAddressConvene)?{id:value.attendeeAddressConvene.id}:undefined),
		        		memberRol:((value.memberRol)?{id:value.memberRol.id}:undefined),
		        		vote:((value.vote)?value.vote:false),
		        		orderView:((value.orderView)?value.orderView:-1)
	            	}
	                model.members.push(member);
	            });
            }
            if(organ.tabs[2].annexaFormly.fields && organ.tabs[2].annexaFormly.fields.length > 0 && organ.tabs[2].annexaFormly.fields[0] && organ.tabs[2].annexaFormly.fields[0].fieldGroup && organ.tabs[2].annexaFormly.fields[0].fieldGroup.length > 0 && organ.tabs[2].annexaFormly.fields[0].fieldGroup[0].data && organ.tabs[2].annexaFormly.fields[0].fieldGroup[0].data.pointTypes){
	            _.forEach(organ.tabs[2].annexaFormly.fields[0].fieldGroup[0].data.pointTypes, function(value) {
	            	var pointType = {
	        			language1:((value.language1)?value.language1:undefined),
	        			language2:((value.language2)?value.language2:undefined),
	        			language3:((value.language3)?value.language3:undefined),
	        			type:((value.type && value.type.id)?value.type.id:undefined),
	        			template:((value.template && value.template.id)?{id:value.template.id}:undefined),
	        			vote:((value.vote)?value.vote:false),
	        			orderView:((value.orderView)?value.orderView:-1)
		        	};
	                model.agendaPointTypes.push(pointType);
	            });
            }
            if(organ.tabs[3].annexaFormly.model && organ.tabs[3].annexaFormly.model.row_proposalPremissions){
            	if(organ.tabs[3].annexaFormly.model.row_proposalPremissions.everyoneCanView_1) {
            		model.everyoneCanView = organ.tabs[3].annexaFormly.model.row_proposalPremissions.everyoneCanView_1;
            	}
            	if(organ.tabs[3].annexaFormly.model.row_proposalPremissions.profilesView && organ.tabs[3].annexaFormly.model.row_proposalPremissions.profilesView.length > 0) {
            		_.forEach(organ.tabs[3].annexaFormly.model.row_proposalPremissions.profilesView, function(value) {
    	            	var profView = {profile:{id:value}};
    	                model.viewProposalProfiles.push(profView);
    	            });
            	}
            	if(organ.tabs[3].annexaFormly.model.row_proposalPremissions.usersView && organ.tabs[3].annexaFormly.model.row_proposalPremissions.usersView.length > 0) {
            		_.forEach(organ.tabs[3].annexaFormly.model.row_proposalPremissions.usersView, function(value) {
    	            	var userView = {user:{id:value}};
    	                model.viewProposalUsers.push(userView);
    	            });
            	}
            	if(organ.tabs[3].annexaFormly.model.row_proposalPremissions.profilesReview && organ.tabs[3].annexaFormly.model.row_proposalPremissions.profilesReview.length > 0) {
            		_.forEach(organ.tabs[3].annexaFormly.model.row_proposalPremissions.profilesReview, function(value) {
    	            	var profReview = {profile:{id:value}};
    	                model.reviewProposalProfiles.push(profReview);
    	            });
            	}
            	if(organ.tabs[3].annexaFormly.model.row_proposalPremissions.usersReview && organ.tabs[3].annexaFormly.model.row_proposalPremissions.usersReview.length > 0) {
            		_.forEach(organ.tabs[3].annexaFormly.model.row_proposalPremissions.usersReview, function(value) {
    	            	var userReview = {user:{id:value}};
    	                model.reviewProposalUsers.push(userReview);
    	            });
            	}
            	if(organ.tabs[3].annexaFormly.model.row_proposalPremissions.profilesValidate && organ.tabs[3].annexaFormly.model.row_proposalPremissions.profilesValidate.length > 0) {
            		_.forEach(organ.tabs[3].annexaFormly.model.row_proposalPremissions.profilesValidate, function(value) {
    	            	var profValidate = {profile:{id:value}};
    	                model.validateProposalProfiles.push(profValidate);
    	            });
            	}
            	if(organ.tabs[3].annexaFormly.model.row_proposalPremissions.usersValidate && organ.tabs[3].annexaFormly.model.row_proposalPremissions.usersValidate.length > 0) {
            		_.forEach(organ.tabs[3].annexaFormly.model.row_proposalPremissions.usersValidate, function(value) {
    	            	var userValidate = {user:{id:value}};
    	                model.validateProposalUsers.push(userValidate);
    	            });
            	}
            	if(organ.tabs[3].annexaFormly.model.row_proposalPremissions.profilesAccept && organ.tabs[3].annexaFormly.model.row_proposalPremissions.profilesAccept.length > 0) {
            		_.forEach(organ.tabs[3].annexaFormly.model.row_proposalPremissions.profilesAccept, function(value) {
    	            	var profAccept = {profile:{id:value}};
    	                model.acceptProposalProfiles.push(profAccept);
    	            });
            	}
            	if(organ.tabs[3].annexaFormly.model.row_proposalPremissions.usersAccept && organ.tabs[3].annexaFormly.model.row_proposalPremissions.usersAccept.length > 0) {
            		_.forEach(organ.tabs[3].annexaFormly.model.row_proposalPremissions.usersAccept, function(value) {
    	            	var userAccept = {user:{id:value}};
    	                model.acceptProposalUsers.push(userAccept);
    	            });
            	}
            }
            
            RestService.insert('./api/sec/organ/', model).then(function () {
				if(GlobalDataFactory.organs){
                	GlobalDataFactory.organs.length = 0;
                }else{
                	GlobalDataFactory.organs = [];
                }
                if(CacheFactory.get('globalDataCache') && CacheFactory.get('globalDataCache').get('organs')){
                	CacheFactory.get('globalDataCache').remove('organs')
                }
                GlobalDataFactory.loadGlobalData();
				$rootScope.$broadcast('annexaAdminBaseReloadTable');
                modal.close();
            }).catch(function (error) {
                var errorTranslate = { msg: ErrorFactory.getErrorMessage('organ', 'new', error.data) };
                modal.alerts.push({msg:errorTranslate.msg});
            })
        }
		$scope.activeOrganFunction = function(organId){
			SecFactory.activateOrdeactivateOrgan(organId).then(function(data){
				if(GlobalDataFactory.organs){
                	GlobalDataFactory.organs.length = 0;
                }else{
                	GlobalDataFactory.organs = [];
                }
                if(CacheFactory.get('globalDataCache') && CacheFactory.get('globalDataCache').get('organs')){
                	CacheFactory.get('globalDataCache').remove('organs')
                }
                GlobalDataFactory.loadGlobalData();
				$rootScope.$broadcast('annexaAdminBaseReloadTable');
			}).catch(function (error) {
				if(error && error.data && error.data.message === 'Not deactivate have active proposals'){
					DialogsFactory.error($filter('translate')('global.errors.organCannotDeactivateHaveProposal'));
				}else{
					DialogsFactory.error($filter('translate')('global.errors.organCannotUpdateState'));
				}
			});	
		}
		var showDisableAction = function (data, type, full, meta) {
            if(full.active){
                return true;
            } else {
                return false;
            }
        }
		var showEnableAction = function (data, type, full, meta) {
            if(full.active){
                return false;
            } else {
                return true;
            }
        }

        $scope.filter = [
            { id: Language.getActiveColumn(), type: 'text', order: 0, label: 'global.literals.name' },
        ]

		$scope.additionalColumns = [
            { id: 'active', width: '10%', column: new BooleanColumn($filter, 'global.profile.active')},
        ]
		$scope.additionalActions = [
            { label: 'global.commonAdmin.datatable.options.enable', click: $scope.activeOrganFunction, clickName: 'activeOrganFunction', icon:undefined, href:undefined, showFunction:showEnableAction, permissions:undefined, addProperties:undefined},
			{ label: 'global.commonAdmin.datatable.options.disable', click: $scope.activeOrganFunction, clickName: 'activeOrganFunction', icon:undefined, href:undefined, showFunction:showDisableAction, permissions:undefined, addProperties:undefined},
        ]
        $scope.viewElement = function(id) {
            RestService.findOne('Organ', id)
                .then(function(data) {
                    var decodedData = JSOG.decode(data.data);
                    renderData(decodedData);
                    var modal = undefined;
                    var president = undefined;
                    var secretary = undefined;
                    modal = angular.copy(CommonAdminModals.organCollegiateEdit);
                    modal.languageColumn = Language.getActiveColumn();
                    modal.meetingLocations = GlobalDataFactory.meetingLocations;
                    modal.classifications = $scope.classifications;
                    modal.printProfiles = function(modelValue){
                    	var profiles = '';

                    	if(modelValue && modelValue.length > 0){
                    		_.forEach(modelValue, function(value){
                    			if(profiles != '') {
                    				profiles += ', ';
                                }
                    			if(value.profile) {
                    				profiles += value.profile[modal.languageColumn];
                    			} else {
                    				profiles += value[modal.languageColumn];
                    			}
                    		});
                    	}
                    	return profiles;
                    }
                    modal.printUsers = function(modelValue) {
                    	var users = '';
                    	if(modelValue && modelValue.length > 0){
                    		_.forEach(modelValue, function(value){
                    			if(users != '') {
                    				users += ', ';
                                }
                    			if(value.user) {
                    				users += value.user.completeName;
                    			} else {
                    				users += value.completeName;
                    			}
                    		});
                    	}
                    	return users;
                    }
                    modal.yesno = [
                        { id: true, name: $filter('translate')('global.literals.yes') },
                        { id: false, name: $filter('translate')('global.literals.no') }
                    ];
                    modal.printYesNo = function(modelValue) {
                        if(modelValue) {
                            return $filter('translate')('global.literals.yes');
                        } else {
                            return $filter('translate')('global.literals.no');
                        }
                    }
                    modal.nameColumn = 'name';
                    modal.resolutionTypes = [
                        { id: 'OPINION', name: 'global.sec.literals.OPINION' },
                        { id: 'AGREEMENT', name: 'global.sec.literals.AGREEMENT' }
                    ];
                    modal.printResolutionType = function(modelValue) {
                        return $filter('translate')('global.sec.literals.' + modelValue);
                    };
                    modal.alerts = [];
                    modal.data = decodedData;
                    if(!modal.data.archiveClassification){
                        modal.data.archiveClassification = {};
                    }
                    modal.data.showPropPermissionsTab = false;
                    if($rootScope && $rootScope.app && $rootScope.app.configuration && $rootScope.app.configuration.validation_circuit_secretary && $rootScope.app.configuration.validation_circuit_secretary.value) {
                    	modal.data.showPropPermissionsTab = true;
                    }
                    modal.updateOrgan = $scope.updateOrgan;
                    modal.loadFunction = CommonService.loadUsers;
                    modal.organTypeCol = 'name';
                    modal.closeValidation = function () {
                        if(!this.data.meetingLocation) {
                            this.alerts.push({ msg: $filter('translate')('global.sec.validations.organEditValidationCollegiate') });
                            return false;
                        } else {
                            return true;
                        }
                    }
                    $scope.modalSecOrgans = modal;
                    
                    var selectableOptions = [];
                    _.forEach(GlobalDataFactory.allProfiles, function (value, key) {
                        var haveProfile = false;
                        if(modal.data.profiles) {
                            haveProfile = modal.data.profiles.some(function (profile) {
                                  return profile.id === value.id;
                                });
                        }
                        if(value.expiryDate == null || haveProfile) {
                            selectableOptions.push(value);
                        }
                    });
                    
                    modal.profiles = selectableOptions;
                    modal.users = [];
                    modal.usersView = [];
                    modal.usersReview = [];
                    modal.usersValidate = [];
                    modal.usersAccept = [];
                    modal.usersValColumn = 'value';
                    modal.onlyManagmentUsers = 'name';
                    $scope.$on('selectedProfile', function(event, args){
                    	if(args.action) {
                    		var attrProposalProfiles = undefined;
                    		var attrProposalUsers = undefined;
                    		var usersAttr = undefined;
                    		if(args.action == 'view') {
                        		attrProposalProfiles = 'viewProposalProfiles';
                        		attrProposalUsers = 'viewProposalUsers';
                        		usersAttr = 'usersView';
                    		} else if(args.action == 'review') {
                        		attrProposalProfiles = 'reviewProposalProfiles';
                        		attrProposalUsers = 'reviewProposalUsers';
                        		usersAttr = 'usersReview';
                    		} else if(args.action == 'validate') {
                        		attrProposalProfiles = 'validateProposalProfiles';
                        		attrProposalUsers = 'validateProposalUsers';
                        		usersAttr = 'usersValidate';
                    		} else if(args.action == 'accept') {
                        		attrProposalProfiles = 'acceptProposalProfiles';
                        		attrProposalUsers = 'acceptProposalUsers';
                        		usersAttr = 'usersAccept';
                    		}
                    		if(attrProposalProfiles && attrProposalUsers && usersAttr) {
                    			if(modal.data[attrProposalProfiles]) {
                            		modal[usersAttr] = [];
                            		_.forEach(modal.users, function(usr) {
                                		var prof = $linq(usr.user.userProfiles).intersect(modal.data[attrProposalProfiles], function(x,y){
                    						if(y.profile && y.profile.id && x.profile && x.profile.id && y.profile.id == x.profile.id){
                    							return true;
                    						}else{
                    							return false;
                    						}
                    					}).toArray();
                                    	if(prof && prof.length > 0) {
                                    		modal[usersAttr].push(usr);
                                    	}
                                    });
                                	if(modal.data[attrProposalUsers] && modal.data[attrProposalUsers].length > 0 && modal[usersAttr].length > 0) {
                                		var selUsr = [];
                                		_.forEach(modal[usersAttr], function(option) {
                                			var existUser = $linq(modal.data[attrProposalUsers]).firstOrDefault(undefined, "x => x.user.id == " + option.user.id);
                                			if(existUser) {
                                				selUsr.push(existUser);
                                			}
                                		});
                                		modal.data[attrProposalUsers] = selUsr;
                                	}
                            	} else {
                            		modal[usersAttr] = [];
                            	}
                    		}
                    	}
                    });
                	RestService.findAll('User', 'adminCreateTransaction')
                        .then(function(data) {
                            var users = [];
                            if(data.data && data.data.length > 0) {
                                _.forEach(JSOG.decode(data.data), function(val) {
                                	users.push({ 'id':  val.id, 'user': val, 'value': val.completeName });
                                });
                            }
                            modal.users = users;
                            $rootScope.$broadcast('selectedProfile', { action: 'view' });
                            $rootScope.$broadcast('selectedProfile', { action: 'review' });
                            $rootScope.$broadcast('selectedProfile', { action: 'validate' });
                            $rootScope.$broadcast('selectedProfile', { action: 'accept' });
                        });
                    
                    AnnexaModalFactory.showModal('modalOrganEdit', modal);
                })
        }

        $scope.updateOrgan = function(val, prop) {
            var self = $scope.modalSecOrgans;
            var model = angular.copy($scope.modalSecOrgans.data);
            if(prop == 'resolutionType' || prop == 'globalVote' || prop == 'everyoneCanView') {
                model[prop] = val.id;
            } else if(prop == 'acronym' || prop == 'organSessionPattern' || prop == 'organSessionDigits'){
            	model[prop] = val;
            }else if(prop == 'profiles' || prop == 'viewProposalProfiles' || prop == 'reviewProposalProfiles' || prop == 'validateProposalProfiles' || prop == 'acceptProposalProfiles'){
            	var exist = $linq(model[prop]).intersect(val, "(x, y) => x.id == y.profile.id").toArray();
            	var notExist = [];
            	_.forEach(val, function(profile) {
            		var existProfile = false;
            		_.forEach(model[prop], function(organProfile) {
            			if(profile.id == organProfile.profile.id){
    						existProfile = true;
                		}	
                	});
            		if(!existProfile){
            			notExist.push(profile);
            		}
            	});
            	model[prop].length = 0;
            	if(exist && exist.length > 0){
	            	_.forEach(exist, function(perfil){
	            		model[prop].push(perfil);
	            	});
            	}
            	if(notExist && notExist.length > 0){
            		_.forEach(notExist, function(perfil){
	            		model[prop].push({profile:perfil});
	            	});
            	}
            } else if(prop == 'viewProposalUsers' || prop == 'reviewProposalUsers' || prop == 'validateProposalUsers' || prop == 'acceptProposalUsers'){
            	var exist = $linq(model[prop]).intersect(val, "(x, y) => x.id == y.user.id").toArray();
            	var notExist = [];
            	_.forEach(val, function(user) {
            		var existUser = false;
            		_.forEach(model[prop], function(organUser) {
            			if(user.id == organUser.user.id){
            				existUser = true;
                		}	
                	});
            		if(!existUser){
            			notExist.push(user);
            		}
            	});
            	model[prop].length = 0;
            	if(exist && exist.length > 0){
	            	_.forEach(exist, function(user){
	            		model[prop].push(user);
	            	});
            	}
            	if(notExist && notExist.length > 0){
            		_.forEach(notExist, function(user){
	            		model[prop].push({user:user});
	            	});
            	}
            } else if(prop == 'meetingLocation'){
            	model[prop] = {id:val.id, language1: val.language1, language2: val.language2, language3: val.language3};
            }else if(prop == 'archiveClassification'){
                if(val.$selected && val.$selected.id){
                	model[prop] = {id:val.$selected.id};
                }else{
                	model[prop] = {};
                }
            } else{
                model = val;
            }
            var acSelected = undefined;
            if(model.archiveClassification && model.archiveClassification.model){
            	acSelected =  model.archiveClassification;
            	if(model.archiveClassification.model.$selected && model.archiveClassification.model.$selected.id){
                	model.archiveClassification = {id:model.archiveClassification.model.$selected.id};
                }else{
                	model.archiveClassification = undefined;
                }
            }
            RestService.update('./api/sec/organ/' + model.id, model).then(function (data) {
            	if(prop == 'resolutionType' || prop == 'globalVote'  || prop == 'everyoneCanView'){
            		self.data[prop] = val.id;
            	}else if(prop == 'profiles'){
            		self.data[prop] = data[prop];
            	}else if(prop == 'language1' || prop == 'language2' || prop == 'language3'){
	            	if(acSelected){
	            		self.data['archiveClassification'] = acSelected;
	            	}
            	}
            	if(GlobalDataFactory.organs){
                	GlobalDataFactory.organs.length = 0;
                }else{
                	GlobalDataFactory.organs = [];
                }
                if(CacheFactory.get('globalDataCache') && CacheFactory.get('globalDataCache').get('organs')){
                	CacheFactory.get('globalDataCache').remove('organs')
                }
                GlobalDataFactory.loadGlobalData();
                $rootScope.$broadcast('annexaAdminBaseReloadTable');
                if(prop == 'viewProposalProfiles' || prop == 'viewProposalUsers' || 
                		prop == 'reviewProposalProfiles' || prop == 'reviewProposalUsers' || 
                		prop == 'validateProposalProfiles' || prop == 'validateProposalUsers' ||
                		prop == 'acceptProposalProfiles' || prop == 'acceptProposalUsers') {
                	self.close();
                	$scope.viewElement(model.id);
                }
                
            }).catch(function (error) {
                var errorTranslate = { msg: ErrorFactory.getErrorMessage('organ', 'update', error.data) };
                self.alerts.push({msg:errorTranslate.msg});
            });
        }
        
        var renderData = function(decodedData) {
            if(decodedData.profiles && decodedData.profiles.length > 0){
            	decodedData.profilesAux = $linq(decodedData.profiles).select("x => x.profile").toArray();
            }else { 
            	decodedData.profilesAux = [];
            }
            if(decodedData.viewProposalProfiles && decodedData.viewProposalProfiles.length > 0){
            	decodedData.viewProposalProfilesAux = $linq(decodedData.viewProposalProfiles).select("x => x.profile").toArray();
            }else { 
            	decodedData.viewProposalProfilesAux = [];
            }
            if(decodedData.viewProposalUsers && decodedData.viewProposalUsers.length > 0){
            	decodedData.viewProposalUsersAux = $linq(decodedData.viewProposalUsers).select("x => x.user").toArray();
            }else { 
            	decodedData.viewProposalUsersAux = [];
            }
            if(decodedData.reviewProposalProfiles && decodedData.reviewProposalProfiles.length > 0){
            	decodedData.reviewProposalProfilesAux = $linq(decodedData.reviewProposalProfiles).select("x => x.profile").toArray();
            }else { 
            	decodedData.reviewProposalProfilesAux = [];
            }
            if(decodedData.reviewProposalUsers && decodedData.reviewProposalUsers.length > 0){
            	decodedData.reviewProposalUsersAux = $linq(decodedData.reviewProposalUsers).select("x => x.user").toArray();
            }else { 
            	decodedData.reviewProposalUsersAux = [];
            }
            if(decodedData.validateProposalProfiles && decodedData.validateProposalProfiles.length > 0){
            	decodedData.validateProposalProfilesAux = $linq(decodedData.validateProposalProfiles).select("x => x.profile").toArray();
            }else { 
            	decodedData.validateProposalProfilesAux = [];
            }
            if(decodedData.validateProposalUsers && decodedData.validateProposalUsers.length > 0){
            	decodedData.validateProposalUsersAux = $linq(decodedData.validateProposalUsers).select("x => x.user").toArray();
            }else { 
            	decodedData.validateProposalUsersAux = [];
            }
            if(decodedData.acceptProposalProfiles && decodedData.acceptProposalProfiles.length > 0){
            	decodedData.acceptProposalProfilesAux = $linq(decodedData.acceptProposalProfiles).select("x => x.profile").toArray();
            }else { 
            	decodedData.acceptProposalProfilesAux = [];
            }
            if(decodedData.acceptProposalUsers && decodedData.acceptProposalUsers.length > 0){
            	decodedData.acceptProposalUsersAux = $linq(decodedData.acceptProposalUsers).select("x => x.user").toArray();
            }else { 
            	decodedData.acceptProposalUsersAux = [];
            }
        }
        
    }])
    .controller('SecMeetingLocationsController',[function() {
        //Empty
    }])
    .controller('SecSessionTypesController',[function () {
        //Empty
    }])
    .controller('DecreeDelegatedTypeController',[function () {
        //Empty
    }])
    .controller('SecProposalTypesController',['$scope', '$filter', '$rootScope', 'apiAdmin', 'CommonAdminModals', 'AnnexaFormlyFactory', 'RestService', 'Language', 'AnnexaModalFactory', 'SecFactory', 'ErrorFactory', 'GlobalDataFactory', function ($scope, $filter, $rootScope, apiAdmin, CommonAdminModals, AnnexaFormlyFactory, RestService, Language, AnnexaModalFactory, SecFactory, ErrorFactory, GlobalDataFactory) {
    	 $scope.additionalColumns = [];
    	 
    	 $scope.headButtons = [
             new HeadButton('btn primary breadDivButton mr-sm-pt',undefined,'#sec-proposal-types','fa fa-plus','global.sec.literals.newProposalType',undefined,'newProposalType')
         ];

    	 $scope.newProposalType = function(){
            var modal =  angular.copy(CommonAdminModals.proposalTypeNew);
            AnnexaFormlyFactory.showModal('modalNewcollegiateOrgan', modal, $scope.saveProposalType, false)
    	 };
    	 $scope.saveProposalType = function(proposalType){
    		 var modal = this;
    		 modal.alerts = [];
    		 var isValid = true;
    		 
    		 var model = {
	            language1: proposalType.annexaFormly.model.modal_body.language1,
	            language2: proposalType.annexaFormly.model.modal_body.language2 ? proposalType.annexaFormly.model.modal_body.language2 : 'NotTranslated',
	            language3: proposalType.annexaFormly.model.modal_body.language3 ? proposalType.annexaFormly.model.modal_body.language3 : 'NotTranslated',
	            decreeDigits: proposalType.annexaFormly.model.modal_body.decreeDigits ? proposalType.annexaFormly.model.modal_body.decreeDigits : null,
	            decreePattern: proposalType.annexaFormly.model.modal_body.decreePattern ? proposalType.annexaFormly.model.modal_body.decreePattern : null, 
        		actionNumber: proposalType.annexaFormly.model.modal_body.actionNumber ? proposalType.annexaFormly.model.modal_body.actionNumber : null,
				actionDate: proposalType.annexaFormly.model.modal_body.actionDate ? proposalType.annexaFormly.model.modal_body.actionDate : null,
				typeNumber: proposalType.annexaFormly.model.modal_body.typeNumber ? proposalType.annexaFormly.model.modal_body.typeNumber : null,
				typeDate: proposalType.annexaFormly.model.modal_body.typeDate ? proposalType.annexaFormly.model.modal_body.typeDate : null,
        		proposalTypeSubtype: proposalType.annexaFormly.model.modal_body.proposalTypeSubtype ? proposalType.annexaFormly.model.modal_body.proposalTypeSubtype : null,
        		secTemplate: (proposalType.annexaFormly.model.modal_body.secTemplate && proposalType.annexaFormly.model.modal_body.secTemplate.id)?{id:proposalType.annexaFormly.model.modal_body.secTemplate.id}: null  
    		 };
             
    		 if($rootScope && $rootScope.app && $rootScope.app.configuration && $rootScope.app.configuration.validation_circuit_secretary &&
    				 $rootScope.app.configuration.validation_circuit_secretary.value) {
    			 if(proposalType.annexaFormly.model.modal_body.validateCircuit_REVIEW || proposalType.annexaFormly.model.modal_body.validateCircuit_VALIDATE
    					 || proposalType.annexaFormly.model.modal_body.validateCircuit_ACCEPT) {
    				 model.circuitReview = proposalType.annexaFormly.model.modal_body.validateCircuit_REVIEW ? proposalType.annexaFormly.model.modal_body.validateCircuit_REVIEW : false;
    				 model.circuitValidate = proposalType.annexaFormly.model.modal_body.validateCircuit_VALIDATE ? proposalType.annexaFormly.model.modal_body.validateCircuit_VALIDATE : false;
    				 model.circuitAccept = proposalType.annexaFormly.model.modal_body.validateCircuit_ACCEPT ? proposalType.annexaFormly.model.modal_body.validateCircuit_ACCEPT : false;
    				 if(model.circuitReview && model.circuitValidate){
    					 model.validateState = 'REVIWED';
    				 } else if(!model.circuitReview && model.circuitValidate) {
    					 model.validateState = 'PENDING';
    				 }
    				 if(model.circuitValidate && model.circuitAccept){
    					 model.acceptState = 'VALIDATED';
    				 } else if(model.circuitReview && !model.circuitValidate && model.circuitAccept) {
    					 model.acceptState = 'REVIWED';
    				 } else if(!model.circuitReview && !model.circuitValidate && model.circuitAccept) {
    					 model.acceptState = 'PENDING';
    				 }
    			 } else {
    				 modal.alerts.push({msg:$filter('translate')('global.sec.errors.proposalTypeRequiredCircuitAction')});
    				 isValid = false;
    			 }
    		 }

    		 if(isValid) {
        		 RestService.insert('./api/sec/proposal_type/', model).then(function () {
                     $rootScope.$broadcast('annexaAdminBaseReloadTable');
                     modal.close();
                 }).catch(function (error) {
                     var errorTranslate = { msg: ErrorFactory.getErrorMessage('proposalType', 'new', error.data) };
                     modal.alerts.push({msg:errorTranslate.msg});
                 })
    		 }
              
    	 };
    	 
    	 $scope.viewElement = function(id) {
             RestService.findOne('ProposalType', id)
                 .then(function(data) {
                     var decodedData = JSOG.decode(data.data);
                     var modal = angular.copy(CommonAdminModals.proposalTypeEdit);
                     modal.showValidateCircuit = false;

		             modal.printValidateCircuit = function(model) {
		                 var circuit = '';
		                 _.forEach(model, function(val, key) {
		                     if(val.checked) {
		                         if(circuit != '') {
		                        	 circuit += ', ';
		                         }
		                         circuit += val.label;
		                     }
		                 });
		                 return circuit;
		             };
                     modal.languageColumn = Language.getActiveColumn();
                     modal.labelCol = 'label';
                     modal.descriptionColumn = "description";
                     modal.proposalTypeSubtypes = apiAdmin.proposalTypeSubtypes;
                     modal.printProposalSubtype = function(subtype){
                    	if (subtype && (subtype == "PROPOSAL" || subtype.id == "PROPOSAL")) return $filter('translate')('global.sec.literals.proposal');
                    	else if (subtype && (subtype == "DECREE" || subtype.id == "DECREE")) return $filter('translate')('global.sec.literals.decree');
                     	else return "";
                     };
                     modal.decreeSignProcessTypeEnum = apiAdmin.decreeSignProcessTypeEnum;
                     modal.printDecreeSignProcessTypeEnum = function(subtype){
                    	if (subtype && (subtype == "SIGN" || subtype.id == "SIGN")) return $filter('translate')('global.sec.literals.SIGN');
                    	else if (subtype && (subtype == "VALIDATION" || subtype.id == "VALIDATION")) return $filter('translate')('global.sec.literals.VALIDATION');
                    	else if (subtype && (subtype == "ANYONE" || subtype.id == "ANYONE")) return $filter('translate')('global.sec.literals.ANYONE');
                     	else return "";
                     };
                     modal.decreeSignProcessActionEnum = apiAdmin.decreeSignProcessActionEnum;
                     modal.printDecreeSignProcessActionEnum = function(subtype){
                    	if (subtype && (subtype == "FIRST" || subtype.id == "FIRST")) return $filter('translate')('global.sec.literals.FIRST');
                    	else if (subtype && (subtype == "LAST" || subtype.id == "LAST")) return $filter('translate')('global.sec.literals.LAST');
                     	else return "";
                     };
                     var documentType = $rootScope.app.configuration.sec_document_types['SDR'];
                     var templates = $linq(GlobalDataFactory.secTemplates).where(function(x){
                     	if(documentType && documentType.id && documentType.id.length > 0 && x.documentType && x.documentType.id){
                     		if(_.contains(documentType.id, x.documentType.id+"") && 'SDR' == x.type){
                     			return true;
                     		}
                     	}
                     	return false;
                     }).toArray();
                     modal.secTemplates= templates;
                     modal.alerts = [];
                     modal.data = decodedData;
                     modal.validateCircuits = [
                 		 { label: $filter('translate')('global.literals.review'), value: 'REVIEW' },
                 		 { label: $filter('translate')('global.literals.validate'), value: 'VALIDATE' },
                 		 { label: $filter('translate')('global.literals.accept'), value: 'ACCEPT' }
                 	 ];
                     if($rootScope && $rootScope.app && $rootScope.app.configuration && $rootScope.app.configuration.validation_circuit_secretary &&
                    		 $rootScope.app.configuration.validation_circuit_secretary.value) {
            	 		modal.showValidateCircuit = true;
            	 		_.forEach(modal.validateCircuits, function(val, key) {
		                     if(val.value == 'REVIEW') {
		                    	 if(modal.data.circuitReview) {
		                    		 val.checked = true;
		                    	 } else {
		                    		 val.checked = false;
		                    	 }
		                     } else if(val.value == 'VALIDATE') {
		                    	 if(modal.data.circuitValidate) {
		                    		 val.checked = true;
		                    	 } else {
		                    		 val.checked = false;
		                    	 }
		                     } else if(val.value == 'ACCEPT') {
		                    	 if(modal.data.circuitAccept) {
		                    		 val.checked = true;
		                    	 } else {
		                    		 val.checked = false;
		                    	 }
		                     }
		                 });
                 	 }
                     modal.updateProposalType = $scope.updateProposalType;
                     AnnexaModalFactory.showModal('modalProposalTypeEdit', modal);
                 })
         }

         $scope.updateProposalType = function(val, prop) {
             var self = this;
             var model = angular.copy(this.data);
             if(prop == 'proposalTypeSubtype') {
            	 if(val && (val == 'PROPOSAL' || val.id == 'PROPOSAL')){
            		 model.secTemplate = undefined;
            		 model.actionNumber = 'NONE';
            		 model.actionDate = 'NONE';
            		 model.typeNumber = 'NONE';
            		 model.typeDate = 'NONE';
            	 }
            	 model[prop] = ((val && val.id)?val.id:val);
             }else if(prop == 'secTemplate'){
            	 model[prop] = ((val && val.id)?{id:val.id, language1:val.language1, language2:val.language2, language3:val.language3}:undefined);
             }else if(prop == 'actionNumber' || prop == 'actionDate' || prop == 'typeNumber' || prop == 'typeDate'){
            	 model[prop] = ((val && val.id)?val.id:val);
             }else if(prop == 'validateCircuit'){
            	 _.forEach(val, function(vc, key) {
                     if(vc.value == 'REVIEW') {
                    	 model.circuitReview = vc.checked;
                     } else if(vc.value == 'VALIDATE') {
                    	 model.circuitValidate = vc.checked;
                     } else if(vc.value == 'ACCEPT') {
                    	 model.circuitAccept = vc.checked;
                     }
                 });
            	 if(model.circuitReview && model.circuitValidate){
					 model.validateState = 'REVIWED';
				 } else if(!model.circuitReview && model.circuitValidate) {
					 model.validateState = 'PENDING';
				 }
				 if(model.circuitValidate && model.circuitAccept){
					 model.acceptState = 'VALIDATED';
				 } else if(model.circuitReview && !model.circuitValidate && model.circuitAccept) {
					 model.acceptState = 'REVIWED';
				 } else if(!model.circuitReview && !model.circuitValidate && model.circuitAccept) {
					 model.acceptState = 'PENDING';
				 }
             }else if(prop != 'language1' && prop != 'language2' && prop != 'language3'){
            	 model[prop] = val;
             }
             if(model.proposalTypeSubtype && model.proposalTypeSubtype.id) {
            	 model.proposalTypeSubtype = model.proposalTypeSubtype.id;
             }
        	 RestService.update('./api/sec/proposal_type/' + model.id, model).then(function (data) {
            	 if(data){
            		 if(prop == 'proposalTypeSubtype') {
                    	 if(val && (val == 'PROPOSAL' || val.id == 'PROPOSAL')){
                    		 self.data.secTemplate = undefined;
                    		 self.data.actionNumber = 'NONE';
                    		 self.data.actionDate = 'NONE';
                    		 self.data.typeNumber = 'NONE';
                    		 self.data.typeDate = 'NONE';
                    	  }
                    	 self.data[prop] = ((val && val.id)?val.id:val);
                     }else if(prop == 'secTemplate'){
                    	 self.data[prop] = ((val && val.id)?{id:val.id, language1:val.language1, language2:val.language2, language3:val.language3}:undefined);
                     }else if(prop == 'actionNumber' || prop == 'actionDate' || prop == 'typeNumber' || prop == 'typeDate'){
                    	 self.data[prop] = ((val && val.id)?val.id:val);
                     } 
            	 }
             	$rootScope.$broadcast('annexaAdminBaseReloadTable');
             }).catch(function (error) {
                 var errorTranslate = { msg: ErrorFactory.getErrorMessage('proposalType', 'update', error.data) };
                 self.alerts.push({msg:errorTranslate.msg});
             });
         }
    }])
    .controller('SecMembersOrganController',['$scope','Language', '$filter', 'RestService', 'CommonAdminModals', 'AnnexaFormlyFactory', '$rootScope', 'SecFactory', function ($scope, Language, $filter, RestService, CommonAdminModals, AnnexaFormlyFactory, $rootScope, SecFactory) {
    	$scope.newElement = function () {
            var modal = angular.copy(CommonAdminModals.organMemberGlobalNew);
            modal.annexaFormly.model = {row_member: undefined};
            modal.annexaFormly.options = { watchAllExpressions: true };
            var organsField = $linq(modal.annexaFormly.fields[0].fieldGroup).firstOrDefault(undefined, "x => x.key == 'organs'");
            if(organsField) {
            	organsField.data.organs = [];
            	organsField.data.isNew = true;
            }
            var identificationReferenceField = $linq(modal.annexaFormly.fields[0].fieldGroup).firstOrDefault(undefined, "x => x.key == 'identificationReference'");
            if(identificationReferenceField && $rootScope.app.configuration && $rootScope.app.configuration.ava_finish_convene) {
            	identificationReferenceField.templateOptions.required = $rootScope.app.configuration.ava_finish_convene.enabled;
            }
            AnnexaFormlyFactory.showModal('modalNewOrganMemberGlobal', modal, $scope.saveMemberGlobal, false);
        }

        $scope.saveMemberGlobal = function(organMember) {
            var modal = this;
            
            if(organMember && organMember.annexaFormly && organMember.annexaFormly.model && organMember.annexaFormly.model.row_member){
            	var organsField = $linq(organMember.annexaFormly.fields[0].fieldGroup).firstOrDefault(undefined, "x => x.key == 'organs'");
            	var organs = undefined;
            	if(organsField && organsField.data && organsField.data.organs) {
            		organs = [];
            		_.forEach(organsField.data.organs, function(organ){
            			var organAux = angular.copy(organ);
            			organAux.attendeeAddressConvene=((organ.attendeeAddressConvene)?{id:organ.attendeeAddressConvene.id}:undefined);
            			organAux.memberRol=((organ.memberRol)?{id:organ.memberRol.id}:undefined);
            			organAux.vote=((organ.vote)?organ.vote:false);
            			organAux.orderView=((organ.orderView)?organ.orderView:-1);
            			organAux.organ= {id: organ.organ.id};
            			organs.push(organAux);
            		});
                }
	        	var member = {
	        		attendee:((organMember.annexaFormly.model.row_member.attendee.attendee)?organMember.annexaFormly.model.row_member.attendee.attendee:undefined),
	        		govermmentTeam:((organMember.annexaFormly.model.row_member.govermmentTeam)?organMember.annexaFormly.model.row_member.govermmentTeam:false),
	        		memberPosition:((organMember.annexaFormly.model.row_member.memberPosition)?organMember.annexaFormly.model.row_member.memberPosition:undefined),
	        		politicalParty:((organMember.annexaFormly.model.row_member.politicalParty)?organMember.annexaFormly.model.row_member.politicalParty:undefined),
	        		organMember:organs,
	        		identificationReference:((organMember.annexaFormly.model.row_member.identificationReference)?organMember.annexaFormly.model.row_member.identificationReference:undefined)
	        	};
	        	RestService.insert('./api/sec/organ_member_global', member)
	        	.then(function() {
                    $rootScope.$broadcast('annexaAdminBaseReloadTable');
                    if(SecFactory.thirdsAdded){
                    	if(member.attendee && member.attendee.id && $linq(SecFactory.thirdsAdded).count("x => x =="+member.attendee.id) == 0){
	                    	SecFactory.thirdsAdded.push(member.attendee.id);
                    	}
                    }else{
                    	if(member.attendee && member.attendee.id){
	                    	SecFactory.thirdsAdded = [];
	                    	SecFactory.thirdsAdded.push(member.attendee.id);
                    	}
                    }
                    modal.close();
                }).catch(function (error) {
                    modal.alerts.push({ msg: $filter('translate')('global.errors.unknownNew') });
                });
        	}
        }

        $scope.viewElement = function(id) {
            RestService.findOne('OrganMemberGlobal', id)
                .then(function(data) {
                	var memberToUpdate = JSOG.decode(data.data);
                	SecFactory.getOrgansByOrganMemberId(memberToUpdate.id)
                		.then(function(organ){
                			var organs = JSOG.decode(organ);
		                	var modal = angular.copy(CommonAdminModals.organMemberGlobalNew);
		                	memberToUpdate.attendee = { id: memberToUpdate.attendee.id, attendee: memberToUpdate.attendee, value: memberToUpdate.attendee.completeName};
		                    modal.annexaFormly.model = {row_member: memberToUpdate};
		                    modal.annexaFormly.options = { watchAllExpressions: true };
		                    modal.attendeeId = memberToUpdate.attendee.id;
		                    var organsField = $linq(modal.annexaFormly.fields[0].fieldGroup).firstOrDefault(undefined, "x => x.key == 'organs'");
		                    if(organsField) {
		                    	organsField.data.organs = organs;
		                    	organsField.data.isNew = false;
		                    	if(memberToUpdate.attendee) {
		                    		organsField.data.organMemberGlobal = memberToUpdate;
		                    	}
		                    }
		                    var identificationReferenceField = $linq(modal.annexaFormly.fields[0].fieldGroup).firstOrDefault(undefined, "x => x.key == 'identificationReference'");
		                    if(identificationReferenceField && $rootScope.app.configuration && $rootScope.app.configuration.ava_finish_convene) {
		                    	identificationReferenceField.templateOptions.required = $rootScope.app.configuration.ava_finish_convene.enabled;
		                    }
		                	AnnexaFormlyFactory.showModal('modalEditOrganMemberGlobal', modal, $scope.updateOrganMemberGlobal, false);
                	});
            });
        }

        $scope.updateOrganMemberGlobal = function(organMember) {
        	if(organMember && organMember.annexaFormly && organMember.annexaFormly.model && organMember.annexaFormly.model.row_member){
	        	var member = angular.copy(organMember.annexaFormly.model.row_member);
	        	if(member){
	        		member.attendee=((member.attendee)?{id:member.attendee.id}:undefined);
            		member.govermmentTeam=((member.govermmentTeam)?member.govermmentTeam:false);
            		member.memberPosition=((member.memberPosition)?{id: member.memberPosition.id}:undefined);
            		member.politicalParty=((member.politicalParty)?{id:member.politicalParty.id}:undefined);
            		
            		RestService.update('./api/sec/organ_member_global/' + member.id, member).then(function (data) {
            			$rootScope.$broadcast('annexaAdminBaseReloadTable');
            			if(SecFactory.thirdsAdded){
            				if(member.attendee && member.attendee.id && organMember.attendeeId != member.attendee.id){
            					var index = $linq(SecFactory.thirdsAdded).indexOf("x => x =="+organMember.attendeeId )
            					if(index > 0){
            						SecFactory.thirdsAdded.splice(index, 1);
            					}
            				}
                        	if(member.attendee && member.attendee.id && $linq(SecFactory.thirdsAdded).count("x => x =="+member.attendee.id) == 0){
    	                    	SecFactory.thirdsAdded.push(member.attendee.id);
                        	}
                        }else{
                        	if(member.attendee && member.attendee.id){
    	                    	SecFactory.thirdsAdded = [];
    	                    	SecFactory.thirdsAdded.push(member.attendee.id);
                        	}
                        }
            			organMember.close();
                    }).catch(function () {
                    	organMember.alerts.push({ msg: $filter('translate')('global.errors.unknownUpdate') });
                    });
			    
	        	}
        	}
        }
    	var siNo = [
            { id: 'No', name: $filter('translate')('global.literals.no') },
            { id: 'Si', name: $filter('translate')('global.literals.yes') }
        ];
    	
        $scope.filter = [
            { id: 'completeName', type: 'text', order: 0, label: 'global.sec.literals.attendee', callAux: true },
            { id: 'memberPosition', type: 'select', order: 1, label: 'global.literals.position', dataType: 'LOCAL', data: new SortedArray(SecFactory.positions, Language.getActiveColumn()).sort(), addAll: true, nameProperty: Language.getActiveColumn() },
            { id: 'politicalParty', type: 'select', order: 2, label: 'global.sec.literals.politicalParty', dataType: 'LOCAL', data: new SortedArray(SecFactory.politicalParties, Language.getActiveColumn()).sort(), addAll: true, nameProperty: Language.getActiveColumn() },
            { id: 'govermmentTeam', type: 'select', order: 3, label: 'global.sec.literals.gov', dataType: 'LOCAL', data: siNo, addAll: true, nameProperty: 'name', callAux: true }
        ];
            	
    	var govermentTeamRender = function(data, type, full, meta) {
            var content = '';

            if(data && data == true) {
                content = '<i class="m-l-xs fa fa-star text-info"></i>';
            } else {
                content = '<i class="m-l-xs fa fa-star-o text-grey"></i>';
            }

            return content;
        }
    	
    	
    	$scope.additionalColumns = [
            { id: 'attendee.completeName', column:  new TextColumn($filter, 'global.sec.literals.attendee')},
            { id: 'memberPosition', column:   new DatabaseTranslatedParentColumn($filter, 'global.literals.position', Language.getActiveColumn()), sortable: false },
            { id: 'politicalParty', column: new DatabaseTranslatedParentColumn($filter, 'global.sec.literals.politicalParty', Language.getActiveColumn()) },
            { id: 'govermmentTeam', title: $filter('translate')('global.sec.literals.gov'), render: govermentTeamRender, sortable: false, className: 'session-attendee-govermmentTeam' }
        ];
    }])
    .controller('SecPoliticalPartiesController',[function() {
        //Empty
    }])
    .controller('SecOrganMemberRolsController',['$scope', '$rootScope', '$filter', 'CommonAdminModals', 'AnnexaFormlyFactory', 'RestService', 'Language', 'AnnexaModalFactory', function ($scope, $rootScope, $filter, CommonAdminModals, AnnexaFormlyFactory, RestService, Language, AnnexaModalFactory) {

        $scope.newElement = function () {
            var modal = CommonAdminModals.newOrganMemberRol;
            modal.annexaFormly.model = {};
            modal.annexaFormly.model.modal_body = {};
            modal.annexaFormly.options = {};

            AnnexaFormlyFactory.showModal('modalNewOrganMemberRol', modal, $scope.saveOrganMemberRol, false)
        }

        $scope.saveOrganMemberRol = function(organMemberRol) {
            var modal = this;

            var model = {
                language1: organMemberRol.annexaFormly.model.modal_body.language1,
                language2: organMemberRol.annexaFormly.model.modal_body.language2 ? organMemberRol.annexaFormly.model.modal_body.language2 : 'NotTranslated',
                language3: organMemberRol.annexaFormly.model.modal_body.language3 ? organMemberRol.annexaFormly.model.modal_body.language3 : 'NotTranslated'
            };

            RestService.insert('./api/sec/organ_member_rol', model)
                .then(function() {
                    $rootScope.$broadcast('annexaAdminBaseReloadTable');
                    modal.close();
                }).catch(function (error) {
                    modal.alerts.push({ msg: $filter('translate')('global.errors.unknownNew') });
            });
        }

        $scope.additionalColumns = [];

        $scope.viewElement = function(id) {
            RestService.findOne('OrganMemberRol', id)
                .then(function(data) {
                    var modal = angular.copy(CommonAdminModals.editOrganMemberRol);
                    modal.alerts = [];
                    modal.data = JSOG.decode(data.data);
                    modal.languageColumn = Language.getActiveColumn();
                    modal.updateOrganMemberRol = $scope.updateOrganMemberRol;
                    AnnexaModalFactory.showModal('modalEditOrganMemberRol', modal);
            });
        }

        $scope.updateOrganMemberRol = function(val, prop) {
            var model = this.data;
            model = val;

            RestService.update('./api/sec/organ_member_rol/' + model.id, model)
                .then(function () {
                    $rootScope.$broadcast('annexaAdminBaseReloadTable');
                }).catch(function () {
                //Empty
            });
        }
    }])
    .controller('SecFooterClaimsController',['$scope', '$rootScope', 'CommonAdminModals', 'Language', '$filter', 'RestService', 'SecFactory', 'CommonService', 'AnnexaModalFactory', 'AnnexaFormlyFactory', 'globalModals', 'Upload', '$http', 'DialogsFactory', function($scope, $rootScope, CommonAdminModals, Language, $filter, RestService, SecFactory, CommonService, AnnexaModalFactory, AnnexaFormlyFactory, globalModals, Upload, $http, DialogsFactory) {

        $scope.newElement = function () {
            var modal = CommonAdminModals.newFooterClaim;
            modal.annexaFormly.model = {};
            modal.annexaFormly.model.modal_body = {};
            modal.annexaFormly.options = {};
            AnnexaFormlyFactory.showModal('modalNewFooterClaim', modal, $scope.saveFooterClaim, false);
        }

        $scope.saveFooterClaim = function (footerClaim) {
            var modal = this;

            var model = {
                language1: footerClaim.annexaFormly.model.modal_body.language1,
                language2: footerClaim.annexaFormly.model.modal_body.language2 ? footerClaim.annexaFormly.model.modal_body.language2 : 'NotTranslated',
                language3: footerClaim.annexaFormly.model.modal_body.language3 ? footerClaim.annexaFormly.model.modal_body.language3 : 'NotTranslated',
                file: footerClaim.annexaFormly.model.modal_body.file ? footerClaim.annexaFormly.model.modal_body.file : undefined
            };
            
            var acceptedFileType = true;
            var fileField = undefined;
            var modalBodyFields = $linq(modal.annexaFormly.fields).firstOrDefault(undefined,"x => x.key == 'modal_body'");
            if(modalBodyFields) {
                fileField = $linq(modalBodyFields.fieldGroup).firstOrDefault(undefined,"x => x.key == 'file'");
            }

            if(fileField && fileField.data && fileField.data.accept && model.file) {
                acceptedFileType = fileField.data.accept.includes(model.file.type);
            }
            
            if(acceptedFileType) {
                SecFactory.addSecFooterClaim(model).then(function() {
                    $rootScope.$broadcast('annexaAdminBaseReloadTable');
                    modal.close();
    			}).catch(function (error) {
    			    modal.alerts.push({ msg: $filter('translate')('global.errors.unknownNew') });
    			});
            } else {
                DialogsFactory.error($filter('translate')('global.literals.documentTypeNotAccepted'));
            }
        }

        $scope.filter = [
            { id: Language.getActiveColumn(), type: 'text', order: 0, label: 'global.literals.name' }
        ]

        $scope.viewElement = function (id) {
            RestService.findOne('FooterClaim', id)
                .then(function(data) {
                    var modal = angular.copy(CommonAdminModals.editFooterClaim);
                    modal.alerts = [];
                    modal.data = JSOG.decode(data.data);
                    modal.languageColumn = Language.getActiveColumn();
                    modal.replaceDocumentModal = $scope.replaceDocumentModal;
                    modal.editOnline = $scope.editOnline;
                    modal.download = $scope.download;
                    modal.updateFooterClaim = $scope.updateFooterClaim;
                    AnnexaModalFactory.showModal('modalEditFooterClaim', modal);
                })
        }

        $scope.updateFooterClaim = function (val, prop) {
            var self = this;
            var model = angular.copy(val);

            RestService.update('./api/sec/footer_claim/' + model.id, model)
                .then(function () {
                    $rootScope.$broadcast('annexaAdminBaseReloadTable');
                }).catch(function(error) {
                    //Empty
                });
        }

        $scope.replaceDocument = function () {
            if(this.annexaFormly.form.$valid) {
                var self = this;
                var data = this.annexaFormly.model.modal_body;

                var send = {
                    id: this.footerClaim.id,
                    file: data.file
                };

                var acceptedFileType = true;
                var fileField = undefined;
                var modalBodyFields = $linq(this.annexaFormly.fields).firstOrDefault(undefined,"x => x.key == 'modal_body'");
                if(modalBodyFields) {
                    fileField = $linq(modalBodyFields.fieldGroup).firstOrDefault(undefined,"x => x.key == 'file'");
                }

                if(fileField && fileField.data && fileField.data.accept && data.file) {
                    acceptedFileType = fileField.data.accept.includes(data.file.type);
                }
                
                if(acceptedFileType) {
                	$rootScope.loading(true);
                    data.file.upload = Upload.upload({
                        url: './api/sec/footer_claim/document',
                        data: send
                    });
                    
                	data.file.upload
                    .then(function (data) {
                        if(data.data) {
                            var updatedFooterClaim = JSOG.decode(data.data);
                            self.callerModal.data.fileId = updatedFooterClaim.fileId;
                            self.callerModal.data.fileName = updatedFooterClaim.fileName;
                            $rootScope.loading(false);
                            self.close();
                        }
                    }).catch(function (error) {
                    	$rootScope.loading(false);
                    });
                } else {
                    DialogsFactory.error($filter('translate')('global.literals.documentTypeNotAccepted'));
                }
                
            }
        }

        $scope.replaceDocumentModal = function (footerClaim, callerModal) {
            var modal = globalModals.uploadFile;
            modal.title = 'global.literals.replace';
            modal.annexaFormly.model = {};
            modal.annexaFormly.model.modal_body = {};
            modal.annexaFormly.options = {};
            modal.annexaFormly.options.formState = { readOnly: false};
            modal.footerClaim = footerClaim;
            modal.callerModal = callerModal;
            modal.annexaFormly.fields[0].fieldGroup[0].data.accept = '\'application/vnd.openxmlformats-officedocument.wordprocessingml.document\'';
            modal.annexaFormly.fields[0].fieldGroup[0].templateOptions.onSelected = function() {};
            AnnexaFormlyFactory.showModal('modalUploadFile', modal, $scope.replaceDocument, false);
        }

        $scope.editOnline = function (fileId) {
            CommonService.getEditOnlineUrl(fileId).then(function(eou) {
                if(eou != null && eou != undefined && eou != ''){
                    window.location.href=eou;
                }
            }).catch(function(){});
        }
        
        $scope.download = function(fileId){
        	$rootScope.loading(true);
            $http.get("./api/repodocs/downloadAlfDoc?id="+fileId, {responseType: 'arraybuffer'})
                .success(function (data, status, headers) {
                    var retDownload = {
                        file: undefined,
                        fileName: undefined,
                        linkElement: undefined,
                        clickEvent: undefined
                    }
                    headers = headers();
                    var contentType = headers['content-type'];
                    retDownload.file = new Blob([data], {type: contentType});
                    retDownload.fileName = headers['content-disposition'].replace('attachment; filename="','').replace('"', '');
                    try {
                        if (window.navigator.msSaveOrOpenBlob) {
                            window.navigator.msSaveOrOpenBlob(retDownload.file, retDownload.fileName);
                        } else {
                            retDownload.linkElement = document.createElement('a');
                            var url = window.URL.createObjectURL(retDownload.file);
                            retDownload.linkElement.setAttribute('href', url);
                            retDownload.linkElement.setAttribute("download", retDownload.fileName);
                            retDownload.clickEvent = new MouseEvent("click", {
                                "view": window,
                                "bubbles": true,
                                "cancelable": false
                            });
                            retDownload.linkElement.dispatchEvent(retDownload.clickEvent);
                        }
                        $rootScope.loading(false);
                    } catch (e) {
                        $rootScope.loading(false);
                    }
                }).error(function (error) {
                    $rootScope.loading(false);
            });
        }
    }])
    .controller('SecTemplateController',['$rootScope', '$scope', '$filter', 'Language', 'SecFactory', 'CommonAdminModals','AnnexaModalFactory', 'AnnexaFormlyFactory', 'RestService', 'CommonService', 'globalModals', 'Upload', 'GlobalDataFactory', '$http', 'DialogsFactory', function ($rootScope, $scope, $filter, Language, SecFactory, CommonAdminModals, AnnexaModalFactory, AnnexaFormlyFactory, RestService, CommonService, globalModals, Upload, GlobalDataFactory, $http, DialogsFactory) {
        
    	$scope.modalSecTemplate;
    	
    	$scope.filter = [
            { id: Language.getActiveColumn(), type: 'text', order: 0, label: 'global.literals.name' },
            { id: 'documentType', type: 'select', order: 1, label: 'global.literals.docType', dataType: 'LOCAL', data: SecFactory.secDocumentTypes, addAll: true, nameProperty: Language.getActiveColumn() }
        ];

        var secTemplateTypeRender = function(data, type, full, meta) {
            var content = '';
            if(data) {
            	content = $scope.printSecTemplateType(data);
            }
            return content;
        }

        $scope.additionalColumns = [
            { id: 'documentType', column: new DatabaseTranslatedParentColumn($filter, 'global.literals.docType', Language.getActiveColumn(), undefined, 'global.literals.others') },
            { id: 'type', title: $filter('translate')('global.literals.type'), render: secTemplateTypeRender },
        ];

        $scope.newElement = function () {
            var modal = CommonAdminModals.newSecTemplate;
            modal.annexaFormly.model = {};
            modal.annexaFormly.model.modal_body = { family: {} };
            modal.annexaFormly.options = {};
            var editCircuitField = $linq(modal.annexaFormly.fields[0].fieldGroup).firstOrDefault(undefined,"x => x.key == 'editCircuit'");
            if(editCircuitField) {
            	editCircuitField.templateOptions.options = [ { label: $filter('translate')('global.literals.editCircuit'), value: true }];
            }
            var councillorSignField = $linq(modal.annexaFormly.fields[0].fieldGroup).firstOrDefault(undefined,"x => x.key == 'councillorSign'");
            if(councillorSignField) {
            	councillorSignField.templateOptions.options = [ { label: $filter('translate')('global.literals.councillorSign'), value: true }];
            	if($rootScope && $rootScope.app && $rootScope.app.configuration && $rootScope.app.configuration.councillor_proposal_type && !$rootScope.app.configuration.councillor_proposal_type.active) {
            		councillorSignField.hideExpression = function(){return true;}
            	}
            }
            var conditionedSignCircuitListField = $linq(modal.annexaFormly.fields[0].fieldGroup).firstOrDefault(undefined, "x => x.key == 'conditionedSignCircuitList'");
            if(conditionedSignCircuitListField) {
            	conditionedSignCircuitListField.data.conditionedSignCircuitList = [];
            }
            AnnexaFormlyFactory.showModal('modalNewSecTemplate', modal, $scope.saveSecTemplate, false);
        };

        $scope.saveSecTemplate = function (secTemplate) {
            var modal = this;
            var haveError = false;
            modal.alerts = [];

            var file = undefined;
            var model = {
                language1: secTemplate.annexaFormly.model.modal_body.language1,
                language2: secTemplate.annexaFormly.model.modal_body.language2 ? secTemplate.annexaFormly.model.modal_body.language2 : 'NotTranslated',
                language3: secTemplate.annexaFormly.model.modal_body.language3 ? secTemplate.annexaFormly.model.modal_body.language3 : 'NotTranslated',
                councillorSign: secTemplate.annexaFormly.model.modal_body.councillorSign_true || false,
                type: secTemplate.annexaFormly.model.modal_body.type ? secTemplate.annexaFormly.model.modal_body.type : undefined
            };
            
            if(secTemplate.annexaFormly.model.modal_body.file) {
            	file = secTemplate.annexaFormly.model.modal_body.file;
            }
            
            if(secTemplate.annexaFormly.model.modal_body.profiles){
            	model.profiles = [];
                _.forEach(secTemplate.annexaFormly.model.modal_body.profiles, function(item) {
                	model.profiles.push({ profile: { id: item } });
                });
        	}
            
            if(secTemplate.annexaFormly.model.modal_body.organs){
            	model.organs = [];
                _.forEach(secTemplate.annexaFormly.model.modal_body.organs, function(item) {
                	model.organs.push({ id: item });
                });
        	}
            
			if(secTemplate.annexaFormly.model.modal_body.documentType){
				model.documentType = {id: secTemplate.annexaFormly.model.modal_body.documentType};
			}
			
            if(!model.councillorSign) {
                var conditionedSignCircuitListField = $linq(modal.annexaFormly.fields[0].fieldGroup).firstOrDefault(undefined, "x => x.key == 'conditionedSignCircuitList'");
                if(conditionedSignCircuitListField) {
                	model.templateSignCircuit = conditionedSignCircuitListField.data.conditionedSignCircuitList;
                }
            }
			
            var acceptedFileType = true;
            var fileField = undefined;
            var modalBodyFields = $linq(modal.annexaFormly.fields).firstOrDefault(undefined,"x => x.key == 'modal_body'");
            if(modalBodyFields) {
                fileField = $linq(modalBodyFields.fieldGroup).firstOrDefault(undefined,"x => x.key == 'file'");
            }

            if(fileField && fileField.data && fileField.data.accept && model.file) {
                acceptedFileType = fileField.data.accept.includes(model.file.type);
            }
			
            if(acceptedFileType && !haveError) {
	            SecFactory.addSecTemplate(model, file).then(function() {
	                    $rootScope.$broadcast('annexaAdminBaseReloadTable');
	                    modal.close();
	            }).catch(function (error) {
					if(error && error.data && error.data.message === 'No preset sign circuits to update'){
						modal.alerts.push({ msg: $filter('translate')('global.errors.requiredSignCircuits') });
					}else{
						modal.alerts.push({msg: $filter('translate')('global.errors.unknownNew')});
					}
	            });
            } else if(!acceptedFileType) {
                DialogsFactory.error($filter('translate')('global.literals.documentTypeNotAccepted'));
            }
        };

        $scope.printEditPresetSignCircuit = function (model) {
            if(model) {
                return $filter('translate')('global.literals.editCircuit');
            } else {
                return $filter('translate')('global.literals.editCircuitNo');
            }
        }
        
        $scope.printCouncillorSign = function (model) {
            if(model) {
                return $filter('translate')('global.literals.councillorSign');
            } else {
                return $filter('translate')('global.literals.councillorSignNo');
            }
        }
        
        $scope.printPresetSignCircuits = function (model) {
            var presetSignCircuits = "";

            angular.forEach(model, function (val, key) {
                if(presetSignCircuits != "") {
                    presetSignCircuits += ", ";
                }

                presetSignCircuits += val[Language.getActiveColumn()];
            });

            return presetSignCircuits;
        }
        
        $scope.printOrgan = function (model) {
            var organs = "";

            angular.forEach(model, function (val, key) {
                if(organs != "") {
                	organs += ", ";
                }

                organs += val[Language.getActiveColumn()];
            });

            return organs;
        }
        
        $scope.printSecTemplateType = function (modelValue) {
        	var secTemplateTypeId = '';
        	var modelValueAux = ((modelValue && modelValue.id)?modelValue.id:modelValue);
        	switch (modelValueAux) {
			case "SAC":
				secTemplateTypeId = 'act';
				break;
			case "SCR":
				secTemplateTypeId = 'certificate';
				break;
			case "SCO":
				secTemplateTypeId = 'announcement';
				break;
			case "SDI":
				secTemplateTypeId = 'opinion';
				break;
			case "SPA":
				secTemplateTypeId = 'preact';
				break;
			case "SPR":
				secTemplateTypeId = 'proposal';
				break;
			case "STR":
				secTemplateTypeId = 'transfer';
				break;
			case "SPT":
				secTemplateTypeId = 'pointExecutedOrder';
				break;
			case "SDR":
				secTemplateTypeId = 'decree';
				break;
			case "SPD":
				secTemplateTypeId = 'proposalDecree';
				break;
			case "OTHER":
				secTemplateTypeId = 'other';
				break;
			default:
				break;
			}
            
        	if (secTemplateTypeId && secTemplateTypeId != ""){
				if(secTemplateTypeId == "other"){
						return $filter('translate')('global.literals.others');
				}else{
					return $filter('translate')('global.sec.literals.' + secTemplateTypeId);
				}
			} 
        	else return "";
        }

        $scope.viewElement = function (id) {
            RestService.findOne('SecTemplate', id)
                .then(function(data) {
                    var modal = angular.copy(CommonAdminModals.editSecTemplate);
                    modal.alerts = [];
                    modal.data = JSOG.decode(data.data);
                    if(!modal.data.type) {
                        modal.data.type = {};
                    }
                    _.forEach(modal.data.templateSignCircuit, function (value, key) {
                    	value.secTemplate = {id: modal.data.id};
                    });
                    modal.data.conditionedSignCircuitList = modal.data.templateSignCircuit;
                    modal.data.isEdit = true;
                    modal.data.secTemplateId = modal.data.id;
                    modal.languageColumn = Language.getActiveColumn();
                    modal.replaceDocumentModal = $scope.replaceDocumentModal;
                    modal.editOnline = $scope.editOnline;
                    modal.download = $scope.download;
                    modal.secDocumentTypes = angular.copy(SecFactory.secDocumentTypes);
					modal.secDocumentTypes.push({id:undefined, language1: $filter('translate')('global.literals.others'), language2:$filter('translate')('global.literals.others'), language3:$filter('translate')('global.literals.others')});
                    modal.updateSecTemplate = $scope.updateSecTemplate;
                    modal.presetSignCircuits = GlobalDataFactory.presetSignCircuit;
                    modal.organs = $linq(GlobalDataFactory.organs).where(function(x){
						if(x.active){
							return true;
						}else{
							var existOrgans = ((modal.data && modal.data.organs)?$linq(modal.data.organs).select("x => x.id").toArray():[]);
							if(_.contains(existOrgans, x.id)){
								return true;
							}else{
								return false;
							}
						}
					}).toArray();
                    modal.printPresetSignCircuits = $scope.printPresetSignCircuits;
                    modal.printEditPresetSignCircuit = $scope.printEditPresetSignCircuit;
                    modal.printCouncillorSign = $scope.printCouncillorSign;
                    modal.secTemplateTypes = SecFactory.secTemplateTypes;
                    modal.printSecTemplateType = $scope.printSecTemplateType;
                    modal.printOrgan = $scope.printOrgan;
                    modal.secTemplateTypeName = 'name';
                    modal.showCouncillor = ($rootScope && $rootScope.app && $rootScope.app.configuration && $rootScope.app.configuration.councillor_proposal_type && $rootScope.app.configuration.councillor_proposal_type.active);
                    
                    modal.data.profiles = $linq(modal.data.profiles).select("x => x.profile").toArray();
                    modal.printProfiles = function (model) {
                        var perfiles = '';
                        angular.forEach(model, function (val, key) {
                            if(perfiles != '') {
                                perfiles += ', ';
                            }
                            perfiles += val[Language.getActiveColumn()];
                        });

                        return perfiles;
                    }
                    modal.printSecDocumentType = function(modelValue){
						if(modelValue && modelValue.id){
							return modelValue[modal.languageColumn];
						}else{
							return $filter('translate')('global.literals.others');
						}			
					}
                    var selectableOptions = [];
                    _.forEach(GlobalDataFactory.allProfiles, function (value, key) {
                        var haveProfile = false;
                        if(modal.data.profiles) {
                            haveProfile = modal.data.profiles.some(function (profile) {
                                  return profile.id === value.id;
                                });
                        }
                        if(value.expiryDate == null || haveProfile) {
                            selectableOptions.push(value);
                        }
                    });
                    
                    modal.profiles = selectableOptions;
                    $scope.modalSecTemplate = modal;
                    
                    AnnexaModalFactory.showModal('modalEditSecTemplate', modal);
                })
        };

        $scope.updateSecTemplate = function (val, prop) {
            var model = angular.copy($scope.modalSecTemplate.data);

            if(prop == 'documentType') {
				if(val && val.id){
	               model.documentType = { id: val.id };
				}else{
					model.documentType = undefined;
				}
            } else if (prop == 'presetSignCircuits') {
                if(!val || val.length == 0) {
                    return $filter('translate')('global.literals.presetSignCircuitRequired');
                }else{
                    model.presetSignCircuits = val
                }
            } else if (prop == 'type') {
            	model.type = val.id;
            } else if (prop != 'language1' && prop != 'language2' && prop != 'language3'){
            	model[prop] = val;
            }

            if(model.type.hasOwnProperty('model')) {
                if(model.type.model.$selected) {
                    model.type = {id: model.type.model.$selected.id};
                } else {
                    model.type = undefined;
                }
            }
            
            if(prop == 'profiles'){
            	model[prop] = [];
                _.forEach(val, function(item) {
                	model[prop].push( { profile: { id: item.id } });
                });
            } else if(model.profiles && model.profiles.length > 0){
            	var profiles = [];
            	_.forEach(model.profiles, function(item) {
            		profiles.push( { profile: { id: item.id } });
                });
            	model.profiles = profiles;
            }

            RestService.update('./api/sec/sec_template/' + model.id, model).then(function () {
                    $rootScope.$broadcast('annexaAdminBaseReloadTable');
            }).catch(function(error) {
                if(error && error.data && error.data.message === 'No preset sign circuits to update'){
					$scope.modalSecTemplate.alerts.push({ msg: $filter('translate')('global.errors.requiredSignCircuits') });
				}else{
					$scope.modalSecTemplate.alerts.push({msg: $filter('translate')('global.errors.unknownNew')});
				}
            });
        };

        $scope.replaceDocument = function () {
            if(this.annexaFormly.form.$valid) {
                $rootScope.loading(true);
                var self = this;
                var data = this.annexaFormly.model.modal_body;

                var send = {
                    idTemplate: this.template.id,
                    file: data.file
                };

                var acceptedFileType = true;
                var fileField = undefined;
                var modalBodyFields = $linq(this.annexaFormly.fields).firstOrDefault(undefined,"x => x.key == 'modal_body'");
                if(modalBodyFields) {
                    fileField = $linq(modalBodyFields.fieldGroup).firstOrDefault(undefined,"x => x.key == 'file'");
                }

                if(fileField && fileField.data && fileField.data.accept && data.file) {
                    acceptedFileType = fileField.data.accept.includes(data.file.type);
                }
                
                if(acceptedFileType) {

                    data.file.upload = Upload.upload({
                        url: './api/sec/sec_template/document',
                        data: send
                    });
                    
                    data.file.upload
                        .then(function (data) {
                            if(data.data) {
                                var updatedTemplate = JSOG.decode(data.data);
                                self.callerModal.data.fileName = updatedTemplate.fileName;
                                self.callerModal.data.fileTypeDoc = updatedTemplate.fileTypeDoc;
                                self.callerModal.data.idDocumentManager = updatedTemplate.idDocumentManager;
                                self.callerModal.data.sizeDoc = updatedTemplate.sizeDoc;
                                $rootScope.loading(false);
                                self.close();
                            }
                        }).catch(function (error) {
                        $rootScope.loading(false);
                    });
                } else {
                    DialogsFactory.error($filter('translate')('global.literals.documentTypeNotAccepted'));
                	$rootScope.loading(false);
                }
            }
        }

        $scope.replaceDocumentModal = function (template, callerModal) {
            var modal = globalModals.uploadFile;
            modal.title = 'global.literals.replace';
            modal.annexaFormly.model = {};
            modal.annexaFormly.model.modal_body = {};
            modal.annexaFormly.options = {};
            modal.annexaFormly.options.formState = { readOnly: false};
            modal.template = template;
            modal.callerModal = callerModal;
            modal.annexaFormly.fields[0].fieldGroup[0].data.accept = '\'application/vnd.openxmlformats-officedocument.wordprocessingml.document\'';
            AnnexaFormlyFactory.showModal('modalUploadFile', modal, $scope.replaceDocument, false);
        }

        $scope.editOnline = function (idDocumentManager) {
            CommonService.getEditOnlineUrl(idDocumentManager).then(function(eou) {
                if(eou != null && eou != undefined && eou != ''){
                    window.location.href=eou;
                }
            }).catch(function(){
            });
        }
        
        $scope.download = function (idDocumentManager) {
        	$rootScope.loading(true);
            $http.get("./api/repodocs/downloadAlfDoc?id="+idDocumentManager, {responseType: 'arraybuffer'})
                .success(function (data, status, headers) {
                    var retDownload = {
                        file: undefined,
                        fileName: undefined,
                        linkElement: undefined,
                        clickEvent: undefined
                    }
                    headers = headers();
                    var contentType = headers['content-type'];
                    retDownload.file = new Blob([data], {type: contentType});
                    retDownload.fileName = headers['content-disposition'].replace('attachment; filename="','').replace('"', '');
                    try {
                        if (window.navigator.msSaveOrOpenBlob) {
                            window.navigator.msSaveOrOpenBlob(retDownload.file, retDownload.fileName);
                        } else {
                            retDownload.linkElement = document.createElement('a');
                            var url = window.URL.createObjectURL(retDownload.file);
                            retDownload.linkElement.setAttribute('href', url);
                            retDownload.linkElement.setAttribute("download", retDownload.fileName);
                            retDownload.clickEvent = new MouseEvent("click", {
                                "view": window,
                                "bubbles": true,
                                "cancelable": false
                            });
                            retDownload.linkElement.dispatchEvent(retDownload.clickEvent);
                        }
                        $rootScope.loading(false);
                    } catch (e) {
                        $rootScope.loading(false);
                    }
                }).error(function (error) {
                    $rootScope.loading(false);
            });
        }
    }])
;