/**
 * Created by osirvent on 02/03/2017.
 */
angular
    .module('annexaApp')
    .controller('ConfigurationController',['$scope', 'AdminFactory', 'Language', '$rootScope', '$filter', 'HeaderService', '$timeout', '$state', 'dialogs', 'AnnexaFormlyFactory', 'RestService', 'GlobalDataFactory', 'CacheFactory', 'CommonAdminModals', 'AnnexaModalFactory', function ($scope, AdminFactory, Language, $rootScope, $filter, HeaderService, $timeout, $state, dialogs, AnnexaFormlyFactory, RestService, GlobalDataFactory, CacheFactory, CommonAdminModals, AnnexaModalFactory) {
        var data = {
            row: true,
            colClass: 'col-sm-12',
            labelClass: 'label-strong',
            extralabel: true,
            inline: true
        };

        //region Documents 

        $scope.documents_by_type = function() {
            var title = $linq($scope.form.fields).singleOrDefault({ templateOptions: { label: '' } }, "x => x.key == 'documents_by_type'");
            var conf = $linq(AdminFactory.configurations).singleOrDefault(undefined, "x => x.key === 'documents_by_type'");
            var confValue = JSON.parse(conf.value.replace(/\r?\n|\r/g, " "));

            var configurationModal = {
                title: title.templateOptions.label,
                size: 'modal-lg',
                icon: 'fa fa-gear',
                annexaFormly: {},
                alerts: [],
                submitModal: function () {}
            };

            var form = new AnnexaFormly();
            form.model = {};

            form.addField(
                'active',
                'annexaRadioCheckboxRow',
                'col-sm-12',
                new AnnexaFormlyFieldSelectTemplateOptions(
                    'global.literals.active',
                    'value',
                    'label',
                    [{ value: 'true', label: $filter('translate')('global.literals.yes') }, { value: 'false', label: $filter('translate')('global.literals.no') }],
                    false,
                    'radio'
                ),
                data
            );

            form.addField(
                'docTypes',
                'annexaDocumentsByTypeConfiguration',
                'col-sm-12',
                new AnnexaFormlyFieldTemplateOptions('text', 'lll', false, false),
                data,
                function($viewValue, $modelValue, scope) {
                    return scope.model.active != 'true';
                }
            );

            form.model = confValue;

            configurationModal.annexaFormly = form;

            var saveConfiguration = function() {
                var showFoldersTypes = [];

                if(this.annexaFormly.model.active == "true" && this.annexaFormly.model.docTypes.length == 0) {
                    this.alerts.push({ msg: 'global.errors.addDocumentType' });
                } else {
                    var saveConf = '';

                    if(this.annexaFormly.model.active == "false") {
                        saveConf = '{"active":"false"}';
                    } else {
                        saveConf = '{"active":"true","docTypes":[';

                        var separator = '';

                        _.forEach(this.annexaFormly.model.docTypes, function(value) {
                            if(value.showFolders) {
                                showFoldersTypes.push(value.type.id);
                            }

                            saveConf += separator;
                            saveConf += '{';
                            saveConf += '"type":{"id":' + value.type.id + ',"language1":"' + value.type.language1 + '","language2":"' + value.type.language2 + '","language3":"' + value.type.language1 + '"},';
                            saveConf += '"showList":' + (value.showList ? 'true' : 'false') + ',';
                            saveConf += '"showFolders":' + (value.showFolders ? 'true' : 'false') + ',';
                            saveConf += '"iconType":{"id":"' + value.iconType.id + '","description":"' + value.iconType.description + '"},';
                            saveConf += '"icon":"' + value.icon + '",';

                            saveConf += '"tableColumns":[';

                            var separator1 = ''
                            _.forEach(value.tableColumns, function (value1) {
                                saveConf += separator1;
                                saveConf += '{';
                                saveConf += '"id":"' + value1.id + '",';
                                saveConf += '"language1":"' + value1.language1 + '",'
                                saveConf += '"language2":"' + value1.language2 + '",'
                                saveConf += '"language3":"' + value1.language3 + '",'
                                saveConf += '"type":"' + value1.type + '"'
                                saveConf += '}'
                                separator1 = ',';
                            })

                            saveConf += '],';

                            saveConf += '"filterColumns":[';

                            var separator1 = ''
                            _.forEach(value.filterColumns, function (value1) {
                                saveConf += separator1;
                                saveConf += '{';
                                saveConf += '"id":"' + value1.id + '",';
                                saveConf += '"language1":"' + value1.language1 + '",'
                                saveConf += '"language2":"' + value1.language2 + '",'
                                saveConf += '"language3":"' + value1.language3 + '",'
                                saveConf += '"type":"' + value1.type + '"'
                                saveConf += '}'
                                separator1 = ',';
                            })

                            saveConf += '],';

                            saveConf += '"folderColumns":[';

                            var separator1 = ''
                            _.forEach(value.folderColumns, function (value1) {
                                saveConf += separator1;
                                saveConf += '{';
                                saveConf += '"id":"' + value1.id + '",';
                                saveConf += '"language1":"' + value1.language1 + '",'
                                saveConf += '"language2":"' + value1.language2 + '",'
                                saveConf += '"language3":"' + value1.language3 + '",'
                                saveConf += '"type":"' + value1.type + '"'
                                saveConf += '}'
                                separator1 = ',';
                            })

                            saveConf += '],';

                            saveConf += '"predefinedFilters":[';

                            var separator1 = ''
							if(value.predefinedFilters){
	                            _.forEach(value.predefinedFilters, function (value1) {
	                                saveConf += separator1;
	                                saveConf += '{';
	                                saveConf += '"name":"' + value1.name + '",';
	                                saveConf += '"value":"' + value1.value + '",';
									saveConf += '"negated":"' + value1.negated + '"';
	                                saveConf += '}';
	                                separator1 = ',';
	                            })
							}
                            saveConf += ']';

                            if(value.orderBy && value.orderBy.id){
                            	saveConf += ',"orderBy":"'+value.orderBy.id+'"';
                            }
                            if(value.orderType && value.orderType.id){
                                saveConf += ',"orderType":"'+value.orderType.id+'"';
                            }
                            saveConf += '}'

                            separator = ','
                        });

                        saveConf += ']}';
                    }

                    var self = this;

                    AdminFactory.updateConfiguration('documents_by_type', saveConf, showFoldersTypes)
                        .then(function(data) {
                            self.close();
                            var indexConfig = $linq(AdminFactory.configurations).indexOf("x => x.key === 'documents_by_type'");
                            if(indexConfig != -1) {
                            	GlobalDataFactory.configuration = [];
                                if(CacheFactory.get('globalDataCache') && CacheFactory.get('globalDataCache').get('configuration')){
                                	CacheFactory.get('globalDataCache').remove('configuration')
                                }
                                GlobalDataFactory.loadGlobalData();
                            }

                        }).catch(function (error) {
                            self.alerts.push({ msg: 'global.errors.errorUpdateConfigValue' });
                    });
                }
            }

            AnnexaFormlyFactory.showModal('configurationModal', configurationModal, saveConfiguration, false);
        }
        
		$scope.alfresco_folders =  function(){
			var title = $linq($scope.form.fields).singleOrDefault({ templateOptions: { label: '' } }, "x => x.key == 'alfresco_folders'");
            var conf = $linq(AdminFactory.configurations).singleOrDefault(undefined, "x => x.key === 'alfresco_folders'");
            var confValue = JSON.parse(conf.value.replace(/\r?\n|\r/g, " "));
            var configurationModal = {
                title: title.templateOptions.label,
                icon: 'fa fa-gear',
                annexaFormly: {},
                alerts: [],
                submitModal: function () {}
            };

            var form = new AnnexaFormly();
            form.model = {};
            var keys = Object.keys(confValue);
            if(confValue && keys.length > 0){
            	_.forEach(keys, function(key){
            		if(key == "maxFolderDocuments" || key == "numFolderDocuments"){
            			form.addField(
    	                        key,
    	                        'annexaInputRow',
    	                        'col-sm-12',
    	                        new AnnexaFormlyFieldTemplateOptions(
    	                            'number',
    	                            'global.configuration.alfrescoFolders.'+key,
    	                            false
    	                        ),
    	                        data
    	                    );
            		}else if(key == "actualFolder"){
            			form.addField(
                                key,
                                'annexaInputRow',
                                'col-sm-12',
                                new AnnexaFormlyFieldTemplateOptions(
                                	'text',
                                	'global.configuration.alfrescoFolders.'+key,
                                    false
                                ),
                                data
                           );
            		}
            		
            	});
            }
            
            form.model = angular.copy(confValue);

            configurationModal.annexaFormly = form;

            var saveConfiguration = function() { 
            	var self = this;
            	var modelModal = this.annexaFormly.model;
            	var keysModal = Object.keys(modelModal);
            	var sendValue = angular.copy(modelModal);
            	_.forEach(keysModal, function(key){
           			sendValue[key] = modelModal[key];
            	});
            	sendValue = JSON.stringify(sendValue)
            	AdminFactory.updateConfiguration('alfresco_folders', sendValue, undefined)
	                .then(function(data) {
	                    self.close();
	                    var indexConfig = $linq(AdminFactory.configurations).indexOf("x => x.key === 'alfresco_folders'");
	                    if(indexConfig != -1) {
	                    	GlobalDataFactory.configuration = [];
	                    	if(CacheFactory.get('globalDataCache') && CacheFactory.get('globalDataCache').get('configuration')){
	                        	CacheFactory.get('globalDataCache').remove('configuration')
	                        }
	                        GlobalDataFactory.loadGlobalData();
	                    }
	
	                }).catch(function (error) {
	                    self.alerts.push({ msg: 'global.errors.errorUpdateConfigValue' });
	            });
			}
            AnnexaFormlyFactory.showModal('configurationModal', configurationModal, saveConfiguration, false);
    	}
		
		$scope.documents_convertibles_to_pdf =  function(){
			var title = $linq($scope.form.fields).singleOrDefault({ templateOptions: { label: '' } }, "x => x.key == 'documents_convertibles_to_pdf'");
            var conf = $linq(AdminFactory.configurations).singleOrDefault(undefined, "x => x.key === 'documents_convertibles_to_pdf'");
            var confValue = JSON.parse(conf.value.replace(/\r?\n|\r/g, " "));
            var configurationModal = {
                title: title.templateOptions.label,
                icon: 'fa fa-gear',
                annexaFormly: {},
                alerts: [],
                submitModal: function () {}
            };

            var form = new AnnexaFormly();
            form.model = {};
            var keys = Object.keys(confValue);
            if(confValue && keys.length > 0){
            	_.forEach(keys, function(key){
            		 if(key == "fileTypes"){
            			confValue[key] = JSON.stringify(confValue[key]);
            			form.addField(
                                key,
                                'annexaInputRow',
                                'col-sm-12',
                                new AnnexaFormlyFieldTemplateOptions(
                                	'text',
                                	'global.configuration.docConvertPDF.'+key,
                                    false
                                ),
                                data
                           );
            		}
            		
            	});
            }
            
            form.model = angular.copy(confValue);

            configurationModal.annexaFormly = form;

            var saveConfiguration = function() { 
            	var self = this;
            	var modelModal = this.annexaFormly.model;
            	var keysModal = Object.keys(modelModal);
            	var sendValue = angular.copy(modelModal);
            	var checkOk = true;
            	_.forEach(keysModal, function(key){
            		if(key == "fileTypes" ){
            			if(modelModal[key].match(/^\[\{"fileType":"[^"]*"\}(,\{"fileType":"[^"]*"\})*\]$/g)){
            				sendValue[key] = JSON.parse(modelModal[key].replace(/\r?\n|\r/g, " "));
            			}else{
            				self.alerts.push({ msg: 'global.configuration.docConvertPDF.errorCheckStructureKey' });
            				checkOk=false;
            			}
            		}else{
            			sendValue[key] = modelModal[key];
            		}
            	});
            	if(checkOk){
            		sendValue = JSON.stringify(sendValue)
            		AdminFactory.updateConfiguration('documents_convertibles_to_pdf', sendValue, undefined)
	                .then(function(data) {
	                    self.close();
	                    var indexConfig = $linq(AdminFactory.configurations).indexOf("x => x.key === 'documents_convertibles_to_pdf'");
	                    if(indexConfig != -1) {
	                    	GlobalDataFactory.configuration = [];
	                    	if(CacheFactory.get('globalDataCache') && CacheFactory.get('globalDataCache').get('configuration')){
	                        	CacheFactory.get('globalDataCache').remove('configuration')
	                        }
	                        GlobalDataFactory.loadGlobalData();
	                    }
	
	                }).catch(function (error) {
	                    self.alerts.push({ msg: 'global.errors.errorUpdateConfigValue' });
	            });
            	}
            	
			}
	
	        AnnexaFormlyFactory.showModal('configurationModal', configurationModal, saveConfiguration, false);
    	}
		
		$scope.validate_document_content =  function(){
			var title = $linq($scope.form.fields).singleOrDefault({ templateOptions: { label: '' } }, "x => x.key == 'validate_document_content'");
            var conf = $linq(AdminFactory.configurations).singleOrDefault(undefined, "x => x.key === 'validate_document_content'");
            var confValue = JSON.parse(conf.value.replace(/\r?\n|\r/g, " "));
            var configurationModal = {
                title: title.templateOptions.label,
                icon: 'fa fa-gear',
                annexaFormly: {},
                alerts: [],
                submitModal: function () {}
            };

            var form = new AnnexaFormly();
            form.model = {};
            var keys = Object.keys(confValue);
            if(confValue && keys.length > 0){
            	_.forEach(keys, function(key){
            		if(key == "validateBookmarks" || key == "validateBookmarksFromTemplate" || key == "validateTable" || key == "validateLink"){
            			form.addField(
            					key,
                                'annexaRadioCheckboxRow',
                                'col-sm-12',
                                new AnnexaFormlyFieldSelectTemplateOptions(
                                    'global.configuration.validate_document_content.'+key,
                                    'value',
                                    'label',
                                    [{ value: true, label: $filter('translate')('global.literals.yes') }, { value: false, label: $filter('translate')('global.literals.no') }],
                                    false,
                                    'radio'
                                ),
                                data
                			);
            		}
            		
            	});
            }
            
            form.model = angular.copy(confValue);

            configurationModal.annexaFormly = form;

            var saveConfiguration = function() { 
            	var self = this;
            	var modelModal = this.annexaFormly.model;
            	var keysModal = Object.keys(modelModal);
            	var sendValue = angular.copy(modelModal);
            	_.forEach(keysModal, function(key){
           			sendValue[key] = modelModal[key];
            	});
            	sendValue = JSON.stringify(sendValue)
            	AdminFactory.updateConfiguration('validate_document_content', sendValue, undefined)
	                .then(function(data) {
	                    self.close();
	                    var indexConfig = $linq(AdminFactory.configurations).indexOf("x => x.key === 'validate_document_content'");
	                    if(indexConfig != -1) {
	                    	GlobalDataFactory.configuration = [];
	                    	if(CacheFactory.get('globalDataCache') && CacheFactory.get('globalDataCache').get('configuration')){
	                        	CacheFactory.get('globalDataCache').remove('configuration')
	                        }
	                        GlobalDataFactory.loadGlobalData();
	                    }
	
	                }).catch(function (error) {
	                    self.alerts.push({ msg: 'global.errors.errorUpdateConfigValue' });
	            });
			}
            AnnexaFormlyFactory.showModal('configurationModal', configurationModal, saveConfiguration, false);
    	}
		
		$scope.libreoffice_convert_to_pdfa =  function(){
			var title = $linq($scope.form.fields).singleOrDefault({ templateOptions: { label: '' } }, "x => x.key == 'libreoffice_convert_to_pdfa'");
            var conf = $linq(AdminFactory.configurations).singleOrDefault(undefined, "x => x.key === 'libreoffice_convert_to_pdfa'");
            var confValue = JSON.parse(conf.value.replace(/\r?\n|\r/g, " "));
            var configurationModal = {
                title: title.templateOptions.label,
                icon: 'fa fa-gear',
                annexaFormly: {},
                alerts: [],
                submitModal: function () {}
            };

            var form = new AnnexaFormly();
            form.model = {};
            var keys = Object.keys(confValue);
            if(confValue && keys.length > 0){
            	_.forEach(keys, function(key){
            		if(key == "active"){
            			form.addField(
            					key,
                                'annexaRadioCheckboxRow',
                                'col-sm-12',
                                new AnnexaFormlyFieldSelectTemplateOptions(
                                    'global.configuration.libreoffice_convert_to_pdfa.'+key,
                                    'value',
                                    'label',
                                    [{ value: true, label: $filter('translate')('global.literals.yes') }, { value: false, label: $filter('translate')('global.literals.no') }],
                                    false,
                                    'radio'
                                ),
                                data
                			);
            		}else if(key == "connectionProperties"){
            			form.addField(
                                key,
                                'annexaInputRow',
                                'col-sm-12',
                                new AnnexaFormlyFieldTemplateOptions(
                                	'text',
                                	'global.configuration.libreoffice_convert_to_pdfa.'+key,
                                    false
                                ),
                                data
                           );
            		}else if(key == "extensionsToConvert"){
            			confValue[key] = confValue[key].toString();
            			form.addField(
                                key,
                                'annexaInputRow',
                                'col-sm-12',
                                new AnnexaFormlyFieldTemplateOptions(
                                	'text',
                                	'global.configuration.libreoffice_convert_to_pdfa.'+key,
                                    false
                                ),
                                data
                           );
            		}
            		
            	});
            }
            
            form.model = angular.copy(confValue);

            configurationModal.annexaFormly = form;

            var saveConfiguration = function() { 
            	var self = this;
            	var modelModal = this.annexaFormly.model;
            	var keysModal = Object.keys(modelModal);
            	var sendValue = angular.copy(modelModal);
            	_.forEach(keysModal, function(key){
            		var ammendKeys = [];
            		if(key == "extensionsToConvert" && modelModal[key] != ""){
            			sendValue[key] = modelModal[key].split(",");
            			_.forEach(sendValue[key], function(keys){
            				ammendKeys.push(keys.trim());
            			});
            			sendValue[key] = ammendKeys;
            		}else if(key == "extensionsToConvert" && modelModal[key] == ""){
            			sendValue[key] = [];
            		}else{
            			sendValue[key] = modelModal[key];
            		}
            	});
            	sendValue = JSON.stringify(sendValue)
            	AdminFactory.updateConfiguration('libreoffice_convert_to_pdfa', sendValue, undefined)
	                .then(function(data) {
	                    self.close();
	                    var indexConfig = $linq(AdminFactory.configurations).indexOf("x => x.key === 'libreoffice_convert_to_pdfa'");
	                    if(indexConfig != -1) {
	                    	GlobalDataFactory.configuration = [];
	                    	if(CacheFactory.get('globalDataCache') && CacheFactory.get('globalDataCache').get('configuration')){
	                        	CacheFactory.get('globalDataCache').remove('configuration')
	                        }
	                        GlobalDataFactory.loadGlobalData();
	                    }
	
	                }).catch(function (error) {
	                    self.alerts.push({ msg: 'global.errors.errorUpdateConfigValue' });
	            });
			}
            AnnexaFormlyFactory.showModal('configurationModal', configurationModal, saveConfiguration, false);
    	}
		
		$scope.remove_scan_temp_files =  function(){
			var title = $linq($scope.form.fields).singleOrDefault({ templateOptions: { label: '' } }, "x => x.key == 'remove_scan_temp_files'");
            var conf = $linq(AdminFactory.configurations).singleOrDefault(undefined, "x => x.key === 'remove_scan_temp_files'");
            var confValue = JSON.parse(conf.value.replace(/\r?\n|\r/g, " "));
            var configurationModal = {
                title: title.templateOptions.label,
                icon: 'fa fa-gear',
                annexaFormly: {},
                alerts: [],
                submitModal: function () {}
            };

            var form = new AnnexaFormly();
            form.model = {};
            form.addField(
                    'maxFiles',
                    'annexaInputRow',
                    'col-sm-12',
                    new AnnexaFormlyFieldTemplateOptions(
                        'number',
                        'global.configuration.remove_scan_temp_files.maxFiles',
                        false
                    ),
                    data
                );
            
            form.model = angular.copy(confValue);

            configurationModal.annexaFormly = form;

            var saveConfiguration = function() { 
            	var self = this;
            	var modelModal = this.annexaFormly.model;
            	var keysModal = Object.keys(modelModal);
            	var sendValue = angular.copy(modelModal);
            	_.forEach(keysModal, function(key){
           			sendValue[key] = modelModal[key];
            	});
            	sendValue = JSON.stringify(sendValue)
            	AdminFactory.updateConfiguration('remove_scan_temp_files', sendValue, undefined)
	                .then(function(data) {
	                    self.close();
	                    var indexConfig = $linq(AdminFactory.configurations).indexOf("x => x.key === 'remove_scan_temp_files'");
	                    if(indexConfig != -1) {
	                    	GlobalDataFactory.configuration = [];
	                    	if(CacheFactory.get('globalDataCache') && CacheFactory.get('globalDataCache').get('configuration')){
	                        	CacheFactory.get('globalDataCache').remove('configuration')
	                        }
	                        GlobalDataFactory.loadGlobalData();
	                    }
	
	                }).catch(function (error) {
	                    self.alerts.push({ msg: 'global.errors.errorUpdateConfigValue' });
	            });
			}
            AnnexaFormlyFactory.showModal('configurationModal', configurationModal, saveConfiguration, false);
    	}
		
		$scope.emgde_dossier_configuration =  function(){
			var title = $linq($scope.form.fields).singleOrDefault({ templateOptions: { label: '' } }, "x => x.key == 'emgde_dossier_configuration'");
            var conf = $linq(AdminFactory.configurations).singleOrDefault(undefined, "x => x.key === 'emgde_dossier_configuration'");
            var confValue = JSON.parse(conf.value.replace(/\r?\n|\r/g, " "));
            var configurationModal = {
                title: title.templateOptions.label,
                icon: 'fa fa-gear',
                annexaFormly: {},
                alerts: [],
                submitModal: function () {}
            };

            var form = new AnnexaFormly();
            form.model = {};
            var keys = Object.keys(confValue);
            if(confValue && keys.length > 0){
            	_.forEach(keys, function(key){
            		if(key == "essentialDocument"){
            			form.addField(
            					key,
                                'annexaRadioCheckboxRow',
                                'col-sm-12',
                                new AnnexaFormlyFieldSelectTemplateOptions(
                                    'global.configuration.emgde_dossier_configuration.'+key,
                                    'value',
                                    'label',
                                    [{ value: true, label: $filter('translate')('global.literals.yes') }, { value: false, label: $filter('translate')('global.literals.no') }],
                                    false,
                                    'radio'
                                ),
                                data
                			);
            		}else if(key == "terms" || key == "transferTerms" || key == "opinionTerms"){
            			form.addField(
                                key,
                                'annexaInputRow',
                                'col-sm-12',
                                new AnnexaFormlyFieldTemplateOptions(
                                    'number',
                                    'global.configuration.emgde_dossier_configuration.'+key,
                                    false
                                ),
                                data
                            );
            		}else{
            			form.addField(
                                key,
                                'annexaInputRow',
                                'col-sm-12',
                                new AnnexaFormlyFieldTemplateOptions(
                                	'text',
                                	'global.configuration.emgde_dossier_configuration.'+key,
                                    false
                                ),
                                data
                           );
            		}
            		
            	});
            }
            
            form.model = angular.copy(confValue);

            configurationModal.annexaFormly = form;

            var saveConfiguration = function() { 
            	var self = this;
            	var modelModal = this.annexaFormly.model;
            	var keysModal = Object.keys(modelModal);
            	var sendValue = angular.copy(modelModal);
            	_.forEach(keysModal, function(key){
        			sendValue[key] = modelModal[key];
            	});
            	sendValue = JSON.stringify(sendValue)
            	AdminFactory.updateConfiguration('emgde_dossier_configuration', sendValue, undefined)
	                .then(function(data) {
	                    self.close();
	                    var indexConfig = $linq(AdminFactory.configurations).indexOf("x => x.key === 'emgde_dossier_configuration'");
	                    if(indexConfig != -1) {
	                    	GlobalDataFactory.configuration = [];
	                    	if(CacheFactory.get('globalDataCache') && CacheFactory.get('globalDataCache').get('configuration')){
	                        	CacheFactory.get('globalDataCache').remove('configuration')
	                        }
	                        GlobalDataFactory.loadGlobalData();
	                    }
	
	                }).catch(function (error) {
	                    self.alerts.push({ msg: 'global.errors.errorUpdateConfigValue' });
	            });
			}
            AnnexaFormlyFactory.showModal('configurationModal', configurationModal, saveConfiguration, false);
    	}
		
		$scope.internal_control_valid_document_types =  function(){
			var title = $linq($scope.form.fields).singleOrDefault({ templateOptions: { label: '' } }, "x => x.key == 'internal_control_valid_document_types'");
            var conf = $linq(AdminFactory.configurations).singleOrDefault(undefined, "x => x.key === 'internal_control_valid_document_types'");
            var confValue = JSON.parse(conf.value.replace(/\r?\n|\r/g, " "));
            var configurationModal = {
                title: title.templateOptions.label,
                icon: 'fa fa-gear',
                annexaFormly: {},
                alerts: [],
                submitModal: function () {}
            };
            var assignOptionsDocTypes = [
            	{id:'NEW', name:$filter('translate')('global.configuration.internal_control_valid_document_types.docTypes.NEW')},
            	{id:'NEW_AND_ATTACHMENT', name:$filter('translate')('global.configuration.internal_control_valid_document_types.docTypes.NEW_AND_ATTACHMENT')},
            	{id:'PENDING_SIGN', name:$filter('translate')('global.configuration.internal_control_valid_document_types.docTypes.PENDING_SIGN')},
            	{id:'SIGNED', name:$filter('translate')('global.configuration.internal_control_valid_document_types.docTypes.SIGNED')},
            	{id:'COMPLETE', name:$filter('translate')('global.configuration.internal_control_valid_document_types.docTypes.COMPLETE')},
            	{id:'REJECTED', name:$filter('translate')('global.configuration.internal_control_valid_document_types.docTypes.REJECTED')},
            	{id:'ARCHIVED', name:$filter('translate')('global.configuration.internal_control_valid_document_types.docTypes.ARCHIVED')}
            ];
            
            var assignOptionsRelationType = [
            	{id:'DOCUMENT', name:$filter('translate')('global.configuration.internal_control_valid_document_types.relationType.DOCUMENT')},
            	{id:'INTERNAL_DOCUMENT', name:$filter('translate')('global.configuration.internal_control_valid_document_types.relationType.INTERNAL_DOCUMENT')},
            	{id:'WORK_DOCUMENT', name:$filter('translate')('global.configuration.internal_control_valid_document_types.relationType.WORK_DOCUMENT')},
            ];

            var form = new AnnexaFormly();
            form.model = {};
            var keys = Object.keys(confValue);
            if(confValue && keys.length > 0){
            	_.forEach(keys, function(key){
            		if(key == "docTypes"){
		        		form.addField(
		    				key,
		                    'annexaMultipleSelectRow',
		                    'col-sm-12',
		                    new AnnexaFormlyFieldSelectTemplateOptions(
		                		'global.configuration.internal_control_valid_document_types.docTypes.title',
		                        'id',
		                        'name',
		                        assignOptionsDocTypes,
		                        true, 
		                        undefined, 
		                        undefined, 
		                        undefined, 
		                        undefined, 
		                        true
		                    ),
		                    data
		                );
            		} else if(key == "relationType") {
            			form.addField(
        					key,
		                    'annexaSelectRow',
		                    '',
		                    new AnnexaFormlyFieldSelectTemplateOptions(
	                    		'global.configuration.internal_control_valid_document_types.relationType.title',
		                        'id',
		                        'name',
		                        assignOptionsRelationType,
		                        true
		                    ),
		                    data
		                );
            		} else if(key == "paperBin"){
            			form.addField(
                            key,
                            'annexaRadioCheckboxRow',
                            '',
                            new AnnexaFormlyFieldSelectTemplateOptions(
                            	'global.configuration.internal_control_valid_document_types.paperBin.title',
                                'value',
                                'label',
                                [{ value: true, label: $filter('translate')('global.literals.yes') }, { value: false, label: $filter('translate')('global.literals.no') }],
                                false,
                                'radio'
                            ),
                            data
                        );
            		}
            	});
            }
            
            form.model = angular.copy(confValue);

            configurationModal.annexaFormly = form;

            var saveConfiguration = function() { 
            	var self = this;
            	var modelModal = this.annexaFormly.model;
            	var keysModal = Object.keys(modelModal);
            	var sendValue = angular.copy(modelModal);
            	_.forEach(keysModal, function(key){
           			sendValue[key] = modelModal[key];
            	});
            	sendValue = JSON.stringify(sendValue)
            	AdminFactory.updateConfiguration('internal_control_valid_document_types', sendValue, undefined)
	                .then(function(data) {
	                    self.close();
	                    var indexConfig = $linq(AdminFactory.configurations).indexOf("x => x.key === 'internal_control_valid_document_types'");
	                    if(indexConfig != -1) {
	                    	GlobalDataFactory.configuration = [];
	                    	if(CacheFactory.get('globalDataCache') && CacheFactory.get('globalDataCache').get('configuration')){
	                        	CacheFactory.get('globalDataCache').remove('configuration')
	                        }
	                        GlobalDataFactory.loadGlobalData();
	                    }
	
	                }).catch(function (error) {
	                    self.alerts.push({ msg: 'global.errors.errorUpdateConfigValue' });
	            });
			}
            AnnexaFormlyFactory.showModal('configurationModal', configurationModal, saveConfiguration, false);
    	};
    	
    	$scope.emgde_configuration =  function(){
			var title = $linq($scope.form.fields).singleOrDefault({ templateOptions: { label: '' } }, "x => x.key == 'emgde_configuration'");
            var conf = $linq(AdminFactory.configurations).singleOrDefault(undefined, "x => x.key === 'emgde_configuration'");
            var confValue = JSON.parse(conf.value.replace(/\r?\n|\r/g, " "));
            var configurationModal = {
                title: title.templateOptions.label,
                icon: 'fa fa-gear',
                annexaFormly: {},
                alerts: [],
                submitModal: function () {}
            };
           
            var form = new AnnexaFormly();
            form.model = {};
            form.model = angular.copy(confValue);
            var keys = Object.keys(confValue);
            if(confValue && keys.length > 0){
            	_.forEach(keys, function(key){
            		if(key == "transferTerms" || key == "opinionTerms"|| key == "terms"){
            			form.addField(
                            key,
                            'annexaInputRow',
                            '',
                            new AnnexaFormlyFieldTemplateOptions(
                                'number',
                                'global.configuration.emgde_configuration.'+key,
                                false
                            ),
                            data
                        );
            		} else if(key == "essentialDocument"){
            			form.addField(
	    					key,
	                        'annexaRadioCheckboxRow',
	                        'col-sm-12',
	                        new AnnexaFormlyFieldSelectTemplateOptions(
	                            'global.configuration.emgde_configuration.'+key,
	                            'value',
	                            'label',
	                            [{ value: true, label: $filter('translate')('global.literals.yes') }, { value: false, label: $filter('translate')('global.literals.no') }],
	                            false,
	                            'radio'
	                        ),
	                        data
	                   );
            		} else {
	        			form.addField(
                            key,
                            'annexaInputRow',
                            '',
                            new AnnexaFormlyFieldTemplateOptions(
                                'text',
                                'global.configuration.emgde_configuration.'+key,
                                false
                            ),
                            data
                        );	
            		}
            	});
            }
            
            configurationModal.annexaFormly = form;

            var saveConfiguration = function() { 
            	var self = this;
            	var modelModal = this.annexaFormly.model;
            	var keysModal = Object.keys(modelModal);
            	var sendValue = angular.copy(modelModal);
            	_.forEach(keysModal, function(key){
        			sendValue[key] = modelModal[key];
            	});
            	sendValue = JSON.stringify(sendValue)
            	AdminFactory.updateConfiguration('emgde_configuration', sendValue, undefined)
	                .then(function(data) {
	                    self.close();
	                    var indexConfig = $linq(AdminFactory.configurations).indexOf("x => x.key === 'emgde_configuration'");
	                    if(indexConfig != -1) {
	                    	GlobalDataFactory.configuration = [];
	                    	if(CacheFactory.get('globalDataCache') && CacheFactory.get('globalDataCache').get('configuration')){
	                        	CacheFactory.get('globalDataCache').remove('configuration')
	                        }
	                        GlobalDataFactory.loadGlobalData();
	                    }
	
	                }).catch(function (error) {
	                    self.alerts.push({ msg: 'global.errors.errorUpdateConfigValue' });
	            });
			}
            AnnexaFormlyFactory.showModal('configurationModal', configurationModal, saveConfiguration, false);
    	};
    	
    	$scope.mail_document_type =  function(){
			var title = $linq($scope.form.fields).singleOrDefault({ templateOptions: { label: '' } }, "x => x.key == 'mail_document_type'");
            var conf = $linq(AdminFactory.configurations).singleOrDefault(undefined, "x => x.key === 'mail_document_type'");
            var confValue = JSON.parse(conf.value.replace(/\r?\n|\r/g, " "));
            var configurationModal = {
                title: title.templateOptions.label,
                icon: 'fa fa-gear',
                annexaFormly: {},
                alerts: [],
                submitModal: function () {}
            };
            var typesAssignOptions = GlobalDataFactory.documentTypes;
            var form = new AnnexaFormly();
            form.model = {};
            form.model = angular.copy(confValue);
            var keys = Object.keys(confValue);
            if(confValue && keys.length > 0){
            	_.forEach(keys, function(key){
            		if(key == "enabled"){
	        			form.addField(
	    					key,
	                        'annexaRadioCheckboxRow',
	                        'col-sm-12',
	                        new AnnexaFormlyFieldSelectTemplateOptions(
	                            'global.configuration.mail_document_type.'+key,
	                            'value',
	                            'label',
	                            [{ value: true, label: $filter('translate')('global.literals.yes') }, { value: false, label: $filter('translate')('global.literals.no') }],
	                            false,
	                            'radio'
	                        ),
	                        data
	                   );
            		} else {
            			form.addField(
		            		key,
		                    'annexaSelectRow',
		                    'col-sm-12',
		                    new AnnexaFormlyFieldSelectTemplateOptions(
	                    		'global.configuration.mail_document_type.'+key,
		                        'id',
		                        'acronym',
		                        typesAssignOptions,
		                        false
		                    ),
		                    data
		                );
            		}
            	});
            }
            
            configurationModal.annexaFormly = form;

            var saveConfiguration = function() { 
            	var self = this;
            	var modelModal = this.annexaFormly.model;
            	var keysModal = Object.keys(modelModal);
            	var sendValue = angular.copy(modelModal);
            	_.forEach(keysModal, function(key){
        			sendValue[key] = modelModal[key];
            	});
            	sendValue = JSON.stringify(sendValue)
            	AdminFactory.updateConfiguration('mail_document_type', sendValue, undefined)
	                .then(function(data) {
	                    self.close();
	                    var indexConfig = $linq(AdminFactory.configurations).indexOf("x => x.key === 'mail_document_type'");
	                    if(indexConfig != -1) {
	                    	GlobalDataFactory.configuration = [];
	                    	if(CacheFactory.get('globalDataCache') && CacheFactory.get('globalDataCache').get('configuration')){
	                        	CacheFactory.get('globalDataCache').remove('configuration')
	                        }
	                        GlobalDataFactory.loadGlobalData();
	                    }
	
	                }).catch(function (error) {
	                    self.alerts.push({ msg: 'global.errors.errorUpdateConfigValue' });
	            });
			}
            AnnexaFormlyFactory.showModal('configurationModal', configurationModal, saveConfiguration, false);
    	};
		
        //endregion

        
        //region secretaria
        $scope.secretary_properties =  function(){
        	var title = $linq($scope.form.fields).singleOrDefault({ templateOptions: { label: '' } }, "x => x.key == 'secretary_properties'");
            var conf = $linq(AdminFactory.configurations).singleOrDefault(undefined, "x => x.key === 'secretary_properties'");
            var confValue = JSON.parse(conf.value.replace(/\r?\n|\r/g, " "));
            var profiles = GlobalDataFactory.allProfiles;
			var transactiontypesReparation = ((GlobalDataFactory.transactiontypes)? $linq(GlobalDataFactory.transactiontypes).where("x => x.transactionSubtype == 12").toArray():[]);
			var transactiontypesReparationFree = ((GlobalDataFactory.transactiontypes)? $linq(GlobalDataFactory.transactiontypes).where("x => x.transactionSubtype == 14").toArray():[]);
            var numbersTypesSecProp = [
            	{id:'global', name:$filter('translate')('global.sec.literals.global')},
            	{id:'anual', name:$filter('translate')('global.sec.literals.anual')}
            ];
            var whenGenerateSessionNumberProp = [
            	{id:'convene', name:$filter('translate')('global.sec.literals.convene')},
            	{id:'start', name:$filter('translate')('global.sec.literals.start')}
            ];
            var configurationModal = {
                title: title.templateOptions.label,
                icon: 'fa fa-gear',
                annexaFormly: {},
                alerts: [],
                submitModal: function () {}
            };
            
            var form = new AnnexaFormly();
            form.model = {};
            var keys = Object.keys(confValue);
            if(confValue && keys.length > 0){
            	_.forEach(keys, function(key){
            		if(_.contains(['secretary_profile','intervention_profile'],key)){ 
		            	form.addField(
		            		key,
		                    'annexaSelectRow',
		                    '',
		                    new AnnexaFormlyFieldSelectTemplateOptions(
		                    	'global.sec.literals.'+key,
		                        'id',
		                        Language.getActiveColumn(),
		                        profiles,
		                        true
		                    ),
		                    data
		                );
            		}else if(_.contains(['inspection_department_profile'],key)){
            			form.addField(
    		            		key,
    		                    'annexaSelectRow',
    		                    '',
    		                    new AnnexaFormlyFieldSelectTemplateOptions(
    		                    	'global.sec.literals.'+key,
    		                        'id',
    		                        Language.getActiveColumn(),
    		                        profiles,
    		                        false
    		                    ),
    		                    data
    		                );
            		}else if(_.contains(['session_number','proposal_number', 'decree_number'],key)){
            			form.addField(
		            		key,
		                    'annexaSelectRow',
		                    '',
		                    new AnnexaFormlyFieldSelectTemplateOptions(
		                    	'global.sec.literals.'+key,
		                        'id',
		                        'name',
		                        numbersTypesSecProp,
		                        true
		                    ),
		                    data
		                );
            		}else if(key == 'proposal_leading_zeros'){
            			form.addField(
                            key,
                            'annexaInputRow',
                            '',
                            new AnnexaFormlyFieldTemplateOptions(
                                'number',
                                'global.sec.literals.'+key,
                                false
                            ),
                            data
                        );
            		}else if(key == 'proposal_number_pattern'){
            			form.addField(
                            key,
                            'annexaInputRow',
                            '',
                            new AnnexaFormlyFieldTemplateOptions(
                                'text',
                                'global.sec.literals.'+key,
                                false
                            ),
                            data
                        );

            		}else if(_.contains(['spa_required','restrictive_internal_control','inspection_department','internal_control','restrictive_secretary'],key)){
            			form.addField(
                            key,
                            'annexaRadioCheckboxRow',
                            '',
                            new AnnexaFormlyFieldSelectTemplateOptions(
                            	'global.sec.literals.'+key,
                                'value',
                                'label',
                                [{ value: true, label: $filter('translate')('global.literals.yes') }, { value: false, label: $filter('translate')('global.literals.no') }],
                                false,
                                'radio'
                            ),
                            data
                        );
            		}else if(key == 'proposal_certificate_title_pattern'){
            			form.addField(
                            key,
                            'annexaInputRow',
                            '',
                            new AnnexaFormlyFieldTemplateOptions(
                                'text',
                                'global.sec.literals.'+key,
                                false
                            ),
                            data
                        );

            		}else if(_.contains(['fiscalizationAmmendment'],key)){ 
		            	form.addField(
		            		key,
		                    'annexaSelectRow',
		                    '',
		                    new AnnexaFormlyFieldSelectTemplateOptions(
		                    	'global.sec.literals.'+key,
		                        'id',
		                        Language.getActiveColumn(),
		                        transactiontypesReparation,
		                        true
		                    ),
		                    data
		                );
            		}else if(_.contains(['fiscalizationAmmendmentFree'],key)){ 
		            	form.addField(
		            		key,
		                    'annexaSelectRow',
		                    '',
		                    new AnnexaFormlyFieldSelectTemplateOptions(
		                    	'global.sec.literals.'+key,
		                        'id',
		                        Language.getActiveColumn(),
		                        transactiontypesReparationFree,
		                        true
		                    ),
		                    data
		                );
            		}else if(key == 'when_generate_session_number') {
            			form.addField(
		            		key,
		                    'annexaSelectRow',
		                    '',
		                    new AnnexaFormlyFieldSelectTemplateOptions(
		                    	'global.sec.literals.'+key,
		                        'id',
		                        'name',
		                        whenGenerateSessionNumberProp,
		                        true
		                    ),
		                    data
		                );
            		}
            	});
            }
            form.model = angular.copy(confValue);
            form.model.proposal_leading_zeros =  Number(form.model.proposal_leading_zeros);
            form.model.spa_required = ((form.model.spa_required && form.model.spa_required == "true")?true:false);
            configurationModal.annexaFormly = form;

            var saveConfiguration = function() { 
            	var self = this;
            	var modelModal = this.annexaFormly.model;
            	var keysModal = Object.keys(modelModal);
            	var sendValue = angular.copy(modelModal);
            	_.forEach(keysModal, function(key){
            		if(key == 'proposal_leading_zeros' || key == 'spa_required' || key == 'intervention_profile' || key == 'inspection_department_profile'){ 
            			sendValue[key] = modelModal[key]+"";
            		}else{
            			sendValue[key] = modelModal[key];
            		}
            	});
            	sendValue = JSON.stringify(sendValue)
            	AdminFactory.updateConfiguration('secretary_properties', sendValue, undefined)
                .then(function(data) {
                    self.close();
                    var indexConfig = $linq(AdminFactory.configurations).indexOf("x => x.key === 'secretary_properties'");
                    if(indexConfig != -1) {
                    	GlobalDataFactory.configuration= [];
                    	if(CacheFactory.get('globalDataCache') && CacheFactory.get('globalDataCache').get('configuration')){
                        	CacheFactory.get('globalDataCache').remove('configuration')
                        }
                        GlobalDataFactory.loadGlobalData();
                    }

                }).catch(function (error) {
                    self.alerts.push({ msg: 'global.errors.errorUpdateConfigValue' });
            });
			}
	
	        AnnexaFormlyFactory.showModal('configurationModal', configurationModal, saveConfiguration, false);
    	}
        
        $scope.sec_document_types =  function(){
        	var title = $linq($scope.form.fields).singleOrDefault({ templateOptions: { label: '' } }, "x => x.key == 'sec_document_types'");
            var conf = $linq(AdminFactory.configurations).singleOrDefault(undefined, "x => x.key === 'sec_document_types'");
            var confValue = JSON.parse(conf.value.replace(/\r?\n|\r/g, " "));
            var documentTypes = GlobalDataFactory.allDocumentTypes;
            var documentStatussesValid = angular.copy(GlobalDataFactory.documentStatusesValidConfig);
            if(documentStatussesValid){
            	_.forEach(documentStatussesValid, function(value){
            		value.nameLiteral = $filter('translate')(value.nameLiteral);
            	});
            }
            var configurationModal = {
                title: title.templateOptions.label,
                icon: 'fa fa-gear',
                annexaFormly: {},
                alerts: [],
                submitModal: function () {}
            };

            var form = new AnnexaFormly();
            form.model = {};
            var keys = Object.keys(confValue);
            var modelToModal = {};
            if(confValue && keys.length > 0){
            	_.forEach(keys, function(key){
            		modelToModal[key] = [];
            		modelToModal[key+'_state'] = [];
            		if(confValue && confValue[key] && confValue[key].id){
            			_.forEach(confValue[key].id, function(id){
            				modelToModal[key].push(parseInt(id));
            			});
            		}
            		if(!_.contains(['SPT', 'STR'], key)){
	            		if(confValue && confValue[key] && confValue[key].valid_states){
	            			_.forEach(confValue[key].valid_states, function(valid_state){
	            				modelToModal[key+'_state'].push(valid_state);
	            			});
	            		}
            		}
            		form.addField(
	            		key,
	                    'annexaMultipleSelectRow',
	                    '',
	                    new AnnexaFormlyFieldSelectTemplateOptions(
	                    	confValue[key].name,
	                        'id',
	                        Language.getActiveColumn(),
	                        documentTypes,
	                        true, 
	                        undefined, 
	                        undefined, 
	                        undefined, 
	                        undefined, 
	                        true
	                    ),
	                    data
	                );
            		if(!_.contains(['SPT', 'STR'], key)){
            			form.addField(
    	            		key+"_state",
    	                    'annexaMultipleSelectRow',
    	                    'col-sm-12',
    	                    new AnnexaFormlyFieldSelectTemplateOptions(
    	                    	confValue[key].name+'_state',
    	                        'id',
    	                        'nameLiteral',
    	                        documentStatussesValid,
    	                        true, 
    	                        undefined, 
    	                        undefined, 
    	                        undefined, 
    	                        undefined, 
    	                        true
    	                    ),
    	                    data
    	                );
            		}
            	});
            }
            form.model = modelToModal;

            configurationModal.annexaFormly = form;

            var saveConfiguration = function() { 
            	var self = this;
            	var modelModal = this.annexaFormly.model;
            	var keysModal = Object.keys(modelModal);
            	var sendValue = angular.copy(confValue);
            	_.forEach(keysModal, function(key){
            		if(!key.endsWith('_state')){
	            		var docType = sendValue[key];
	            		docType.id = [];
	            		docType.valid_states = [];
	            		_.forEach(modelModal[key], function(id){
	            			docType.id.push(id+"");
	            		});
	            		if(!_.contains(['SPT', 'STR'], key)){
	            			docType.valid_states = modelModal[key+'_state'];
	            		}
	            		
            		}
            	});
            	sendValue = JSON.stringify(sendValue)
            	AdminFactory.updateConfiguration('sec_document_types', sendValue, undefined)
                .then(function(data) {
                    self.close();
                    var indexConfig = $linq(AdminFactory.configurations).indexOf("x => x.key === 'sec_document_types'");
                    if(indexConfig != -1) {
                    	GlobalDataFactory.configuration = [];
                    	if(CacheFactory.get('globalDataCache') && CacheFactory.get('globalDataCache').get('configuration')){
                        	CacheFactory.get('globalDataCache').remove('configuration')
                        }
                        GlobalDataFactory.loadGlobalData();
                    }

                }).catch(function (error) {
                    self.alerts.push({ msg: 'global.errors.errorUpdateConfigValue' });
            	});
			}
	
	        AnnexaFormlyFactory.showModal('configurationModal', configurationModal, saveConfiguration, false);
    	}
		$scope.sec_organ_member_rols =  function(){
			var title = $linq($scope.form.fields).singleOrDefault({ templateOptions: { label: '' } }, "x => x.key == 'sec_organ_member_rols'");
            var conf = $linq(AdminFactory.configurations).singleOrDefault(undefined, "x => x.key === 'sec_organ_member_rols'");
            var confValue = JSON.parse(conf.value.replace(/\r?\n|\r/g, " "));
            var organMembers = angular.copy(GlobalDataFactory.organMemberRols);
            var configurationModal = {
                title: title.templateOptions.label,
                icon: 'fa fa-gear',
                annexaFormly: {},
                alerts: [],
                submitModal: function () {}
            };

            var form = new AnnexaFormly();
            form.model = {};
            var keys = Object.keys(confValue);
            if(confValue && keys.length > 0){
            	_.forEach(keys, function(key){
	            	form.addField(
	            		key,
	                    'annexaSelectRow',
	                    '',
	                    new AnnexaFormlyFieldSelectTemplateOptions(
	                    	'global.sec.literals.'+key,
	                        'id',
	                        Language.getActiveColumn(),
	                        organMembers,
	                        true
	                    ),
	                    data
	                );
            	});
            }
            form.model = angular.copy(confValue);

            configurationModal.annexaFormly = form;

            var saveConfiguration = function() { 
            	var self = this;
            	var modelModal = this.annexaFormly.model;
            	var keysModal = Object.keys(modelModal);
            	var sendValue = angular.copy(modelModal);
            	_.forEach(keysModal, function(key){
            		sendValue[key] = modelModal[key];
            	});
            	sendValue = JSON.stringify(sendValue)
            	AdminFactory.updateConfiguration('sec_organ_member_rols', sendValue, undefined)
                .then(function(data) {
                    self.close();
                    var indexConfig = $linq(AdminFactory.configurations).indexOf("x => x.key === 'sec_organ_member_rols'");
                    if(indexConfig != -1) {
                    	GlobalDataFactory.configuration = [];
                    	if(CacheFactory.get('globalDataCache') && CacheFactory.get('globalDataCache').get('configuration')){
                        	CacheFactory.get('globalDataCache').remove('configuration')
                        }
                        GlobalDataFactory.loadGlobalData();
                    }

                }).catch(function (error) {
                    self.alerts.push({ msg: 'global.errors.errorUpdateConfigValue' });
            });
			}
	
	        AnnexaFormlyFactory.showModal('configurationModal', configurationModal, saveConfiguration, false);
    	}

		$scope.user_positions =  function(){
			RestService.findAll('Position').then(function(dataP) {
				var title = $linq($scope.form.fields).singleOrDefault({ templateOptions: { label: '' } }, "x => x.key == 'user_positions'");
	            var conf = $linq(AdminFactory.configurations).singleOrDefault(undefined, "x => x.key === 'user_positions'");
	            var confValue = JSON.parse(conf.value.replace(/\r?\n|\r/g, " "));
	            var positions = JSOG.decode(dataP.data);
	            var configurationModal = {
	                title: title.templateOptions.label,
	                icon: 'fa fa-gear',
	                annexaFormly: {},
	                alerts: [],
	                submitModal: function () {}
	            };
	
	            var form = new AnnexaFormly();
	            form.model = {};
	            var keys = Object.keys(confValue);
	            if(confValue && keys.length > 0){
	            	_.forEach(keys, function(key){
		            	form.addField(
		            		key,
		                    'annexaSelectRow',
		                    '',
		                    new AnnexaFormlyFieldSelectTemplateOptions(
		                    	'global.sec.literals.'+key,
		                        'id',
		                        Language.getActiveColumn(),
		                        positions,
		                        true
		                    ),
		                    data
		                );
	            	});
	            }
	            form.model = angular.copy(confValue);
	
	            configurationModal.annexaFormly = form;
	
	            var saveConfiguration = function() { 
	            	var self = this;
	            	var modelModal = this.annexaFormly.model;
	            	var keysModal = Object.keys(modelModal);
	            	var sendValue = angular.copy(modelModal);
	            	_.forEach(keysModal, function(key){
	            		sendValue[key] = modelModal[key];
	            	});
	            	sendValue = JSON.stringify(sendValue)
	            	AdminFactory.updateConfiguration('user_positions', sendValue, undefined)
                    .then(function(data) {
                        self.close();
                        var indexConfig = $linq(AdminFactory.configurations).indexOf("x => x.key === 'user_positions'");
                        if(indexConfig != -1) {
                        	GlobalDataFactory.configuration = [];
                        	if(CacheFactory.get('globalDataCache') && CacheFactory.get('globalDataCache').get('configuration')){
                            	CacheFactory.get('globalDataCache').remove('configuration')
                            }
                            GlobalDataFactory.loadGlobalData();
                        }

                    }).catch(function (error) {
                        self.alerts.push({ msg: 'global.errors.errorUpdateConfigValue' });
                });
				}
		
		        AnnexaFormlyFactory.showModal('configurationModal', configurationModal, saveConfiguration, false);
			}).catch(function (error) {
            });
    	}
		
		$scope.ava_finish_convene =  function(){
			var title = $linq($scope.form.fields).singleOrDefault({ templateOptions: { label: '' } }, "x => x.key == 'ava_finish_convene'");
            var conf = $linq(AdminFactory.configurations).singleOrDefault(undefined, "x => x.key === 'ava_finish_convene'");
            var confValue = JSON.parse(conf.value.replace(/\r?\n|\r/g, " "));
            var configurationModal = {
                title: title.templateOptions.label,
                icon: 'fa fa-gear',
                annexaFormly: {},
                alerts: [],
                submitModal: function () {}
            };
           
            var form = new AnnexaFormly();
            form.model = {};
            form.model = angular.copy(confValue);
            var keys = Object.keys(confValue);
            if(confValue && keys.length > 0){
            	_.forEach(keys, function(key){
            		if(key == "enabled"){
	        			form.addField(
	    					key,
	                        'annexaRadioCheckboxRow',
	                        'col-sm-12',
	                        new AnnexaFormlyFieldSelectTemplateOptions(
	                            'global.configuration.ava_finish_convene.'+key,
	                            'value',
	                            'label',
	                            [{ value: true, label: $filter('translate')('global.literals.yes') }, { value: false, label: $filter('translate')('global.literals.no') }],
	                            false,
	                            'radio'
	                        ),
	                        data
	                   );
            		} else {
            			form.addField(
                            key,
                            'annexaInputRow',
                            'col-sm-12',
                            new AnnexaFormlyFieldTemplateOptions(
                            	'text',
                            	'global.configuration.ava_finish_convene.'+key,
                                false
                            ),
                            data
                        );
            		}
            	});
            }
            
            configurationModal.annexaFormly = form;

            var saveConfiguration = function() { 
            	var self = this;
            	var modelModal = this.annexaFormly.model;
            	var keysModal = Object.keys(modelModal);
            	var sendValue = angular.copy(modelModal);
            	_.forEach(keysModal, function(key){
        			sendValue[key] = modelModal[key];
            	});
            	sendValue = JSON.stringify(sendValue)
            	AdminFactory.updateConfiguration('ava_finish_convene', sendValue, undefined)
	                .then(function(data) {
	                    self.close();
	                    var indexConfig = $linq(AdminFactory.configurations).indexOf("x => x.key === 'ava_finish_convene'");
	                    if(indexConfig != -1) {
	                    	GlobalDataFactory.configuration = [];
	                    	if(CacheFactory.get('globalDataCache') && CacheFactory.get('globalDataCache').get('configuration')){
	                        	CacheFactory.get('globalDataCache').remove('configuration')
	                        }
	                        GlobalDataFactory.loadGlobalData();
	                    }
	
	                }).catch(function (error) {
	                    self.alerts.push({ msg: 'global.errors.errorUpdateConfigValue' });
	            });
			}
            AnnexaFormlyFactory.showModal('configurationModal', configurationModal, saveConfiguration, false);
    	};
    	
    	$scope.sec_convene_button_literal =  function(){
			var title = $linq($scope.form.fields).singleOrDefault({ templateOptions: { label: '' } }, "x => x.key == 'sec_convene_button_literal'");
            var conf = $linq(AdminFactory.configurations).singleOrDefault(undefined, "x => x.key === 'sec_convene_button_literal'");
            var confValue = JSON.parse(conf.value.replace(/\r?\n|\r/g, " "));
            var configurationModal = {
                title: title.templateOptions.label,
                icon: 'fa fa-gear',
                annexaFormly: {},
                alerts: [],
                submitModal: function () {}
            };
            var typesAssignOptions = GlobalDataFactory.documentTypes;
            var form = new AnnexaFormly();
            form.model = {};
            form.model = angular.copy(confValue);
            var keys = Object.keys(confValue);
            if(confValue && keys.length > 0){
            	_.forEach(keys, function(key){
            		form.addField(
	                    key,
	                    'annexaInputRow',
	                    'col-sm-12',
	                    new AnnexaFormlyFieldTemplateOptions(
	                    	'text',
	                    	'global.configuration.sec_convene_button_literal.'+key,
	                        false
	                    ),
	                    data
	                );
            	});
            }
            
            configurationModal.annexaFormly = form;

            var saveConfiguration = function() { 
            	var self = this;
            	var modelModal = this.annexaFormly.model;
            	var keysModal = Object.keys(modelModal);
            	var sendValue = angular.copy(modelModal);
            	_.forEach(keysModal, function(key){
        			sendValue[key] = modelModal[key];
            	});
            	sendValue = JSON.stringify(sendValue)
            	AdminFactory.updateConfiguration('sec_convene_button_literal', sendValue, undefined)
	                .then(function(data) {
	                    self.close();
	                    var indexConfig = $linq(AdminFactory.configurations).indexOf("x => x.key === 'sec_convene_button_literal'");
	                    if(indexConfig != -1) {
	                    	GlobalDataFactory.configuration = [];
	                    	if(CacheFactory.get('globalDataCache') && CacheFactory.get('globalDataCache').get('configuration')){
	                        	CacheFactory.get('globalDataCache').remove('configuration')
	                        }
	                        GlobalDataFactory.loadGlobalData();
	                    }
	
	                }).catch(function (error) {
	                    self.alerts.push({ msg: 'global.errors.errorUpdateConfigValue' });
	            });
			}
            AnnexaFormlyFactory.showModal('configurationModal', configurationModal, saveConfiguration, false);
    	};

		//endregion
		
		//region Signatura
		
		$scope.signConflictsConfiguration = function (){
			
			var conf = $linq(AdminFactory.configurations).singleOrDefault(undefined, "x => x.key === 'signConflictsConfiguration'");
            var confValue = angular.copy(conf.value);
            confValue = JSON.parse(conf.value);
			if(confValue.position){
				confValue.position = confValue.position+"";
			}
            var modal = CommonAdminModals.signDefaultConfigUser;
			modal.confValue = confValue;
            modal.tenant = $scope.LoggedUser.tenant;
            modal.submitLabel = 'global.literals.save';
            modal.submitFunction = function() { 
            	var self = this;
                self.alerts.length = 0;
				var confSave = angular.copy(self.confValue);
				if(confSave && confSave.position){
					confSave.position = parseInt(confSave.position);	
				}
            	var position = JSON.stringify(confSave);
            	AdminFactory.updateConfiguration('signConflictsConfiguration', position, undefined)
	                .then(function(data) {
	                    self.close();
	                    var indexConfig = $linq(AdminFactory.configurations).indexOf("x => x.key === 'signConflictsConfiguration'");
	                    if(indexConfig != -1) {
                        	GlobalDataFactory.configuration = [];
                        	if(CacheFactory.get('globalDataCache') && CacheFactory.get('globalDataCache').get('configuration')){
                            	CacheFactory.get('globalDataCache').remove('configuration')
                            }
                            GlobalDataFactory.loadGlobalData();
	                    }
	
	                }).catch(function (error) {
	                    self.alerts.push({ msg: 'global.errors.errorUpdateConfigValue' });
	                });
			}
            AnnexaModalFactory.showModal('modaSignDefaultConfigUser', modal);
        };
        
        $scope.operations_invoices_conf =  function(){
			var title = $linq($scope.form.fields).singleOrDefault({ templateOptions: { label: '' } }, "x => x.key == 'operations_invoices_conf'");
            var conf = $linq(AdminFactory.configurations).singleOrDefault(undefined, "x => x.key === 'operations_invoices_conf'");
            var confValue = JSON.parse(conf.value.replace(/\r?\n|\r/g, " "));
            var configurationModal = {
                title: 'global.operationsAndInvoices.title',
                icon: 'fa fa-gear',
                annexaFormly: {},
                alerts: [],
                submitModal: function () {}
            };
            var typesAssignOptions = [
            	{id:'PREVIOUS', name:$filter('translate')('global.operationsAndInvoices.previous')},
            	{id:'DEFINITIVE', name:$filter('translate')('global.operationsAndInvoices.definitive')}
            ];

            var form = new AnnexaFormly();
            form.model = {};
            var keys = Object.keys(confValue);
            if(confValue && keys.length > 0){
            	_.forEach(keys, function(key){
            		if(key == "active" || key == "modifyPreviousDefinitive"){
            			form.addField(
        					key,
                            'annexaRadioCheckboxRow',
                            'col-sm-12',
                            new AnnexaFormlyFieldSelectTemplateOptions(
                                'global.configuration.operationsInvoices.'+key,
                                'value',
                                'label',
                                [{ value: true, label: $filter('translate')('global.literals.yes') }, { value: false, label: $filter('translate')('global.literals.no') }],
                                false,
                                'radio'
                            ),
                            data
            			);
            		} else if(key == "perviousDefinitiveDefault") {
            			form.addField(
		            		key,
		                    'annexaSelectRow',
		                    'col-sm-12',
		                    new AnnexaFormlyFieldSelectTemplateOptions(
	                    		'global.configuration.operationsInvoices.'+key,
		                        'id',
		                        'name',
		                        typesAssignOptions,
		                        true
		                    ),
		                    data
		                );
            		}
            	});
            }
            
            form.model = angular.copy(confValue);

            configurationModal.annexaFormly = form;

            var saveConfiguration = function() { 
            	var self = this;
            	var modelModal = this.annexaFormly.model;
            	var keysModal = Object.keys(modelModal);
            	var sendValue = angular.copy(modelModal);
            	_.forEach(keysModal, function(key){
           			sendValue[key] = modelModal[key];
            	});
            	sendValue = JSON.stringify(sendValue)
            	AdminFactory.updateConfiguration('operations_invoices_conf', sendValue, undefined)
	                .then(function(data) {
	                    self.close();
	                    var indexConfig = $linq(AdminFactory.configurations).indexOf("x => x.key === 'operations_invoices_conf'");
	                    if(indexConfig != -1) {
	                    	GlobalDataFactory.configuration = [];
	                    	if(CacheFactory.get('globalDataCache') && CacheFactory.get('globalDataCache').get('configuration')){
	                        	CacheFactory.get('globalDataCache').remove('configuration')
	                        }
	                        GlobalDataFactory.loadGlobalData();
	                    }
	
	                }).catch(function (error) {
	                    self.alerts.push({ msg: 'global.errors.errorUpdateConfigValue' });
	            });
			}
            AnnexaFormlyFactory.showModal('configurationModal', configurationModal, saveConfiguration, false);
    	};
    	
    	$scope.autofirma_properties =  function(){
			var title = $linq($scope.form.fields).singleOrDefault({ templateOptions: { label: '' } }, "x => x.key == 'autofirma_properties'");
            var conf = $linq(AdminFactory.configurations).singleOrDefault(undefined, "x => x.key === 'autofirma_properties'");
            var confValue = JSON.parse(conf.value.replace(/\r?\n|\r/g, " "));
            var configurationModal = {
                title: title.templateOptions.label,
                icon: 'fa fa-gear',
                annexaFormly: {},
                alerts: [],
                submitModal: function () {}
            };

            var form = new AnnexaFormly();
            form.model = {};
            var keys = Object.keys(confValue);
            if(confValue && keys.length > 0){
            	_.forEach(keys, function(key){
            		if(key == "algorithm" || key == "format" || key == "paeUrl" || key == "policy"){
            			form.addField(
                            key,
                            'annexaInputRow',
                            'col-sm-12',
                            new AnnexaFormlyFieldTemplateOptions(
                            	'text',
                            	'global.configuration.autofirmaProperties.'+key,
                                false
                            ),
                            data
                       );
            		}
            	});
            }
            
            form.model = angular.copy(confValue);

            configurationModal.annexaFormly = form;

            var saveConfiguration = function() { 
            	var self = this;
            	var modelModal = this.annexaFormly.model;
            	var keysModal = Object.keys(modelModal);
            	var sendValue = angular.copy(modelModal);
            	_.forEach(keysModal, function(key){
           			sendValue[key] = modelModal[key];
            	});
            	sendValue = JSON.stringify(sendValue)
            	AdminFactory.updateConfiguration('autofirma_properties', sendValue, undefined)
	                .then(function(data) {
	                    self.close();
	                    var indexConfig = $linq(AdminFactory.configurations).indexOf("x => x.key === 'autofirma_properties'");
	                    if(indexConfig != -1) {
	                    	GlobalDataFactory.configuration = [];
	                    	if(CacheFactory.get('globalDataCache') && CacheFactory.get('globalDataCache').get('configuration')){
	                        	CacheFactory.get('globalDataCache').remove('configuration')
	                        }
	                        GlobalDataFactory.loadGlobalData();
	                    }
	
	                }).catch(function (error) {
	                    self.alerts.push({ msg: 'global.errors.errorUpdateConfigValue' });
	            });
			}
            AnnexaFormlyFactory.showModal('configurationModal', configurationModal, saveConfiguration, false);
    	};
    	
    	$scope.bioSignConfiguration =  function(){
			var title = $linq($scope.form.fields).singleOrDefault({ templateOptions: { label: '' } }, "x => x.key == 'bioSignConfiguration'");
            var conf = $linq(AdminFactory.configurations).singleOrDefault(undefined, "x => x.key === 'bioSignConfiguration'");
            var confValue = JSON.parse(conf.value.replace(/\r?\n|\r/g, " "));
            var configurationModal = {
                title: title.templateOptions.label,
                icon: 'fa fa-gear',
                annexaFormly: {},
                alerts: [],
                submitModal: function () {}
            };
            var textKeys = ['baseUrl','auth','devicesUrl','documentUrl','getDocumentInfoUrl','typeOfID'];
            var numberKeys = ['minTimeOut','minUpdateAnnexa','page','posX1','posY1','sizeX1','sizeY1','posX2','posY2','sizeX2','sizeY2','posX3','posY3','sizeX3','sizeY3','posX4','posY4','sizeX4','sizeY4','posX5','posY5','sizeX5','sizeY5','posX6','posY6','sizeX6','sizeY6'];

            var form = new AnnexaFormly();
            form.model = {};
            form.model = angular.copy(confValue);
            var keys = Object.keys(confValue);
            if(confValue && keys.length > 0){
            	_.forEach(keys, function(key){
            		if(_.contains(textKeys,key)){
            			form.addField(
                            key,
                            'annexaInputRow',
                            'col-sm-12',
                            new AnnexaFormlyFieldTemplateOptions(
                            	'text',
                            	'global.configuration.bioSignConfiguration.'+key,
                                false
                            ),
                            data
                       );
            		} else if(_.contains(numberKeys,key)){
            			form.addField(
                            key,
                            'annexaInputRow',
                            'col-sm-12',
                            new AnnexaFormlyFieldTemplateOptions(
                                'number',
                                'global.configuration.bioSignConfiguration.'+key,
                                false
                            ),
                            data
                        );
            			form.model[key] = Number(form.model[key])
            		}
            	});
            }
            
            configurationModal.annexaFormly = form;

            var saveConfiguration = function() { 
            	var self = this;
            	var modelModal = this.annexaFormly.model;
            	var keysModal = Object.keys(modelModal);
            	var sendValue = angular.copy(modelModal);
            	_.forEach(keysModal, function(key){
            		if(_.contains(numberKeys,key)){
            			sendValue[key] = modelModal[key] + '';
            		} else {
            			sendValue[key] = modelModal[key];
            		}
            	});
            	sendValue = JSON.stringify(sendValue)
            	AdminFactory.updateConfiguration('bioSignConfiguration', sendValue, undefined)
	                .then(function(data) {
	                    self.close();
	                    var indexConfig = $linq(AdminFactory.configurations).indexOf("x => x.key === 'bioSignConfiguration'");
	                    if(indexConfig != -1) {
	                    	GlobalDataFactory.configuration = [];
	                    	if(CacheFactory.get('globalDataCache') && CacheFactory.get('globalDataCache').get('configuration')){
	                        	CacheFactory.get('globalDataCache').remove('configuration')
	                        }
	                        GlobalDataFactory.loadGlobalData();
	                    }
	
	                }).catch(function (error) {
	                    self.alerts.push({ msg: 'global.errors.errorUpdateConfigValue' });
	            });
			}
            AnnexaFormlyFactory.showModal('configurationModal', configurationModal, saveConfiguration, false);
    	};
    	
    	$scope.sign_signature_stamp_cloud_andorra =  function(){
			var title = $linq($scope.form.fields).singleOrDefault({ templateOptions: { label: '' } }, "x => x.key == 'sign_signature_stamp_cloud_andorra'");
            var conf = $linq(AdminFactory.configurations).singleOrDefault(undefined, "x => x.key === 'sign_signature_stamp_cloud_andorra'");
            var confValue = JSON.parse(conf.value.replace(/\r?\n|\r/g, " "));
            var configurationModal = {
                title: title.templateOptions.label,
                icon: 'fa fa-gear',
                annexaFormly: {},
                alerts: [],
                submitModal: function () {}
            };
            
            var form = new AnnexaFormly();
            form.model = {};
            var keys = Object.keys(confValue);
            if(confValue && keys.length > 0){
            	_.forEach(keys, function(key){
            		if(key == "active"){
            			form.addField(
        					key,
                            'annexaRadioCheckboxRow',
                            'col-sm-12',
                            new AnnexaFormlyFieldSelectTemplateOptions(
                                'global.configuration.sign_signature_stamp_cloud_andorra.'+key,
                                'value',
                                'label',
                                [{ value: true, label: $filter('translate')('global.literals.yes') }, { value: false, label: $filter('translate')('global.literals.no') }],
                                false,
                                'radio'
                            ),
                            data
            			);
            		} else {
            			form.addField(
                            key,
                            'annexaInputRow',
                            'col-sm-12',
                            new AnnexaFormlyFieldTemplateOptions(
                                'number',
                                'global.configuration.sign_signature_stamp_cloud_andorra.'+key,
                                false
                            ),
                            data
                        );
            		}
            	});
            }
            
            form.model = angular.copy(confValue);

            configurationModal.annexaFormly = form;

            var saveConfiguration = function() { 
            	var self = this;
            	var modelModal = this.annexaFormly.model;
            	var keysModal = Object.keys(modelModal);
            	var sendValue = angular.copy(modelModal);
            	_.forEach(keysModal, function(key){
           			sendValue[key] = modelModal[key];
            	});
            	sendValue = JSON.stringify(sendValue)
            	AdminFactory.updateConfiguration('sign_signature_stamp_cloud_andorra', sendValue, undefined)
	                .then(function(data) {
	                    self.close();
	                    var indexConfig = $linq(AdminFactory.configurations).indexOf("x => x.key === 'sign_signature_stamp_cloud_andorra'");
	                    if(indexConfig != -1) {
	                    	GlobalDataFactory.configuration = [];
	                    	if(CacheFactory.get('globalDataCache') && CacheFactory.get('globalDataCache').get('configuration')){
	                        	CacheFactory.get('globalDataCache').remove('configuration')
	                        }
	                        GlobalDataFactory.loadGlobalData();
	                    }
	
	                }).catch(function (error) {
	                    self.alerts.push({ msg: 'global.errors.errorUpdateConfigValue' });
	            });
			}
            AnnexaFormlyFactory.showModal('configurationModal', configurationModal, saveConfiguration, false);
    	};
    	
    	$scope.third_sign_configuration =  function(){
			var title = $linq($scope.form.fields).singleOrDefault({ templateOptions: { label: '' } }, "x => x.key == 'third_sign_configuration'");
            var conf = $linq(AdminFactory.configurations).singleOrDefault(undefined, "x => x.key === 'third_sign_configuration'");
            var confValue = JSON.parse(conf.value.replace(/\r?\n|\r/g, " "));
            var configurationModal = {
                title: title.templateOptions.label,
                icon: 'fa fa-gear',
                annexaFormly: {},
                alerts: [],
                submitModal: function () {}
            };
            
            var form = new AnnexaFormly();
            form.model = {};
            form.model = angular.copy(confValue);
            var keys = Object.keys(confValue);
            if(confValue && keys.length > 0){
            	_.forEach(keys, function(key){
            		if(key == "url" || key == "paramsDoc"){
            			form.addField(
                            key,
                            'annexaInputRow',
                            'col-sm-12',
                            new AnnexaFormlyFieldTemplateOptions(
                            	'text',
                            	'global.configuration.third_sign_configuration.'+key,
                                false
                            ),
                            data
                       );
            		}
            	});
            }
            
            configurationModal.annexaFormly = form;

            var saveConfiguration = function() { 
            	var self = this;
            	var modelModal = this.annexaFormly.model;
            	var keysModal = Object.keys(modelModal);
            	var sendValue = angular.copy(modelModal);
            	_.forEach(keysModal, function(key){
            		sendValue[key] = modelModal[key];
            	});
            	sendValue = JSON.stringify(sendValue)
            	AdminFactory.updateConfiguration('third_sign_configuration', sendValue, undefined)
	                .then(function(data) {
	                    self.close();
	                    var indexConfig = $linq(AdminFactory.configurations).indexOf("x => x.key === 'third_sign_configuration'");
	                    if(indexConfig != -1) {
	                    	GlobalDataFactory.configuration = [];
	                    	if(CacheFactory.get('globalDataCache') && CacheFactory.get('globalDataCache').get('configuration')){
	                        	CacheFactory.get('globalDataCache').remove('configuration')
	                        }
	                        GlobalDataFactory.loadGlobalData();
	                    }
	
	                }).catch(function (error) {
	                    self.alerts.push({ msg: 'global.errors.errorUpdateConfigValue' });
	            });
			}
            AnnexaFormlyFactory.showModal('configurationModal', configurationModal, saveConfiguration, false);
    	};
    	
    	$scope.centralizedSignConfiguration =  function(){
			var title = $linq($scope.form.fields).singleOrDefault({ templateOptions: { label: '' } }, "x => x.key == 'centralizedSignConfiguration'");
            var conf = $linq(AdminFactory.configurations).singleOrDefault(undefined, "x => x.key === 'centralizedSignConfiguration'");
            var confValue = JSON.parse(conf.value.replace(/\r?\n|\r/g, " "));
            var configurationModal = {
                title: title.templateOptions.label,
                icon: 'fa fa-gear',
                annexaFormly: {},
                alerts: [],
                submitModal: function () {}
            };
            var textKeys = ['baseUrl','auth','documentUrl','getDocumentInfoUrl','typeOfID','signatureType','notificationUrl'];
            var numberKeys = ['minTimeOut','minUpdateAnnexa','page','posX1','posY1','sizeX1','sizeY1','posX2','posY2','sizeX2','sizeY2','posX3','posY3','sizeX3','sizeY3','posX4','posY4','sizeX4','sizeY4','posX5','posY5','sizeX5','sizeY5','posX6','posY6','sizeX6','sizeY6'];

            var form = new AnnexaFormly();
            form.model = {};
            form.model = angular.copy(confValue);
            var keys = Object.keys(confValue);
            if(confValue && keys.length > 0){
            	_.forEach(keys, function(key){
            		if(_.contains(textKeys,key)){
            			form.addField(
                            key,
                            'annexaInputRow',
                            'col-sm-12',
                            new AnnexaFormlyFieldTemplateOptions(
                            	'text',
                            	'global.configuration.centralizedSignConfiguration.'+key,
                                false
                            ),
                            data
                       );
            		} else if(_.contains(numberKeys,key)){
            			form.addField(
                            key,
                            'annexaInputRow',
                            'col-sm-12',
                            new AnnexaFormlyFieldTemplateOptions(
                                'number',
                                'global.configuration.centralizedSignConfiguration.'+key,
                                false
                            ),
                            data
                        );
            			form.model[key] = Number(form.model[key])
            		}
            	});
            }
            
            configurationModal.annexaFormly = form;

            var saveConfiguration = function() { 
            	var self = this;
            	var modelModal = this.annexaFormly.model;
            	var keysModal = Object.keys(modelModal);
            	var sendValue = angular.copy(modelModal);
            	_.forEach(keysModal, function(key){
            		if(_.contains(numberKeys,key)){
            			sendValue[key] = modelModal[key] + '';
            		} else {
            			sendValue[key] = modelModal[key];
            		}
            	});
            	sendValue = JSON.stringify(sendValue)
            	AdminFactory.updateConfiguration('centralizedSignConfiguration', sendValue, undefined)
	                .then(function(data) {
	                    self.close();
	                    var indexConfig = $linq(AdminFactory.configurations).indexOf("x => x.key === 'centralizedSignConfiguration'");
	                    if(indexConfig != -1) {
	                    	GlobalDataFactory.configuration = [];
	                    	if(CacheFactory.get('globalDataCache') && CacheFactory.get('globalDataCache').get('configuration')){
	                        	CacheFactory.get('globalDataCache').remove('configuration')
	                        }
	                        GlobalDataFactory.loadGlobalData();
	                    }
	
	                }).catch(function (error) {
	                    self.alerts.push({ msg: 'global.errors.errorUpdateConfigValue' });
	            });
			}
            AnnexaFormlyFactory.showModal('configurationModal', configurationModal, saveConfiguration, false);
    	};
		
		//endregion
        
        //region register
        
        $scope.tram_classification_register_output =  function(){
			var title = $linq($scope.form.fields).singleOrDefault({ templateOptions: { label: '' } }, "x => x.key == 'tram_classification_register_output'");
            var conf = $linq(AdminFactory.configurations).singleOrDefault(undefined, "x => x.key === 'tram_classification_register_output'");
            var confValue = JSON.parse(conf.value.replace(/\r?\n|\r/g, " "));
            var configurationModal = {
                title: title.templateOptions.label,
                icon: 'fa fa-gear',
                annexaFormly: {},
                alerts: [],
                submitModal: function () {}
            };

            var form = new AnnexaFormly();
            form.model = {};
            form.addField(
                    'show',
                    'annexaRadioCheckboxRow',
                    'col-sm-12',
                    new AnnexaFormlyFieldSelectTemplateOptions(
                        'global.literals.showField',
                        'value',
                        'label',
                        [{ value: true, label: $filter('translate')('global.literals.yes') }, { value: false, label: $filter('translate')('global.literals.no') }],
                        false,
                        'radio'
                    ),
                    data
                );
            form.addField(
                    'required',
                    'annexaRadioCheckboxRow',
                    'col-sm-12',
                    new AnnexaFormlyFieldSelectTemplateOptions(
                        'global.literals.isRequiredField',
                        'value',
                        'label',
                        [{ value: true, label: $filter('translate')('global.literals.yes') }, { value: false, label: $filter('translate')('global.literals.no') }],
                        false,
                        'radio'
                    ),
                    data
                );
            form.model = angular.copy(confValue);

            configurationModal.annexaFormly = form;

            var saveConfiguration = function() { 
            	var self = this;
            	var modelModal = this.annexaFormly.model;
            	var keysModal = Object.keys(modelModal);
            	var sendValue = angular.copy(modelModal);
            	_.forEach(keysModal, function(key){
            		sendValue[key] = modelModal[key];
            	});
            	sendValue = JSON.stringify(sendValue)
            	AdminFactory.updateConfiguration('tram_classification_register_output', sendValue, undefined)
	                .then(function(data) {
	                    self.close();
	                    var indexConfig = $linq(AdminFactory.configurations).indexOf("x => x.key === 'tram_classification_register_output'");
	                    if(indexConfig != -1) {
	                    	GlobalDataFactory.configuration = [];
	                    	if(CacheFactory.get('globalDataCache') && CacheFactory.get('globalDataCache').get('configuration')){
	                        	CacheFactory.get('globalDataCache').remove('configuration')
	                        }
	                        GlobalDataFactory.loadGlobalData();
	                    }
	
	                }).catch(function (error) {
	                    self.alerts.push({ msg: 'global.errors.errorUpdateConfigValue' });
	            });
			}
	
	        AnnexaFormlyFactory.showModal('configurationModal', configurationModal, saveConfiguration, false);
    	}
        
        $scope.tram_classification_register_entry =  function(){
			var title = $linq($scope.form.fields).singleOrDefault({ templateOptions: { label: '' } }, "x => x.key == 'tram_classification_register_entry'");
            var conf = $linq(AdminFactory.configurations).singleOrDefault(undefined, "x => x.key === 'tram_classification_register_entry'");
            var confValue = JSON.parse(conf.value.replace(/\r?\n|\r/g, " "));
            var configurationModal = {
                title: title.templateOptions.label,
                icon: 'fa fa-gear',
                annexaFormly: {},
                alerts: [],
                submitModal: function () {}
            };

            var form = new AnnexaFormly();
            form.model = {};
            form.addField(
                    'show',
                    'annexaRadioCheckboxRow',
                    'col-sm-12',
                    new AnnexaFormlyFieldSelectTemplateOptions(
                        'global.literals.showField',
                        'value',
                        'label',
                        [{ value: true, label: $filter('translate')('global.literals.yes') }, { value: false, label: $filter('translate')('global.literals.no') }],
                        false,
                        'radio'
                    ),
                    data
                );
            form.addField(
                    'required',
                    'annexaRadioCheckboxRow',
                    'col-sm-12',
                    new AnnexaFormlyFieldSelectTemplateOptions(
                        'global.literals.isRequiredField',
                        'value',
                        'label',
                        [{ value: true, label: $filter('translate')('global.literals.yes') }, { value: false, label: $filter('translate')('global.literals.no') }],
                        false,
                        'radio'
                    ),
                    data
                );
            form.model = angular.copy(confValue);

            configurationModal.annexaFormly = form;

            var saveConfiguration = function() { 
            	var self = this;
            	var modelModal = this.annexaFormly.model;
            	var keysModal = Object.keys(modelModal);
            	var sendValue = angular.copy(modelModal);
            	_.forEach(keysModal, function(key){
            		sendValue[key] = modelModal[key];
            	});
            	sendValue = JSON.stringify(sendValue)
            	AdminFactory.updateConfiguration('tram_classification_register_entry', sendValue, undefined)
	                .then(function(data) {
	                    self.close();
	                    var indexConfig = $linq(AdminFactory.configurations).indexOf("x => x.key === 'tram_classification_register_entry'");
	                    if(indexConfig != -1) {
	                    	GlobalDataFactory.configuration = [];
	                    	if(CacheFactory.get('globalDataCache') && CacheFactory.get('globalDataCache').get('configuration')){
	                        	CacheFactory.get('globalDataCache').remove('configuration')
	                        }
	                        GlobalDataFactory.loadGlobalData();
	                    }
	
	                }).catch(function (error) {
	                    self.alerts.push({ msg: 'global.errors.errorUpdateConfigValue' });
	            });
			}
	
	        AnnexaFormlyFactory.showModal('configurationModal', configurationModal, saveConfiguration, false);
    	};
    	
    	$scope.multiple_register_office =  function(){
			var title = $linq($scope.form.fields).singleOrDefault({ templateOptions: { label: '' } }, "x => x.key == 'multiple_register_office'");
            var conf = $linq(AdminFactory.configurations).singleOrDefault(undefined, "x => x.key === 'multiple_register_office'");
            var confValue = JSON.parse(conf.value.replace(/\r?\n|\r/g, " "));
            var configurationModal = {
                title: title.templateOptions.label,
                icon: 'fa fa-gear',
                annexaFormly: {},
                alerts: [],
                submitModal: function () {}
            };
            
            var form = new AnnexaFormly();
            form.model = {};
            var keys = Object.keys(confValue);
            if(confValue && keys.length > 0){
            	_.forEach(keys, function(key){
            		if(key == "active"){
            			form.addField(
        					key,
                            'annexaRadioCheckboxRow',
                            'col-sm-12',
                            new AnnexaFormlyFieldSelectTemplateOptions(
                                'global.configuration.multiple_register_office.'+key,
                                'value',
                                'label',
                                [{ value: true, label: $filter('translate')('global.literals.yes') }, { value: false, label: $filter('translate')('global.literals.no') }],
                                false,
                                'radio'
                            ),
                            data
            			);
            		} else {
            			form.addField(
                            key,
                            'annexaInputRow',
                            'col-sm-12',
                            new AnnexaFormlyFieldTemplateOptions(
                                'number',
                                'global.configuration.multiple_register_office.'+key,
                                false
                            ),
                            data
                        );
            		}
            	});
            }
            
            form.model = angular.copy(confValue);

            configurationModal.annexaFormly = form;

            var saveConfiguration = function() { 
            	var self = this;
            	var modelModal = this.annexaFormly.model;
            	var keysModal = Object.keys(modelModal);
            	var sendValue = angular.copy(modelModal);
            	_.forEach(keysModal, function(key){
           			sendValue[key] = modelModal[key];
            	});
            	sendValue = JSON.stringify(sendValue)
            	AdminFactory.updateConfiguration('multiple_register_office', sendValue, undefined)
	                .then(function(data) {
	                    self.close();
	                    var indexConfig = $linq(AdminFactory.configurations).indexOf("x => x.key === 'multiple_register_office'");
	                    if(indexConfig != -1) {
	                    	GlobalDataFactory.configuration = [];
	                    	if(CacheFactory.get('globalDataCache') && CacheFactory.get('globalDataCache').get('configuration')){
	                        	CacheFactory.get('globalDataCache').remove('configuration')
	                        }
	                        GlobalDataFactory.loadGlobalData();
	                    }
	
	                }).catch(function (error) {
	                    self.alerts.push({ msg: 'global.errors.errorUpdateConfigValue' });
	            });
			}
            AnnexaFormlyFactory.showModal('configurationModal', configurationModal, saveConfiguration, false);
    	};
    	
    	$scope.create_notification_states =  function(){
			var title = $linq($scope.form.fields).singleOrDefault({ templateOptions: { label: '' } }, "x => x.key == 'create_notification_states'");
            var conf = $linq(AdminFactory.configurations).singleOrDefault(undefined, "x => x.key === 'create_notification_states'");
            var confValue = ((conf && conf.value)?JSON.parse(conf.value.replace(/\r?\n|\r/g, " ")):{});
			
            var configurationModal = {
                title: title.templateOptions.label,
                icon: 'fa fa-gear',
                annexaFormly: {},
                alerts: [],
                submitModal: function () {}
            };

            var form = new AnnexaFormly();
            form.model = {};
			form.fields.push({
                key: conf.key,
                type: 'annexaComponent',
                templateOptions: {
                    type: 'annexa-create-notification-states'
                },
				data: {
					model: angular.copy(confValue),
				}
        	});
            form.model = angular.copy(confValue);

            configurationModal.annexaFormly = form; 

            var saveConfiguration = function() { 
            	var self = this;
				if(this.annexaFormly && this.annexaFormly.fields){
					var field = $linq(this.annexaFormly.fields).firstOrDefault(undefined, "x => x.key == '"+conf.key+"'");
					if(field && field.data && field.data.model){
						var sendValue = angular.toJson(field.data.model);
		            	AdminFactory.updateConfiguration('create_notification_states', sendValue, undefined)
			                .then(function(data) {
			                    self.close();
			                    var indexConfig = $linq(AdminFactory.configurations).indexOf("x => x.key === 'create_notification_states'");
			                    if(indexConfig != -1) {
			                    	GlobalDataFactory.configuration = [];
			                    	if(CacheFactory.get('globalDataCache') && CacheFactory.get('globalDataCache').get('configuration')){
			                        	CacheFactory.get('globalDataCache').remove('configuration')
			                        }
			                        GlobalDataFactory.loadGlobalData();
			                    }

			                }).catch(function (error) {
			                    self.alerts.push({ msg: 'global.errors.errorUpdateConfigValue' });
			            });
					} 
				}
			}

	        AnnexaFormlyFactory.showModal('configurationModal', configurationModal, saveConfiguration, false);
    	};
    	$scope.sicresConfiguration =  function(){
			var title = $linq($scope.form.fields).singleOrDefault({ templateOptions: { label: '' } }, "x => x.key == 'sicresConfiguration'");
            var conf = $linq(AdminFactory.configurations).singleOrDefault(undefined, "x => x.key === 'sicresConfiguration'");
            var confValue = JSON.parse(conf.value.replace(/\r?\n|\r/g, " "));
            var configurationModal = {
                title: title.templateOptions.label,
                icon: 'fa fa-gear',
                annexaFormly: {},
                alerts: [],
                submitModal: function () {}
            };

            var form = new AnnexaFormly();
            form.model = {};
            var keys = Object.keys(confValue);
            if(confValue && keys.length > 0){
            	_.forEach(keys, function(key){
            		if(key == "year" || key == "seq"){
            			form.addField(
                                key,
                                'annexaInputRow',
                                'col-sm-12',
                                new AnnexaFormlyFieldTemplateOptions(
                                	'text',
                                	'global.configuration.sicresConfiguration.'+key,
                                    true
                                ),
                                data
                           );
            		}
            		
            	});
            }
            
            form.model = angular.copy(confValue);

            configurationModal.annexaFormly = form;

            var saveConfiguration = function() { 
            	var self = this;
            	var modelModal = this.annexaFormly.model;
            	var keysModal = Object.keys(modelModal);
            	var sendValue = angular.copy(modelModal);
            	_.forEach(keysModal, function(key){
           			sendValue[key] = modelModal[key];
            	});
            	sendValue = JSON.stringify(sendValue)
            	AdminFactory.updateConfiguration('sicresConfiguration', sendValue, undefined)
	                .then(function(data) {
	                    self.close();
	                    var indexConfig = $linq(AdminFactory.configurations).indexOf("x => x.key === 'sicresConfiguration'");
	                    if(indexConfig != -1) {
	                    	GlobalDataFactory.configuration = [];
	                    	if(CacheFactory.get('globalDataCache') && CacheFactory.get('globalDataCache').get('configuration')){
	                        	CacheFactory.get('globalDataCache').remove('configuration')
	                        }
	                        GlobalDataFactory.loadGlobalData();
	                    }
	
	                }).catch(function (error) {
	                    self.alerts.push({ msg: 'global.errors.errorUpdateConfigValue' });
	            });
			}
	
	        AnnexaFormlyFactory.showModal('configurationModal', configurationModal, saveConfiguration, false);
    	};
    	
    	$scope.reg_thirds_permited_identification_document_types =  function(){
			var title = $linq($scope.form.fields).singleOrDefault({ templateOptions: { label: '' } }, "x => x.key == 'reg_thirds_permited_identification_document_types'");
            var conf = $linq(AdminFactory.configurations).singleOrDefault(undefined, "x => x.key === 'reg_thirds_permited_identification_document_types'");
            var confValue = JSON.parse(conf.value.replace(/\r?\n|\r/g, " "));
            var configurationModal = {
                title: title.templateOptions.label,
                icon: 'fa fa-gear',
                annexaFormly: {},
                alerts: [],
                submitModal: function () {}
            };
            var typesAssignOptions = [
            	{id:'DNI', name:$filter('translate')('global.configuration.reg_thirds_permited_identification_document_types.DNI')},
            	{id:'NIE', name:$filter('translate')('global.configuration.reg_thirds_permited_identification_document_types.NIE')},
            	{id:'NIF', name:$filter('translate')('global.configuration.reg_thirds_permited_identification_document_types.NIF')},
            	{id:'OTHER', name:$filter('translate')('global.configuration.reg_thirds_permited_identification_document_types.OTHER')}
            ];

            var form = new AnnexaFormly();
            form.model = {};
            var keys = Object.keys(confValue);
            if(confValue && keys.length > 0){
            	_.forEach(keys, function(key){
	        		form.addField(
        				key,
	                    'annexaMultipleSelectRow',
	                    'col-sm-12',
	                    new AnnexaFormlyFieldSelectTemplateOptions(
                    		'global.configuration.reg_thirds_permited_identification_document_types.title',
	                        'id',
	                        'name',
	                        typesAssignOptions,
	                        false, 
	                        undefined, 
	                        undefined, 
	                        undefined, 
	                        undefined, 
	                        true
	                    ),
	                    data
	                );
            	});
            }
            
            form.model = angular.copy(confValue);

            configurationModal.annexaFormly = form;

            var saveConfiguration = function() { 
            	var self = this;
            	var modelModal = this.annexaFormly.model;
            	var keysModal = Object.keys(modelModal);
            	var sendValue = angular.copy(modelModal);
            	_.forEach(keysModal, function(key){
           			sendValue[key] = modelModal[key];
            	});
            	sendValue = JSON.stringify(sendValue)
            	AdminFactory.updateConfiguration('reg_thirds_permited_identification_document_types', sendValue, undefined)
	                .then(function(data) {
	                    self.close();
	                    var indexConfig = $linq(AdminFactory.configurations).indexOf("x => x.key === 'reg_thirds_permited_identification_document_types'");
	                    if(indexConfig != -1) {
	                    	GlobalDataFactory.configuration = [];
	                    	if(CacheFactory.get('globalDataCache') && CacheFactory.get('globalDataCache').get('configuration')){
	                        	CacheFactory.get('globalDataCache').remove('configuration')
	                        }
	                        GlobalDataFactory.loadGlobalData();
	                    }
	
	                }).catch(function (error) {
	                    self.alerts.push({ msg: 'global.errors.errorUpdateConfigValue' });
	            });
			}
            AnnexaFormlyFactory.showModal('configurationModal', configurationModal, saveConfiguration, false);
    	};
    	
    	$scope.notification_valid_document_status =  function(){
			var title = $linq($scope.form.fields).singleOrDefault({ templateOptions: { label: '' } }, "x => x.key == 'notification_valid_document_status'");
            var conf = $linq(AdminFactory.configurations).singleOrDefault(undefined, "x => x.key === 'notification_valid_document_status'");
            var confValue = JSON.parse(conf.value.replace(/\r?\n|\r/g, " "));
            var configurationModal = {
                title: title.templateOptions.label,
                icon: 'fa fa-gear',
                annexaFormly: {},
                alerts: [],
                submitModal: function () {}
            };
            
            var statusAssignOptions = [
            	{id:'NEW', name:$filter('translate')('global.configuration.notification_valid_document_status.status.NEW')},
            	{id:'NEW_AND_ATTACHMENT', name:$filter('translate')('global.configuration.notification_valid_document_status.status.NEW_AND_ATTACHMENT')},
            	{id:'PENDING_SIGN', name:$filter('translate')('global.configuration.notification_valid_document_status.status.PENDING_SIGN')},
            	{id:'SIGNED', name:$filter('translate')('global.configuration.notification_valid_document_status.status.SIGNED')},
            	{id:'COMPLETE', name:$filter('translate')('global.configuration.notification_valid_document_status.status.COMPLETE')},
            	{id:'REJECTED', name:$filter('translate')('global.configuration.notification_valid_document_status.status.REJECTED')},
            	{id:'ARCHIVED', name:$filter('translate')('global.configuration.notification_valid_document_status.status.ARCHIVED')}
            ];
            var assignOptionsRelationType = [
            	{id:'DOCUMENT', name:$filter('translate')('global.configuration.notification_valid_document_status.relationType.DOCUMENT')},
            	{id:'INTERNAL_DOCUMENT', name:$filter('translate')('global.configuration.notification_valid_document_status.relationType.INTERNAL_DOCUMENT')},
            	{id:'WORK_DOCUMENT', name:$filter('translate')('global.configuration.notification_valid_document_status.relationType.WORK_DOCUMENT')},
            ];

            var form = new AnnexaFormly();
            form.model = {};
            var keys = Object.keys(confValue);
            if(confValue && keys.length > 0){
            	_.forEach(keys, function(key){
	        		form.addField(
        				key,
	                    'annexaMultipleSelectRow',
	                    'col-sm-12',
	                    new AnnexaFormlyFieldSelectTemplateOptions(
	                    	'global.configuration.notification_valid_document_status.' + (key === 'status' ? 'status' : 'relationType') + '.title',
	                        'id',
	                        'name',
	                        (key === 'status' ? statusAssignOptions : assignOptionsRelationType),
	                        true, 
	                        undefined, 
	                        undefined, 
	                        undefined, 
	                        undefined, 
	                        true
	                    ),
	                    data
	                );
            	});
            }
            
            form.model = angular.copy(confValue);

            configurationModal.annexaFormly = form;

            var saveConfiguration = function() { 
            	var self = this;
            	var modelModal = this.annexaFormly.model;
            	var keysModal = Object.keys(modelModal);
            	var sendValue = angular.copy(modelModal);
            	_.forEach(keysModal, function(key){
           			sendValue[key] = modelModal[key];
            	});
            	sendValue = JSON.stringify(sendValue)
            	AdminFactory.updateConfiguration('notification_valid_document_status', sendValue, undefined)
	                .then(function(data) {
	                    self.close();
	                    var indexConfig = $linq(AdminFactory.configurations).indexOf("x => x.key === 'notification_valid_document_status'");
	                    if(indexConfig != -1) {
	                    	GlobalDataFactory.configuration = [];
	                    	if(CacheFactory.get('globalDataCache') && CacheFactory.get('globalDataCache').get('configuration')){
	                        	CacheFactory.get('globalDataCache').remove('configuration')
	                        }
	                        GlobalDataFactory.loadGlobalData();
	                    }
	
	                }).catch(function (error) {
	                    self.alerts.push({ msg: 'global.errors.errorUpdateConfigValue' });
	            });
			}
            AnnexaFormlyFactory.showModal('configurationModal', configurationModal, saveConfiguration, false);
    	};
    	
    	$scope.excluded_profiles_see_all_registers =  function(){
			var title = $linq($scope.form.fields).singleOrDefault({ templateOptions: { label: '' } }, "x => x.key == 'excluded_profiles_see_all_registers'");
            var conf = $linq(AdminFactory.configurations).singleOrDefault(undefined, "x => x.key === 'excluded_profiles_see_all_registers'");
            var confValue = JSON.parse(conf.value.replace(/\r?\n|\r/g, " "));
            var configurationModal = {
                title: title.templateOptions.label,
                icon: 'fa fa-gear',
                annexaFormly: {},
                alerts: [],
                submitModal: function () {}
            };

            var form = new AnnexaFormly();
            form.model = {};
            var keys = Object.keys(confValue);
            
            if(confValue && keys.length > 0){
            	_.forEach(keys, function(key){
        			confValue[key] = confValue[key].toString();
        			form.addField(
	                    key,
	                    'annexaInputRow',
	                    'col-sm-12',
	                    new AnnexaFormlyFieldTemplateOptions(
	                    	'text',
	                    	'global.configuration.excluded_profiles_see_all_registers.'+key,
	                        false
	                    ),
	                    data
                   );
            	});
            }
            
            form.model = angular.copy(confValue);

            configurationModal.annexaFormly = form;

            var saveConfiguration = function() { 
            	var self = this;
            	var modelModal = this.annexaFormly.model;
            	var keysModal = Object.keys(modelModal);
            	var sendValue = angular.copy(modelModal);
            	_.forEach(keysModal, function(key){
            		var ammendKeys = [];
            		if(key == "value" && modelModal[key] != ""){
            			sendValue[key] = modelModal[key].split(",");
            			_.forEach(sendValue[key], function(keys){
            				ammendKeys.push(Number(keys.trim()));
            			});
            			sendValue[key] = ammendKeys;
            		}else if(key == "value" && modelModal[key] == ""){
            			sendValue[key] = [];
            		}else{
            			sendValue[key] = modelModal[key];
            		}
            	});
            	sendValue = JSON.stringify(sendValue)
            	AdminFactory.updateConfiguration('excluded_profiles_see_all_registers', sendValue, undefined)
	                .then(function(data) {
	                    self.close();
	                    var indexConfig = $linq(AdminFactory.configurations).indexOf("x => x.key === 'excluded_profiles_see_all_registers'");
	                    if(indexConfig != -1) {
	                    	GlobalDataFactory.configuration = [];
	                    	if(CacheFactory.get('globalDataCache') && CacheFactory.get('globalDataCache').get('configuration')){
	                        	CacheFactory.get('globalDataCache').remove('configuration')
	                        }
	                        GlobalDataFactory.loadGlobalData();
	                    }
	
	                }).catch(function (error) {
	                    self.alerts.push({ msg: 'global.errors.errorUpdateConfigValue' });
	            });
			}
            AnnexaFormlyFactory.showModal('configurationModal', configurationModal, saveConfiguration, false);
    	}
    	
    	$scope.identification_reference_types =  function(){
			var title = $linq($scope.form.fields).singleOrDefault({ templateOptions: { label: '' } }, "x => x.key == 'identification_reference_types'");
            var conf = $linq(AdminFactory.configurations).singleOrDefault(undefined, "x => x.key === 'identification_reference_types'");
            var confValue = JSON.parse(conf.value.replace(/\r?\n|\r/g, " "));
            var configurationModal = {
                title: title.templateOptions.label,
                icon: 'fa fa-gear',
                annexaFormly: {},
                alerts: [],
                submitModal: function () {}
            };

            var form = new AnnexaFormly();
            form.model = {};
            var keys = Object.keys(confValue);
            
            if(confValue && keys.length > 0){
            	_.forEach(keys, function(key){
        			form.addField(
	                    key,
	                    'annexaInputRow',
	                    'col-sm-12',
	                    new AnnexaFormlyFieldTemplateOptions(
	                    	'text',
	                    	'global.configuration.identification_reference_types.title',
	                        false
	                    ),
	                    data
                   );
            	});
            }
            
            form.model = angular.copy(confValue);

            configurationModal.annexaFormly = form;

            var saveConfiguration = function() { 
            	var self = this;
            	var modelModal = this.annexaFormly.model;
            	var keysModal = Object.keys(modelModal);
            	var sendValue = angular.copy(modelModal);
            	_.forEach(keysModal, function(key){
            		if(key == "codes" && modelModal[key] != ""){
            			sendValue[key] = modelModal[key].split(",");

            		}else if(key == "codes" && modelModal[key] == ""){
            			sendValue[key] = [];
            		}else{
            			sendValue[key] = modelModal[key];
            		}
            	});
            	sendValue = JSON.stringify(sendValue)
            	AdminFactory.updateConfiguration('identification_reference_types', sendValue, undefined)
	                .then(function(data) {
	                    self.close();
	                    var indexConfig = $linq(AdminFactory.configurations).indexOf("x => x.key === 'identification_reference_types'");
	                    if(indexConfig != -1) {
	                    	GlobalDataFactory.configuration = [];
	                    	if(CacheFactory.get('globalDataCache') && CacheFactory.get('globalDataCache').get('configuration')){
	                        	CacheFactory.get('globalDataCache').remove('configuration')
	                        }
	                        GlobalDataFactory.loadGlobalData();
	                    }
	
	                }).catch(function (error) {
	                    self.alerts.push({ msg: 'global.errors.errorUpdateConfigValue' });
	            });
			}
            AnnexaFormlyFactory.showModal('configurationModal', configurationModal, saveConfiguration, false);
    	}

        //endregion
        
        //region dossier
        
        $scope.days_before_alert =  function(){
        	var title = $linq($scope.form.fields).singleOrDefault({ templateOptions: { label: '' } }, "x => x.key == 'days_before_alert'");
            var conf = $linq(AdminFactory.configurations).singleOrDefault(undefined, "x => x.key === 'days_before_alert'");
            var confValue = JSON.parse(conf.value.replace(/\r?\n|\r/g, " "));
            var configurationModal = {
                title: title.templateOptions.label,
                icon: 'fa fa-gear',
                annexaFormly: {},
                alerts: [],
                submitModal: function () {}
            };

            var form = new AnnexaFormly();
            form.model = {};
            var keys = Object.keys(confValue);
            if(confValue && keys.length > 0){
            	_.forEach(keys, function(key){
            		
            		form.addField(
                        key,
                        'annexaInputRow',
                        '',
                        new AnnexaFormlyFieldTemplateOptions(
                            'number',
                            'global.tram.literals.'+key,
                            false
                        ),
                        data
                    );
            	});
            }
            form.model = angular.copy(confValue);

            configurationModal.annexaFormly = form;

            var saveConfiguration = function() { 
            	var self = this;
            	var modelModal = this.annexaFormly.model;
            	var keysModal = Object.keys(modelModal);
            	var sendValue = angular.copy(modelModal);
            	_.forEach(keysModal, function(key){
            		sendValue[key] = modelModal[key];
            	});
            	sendValue = JSON.stringify(sendValue)
            	AdminFactory.updateConfiguration('days_before_alert', sendValue, undefined)
	                .then(function(data) {
	                    self.close();
	                    var indexConfig = $linq(AdminFactory.configurations).indexOf("x => x.key === 'days_before_alert'");
	                    if(indexConfig != -1) {
	                    	GlobalDataFactory.configuration = [];
	                    	if(CacheFactory.get('globalDataCache') && CacheFactory.get('globalDataCache').get('configuration')){
	                        	CacheFactory.get('globalDataCache').remove('configuration')
	                        }
	                        GlobalDataFactory.loadGlobalData();
	                    }
	
	                }).catch(function (error) {
	                    self.alerts.push({ msg: 'global.errors.errorUpdateConfigValue' });
	            });
			}
	
	        AnnexaFormlyFactory.showModal('configurationModal', configurationModal, saveConfiguration, false);
    	}
        
        $scope.tram_classification_dossier =  function(){
			var title = $linq($scope.form.fields).singleOrDefault({ templateOptions: { label: '' } }, "x => x.key == 'tram_classification_dossier'");
            var conf = $linq(AdminFactory.configurations).singleOrDefault(undefined, "x => x.key === 'tram_classification_dossier'");
            var confValue = JSON.parse(conf.value.replace(/\r?\n|\r/g, " "));
            var configurationModal = {
                title: title.templateOptions.label,
                icon: 'fa fa-gear',
                annexaFormly: {},
                alerts: [],
                submitModal: function () {}
            };

            var form = new AnnexaFormly();
            form.model = {};
            form.addField(
                    'show',
                    'annexaRadioCheckboxRow',
                    'col-sm-12',
                    new AnnexaFormlyFieldSelectTemplateOptions(
                        'global.literals.showField',
                        'value',
                        'label',
                        [{ value: true, label: $filter('translate')('global.literals.yes') }, { value: false, label: $filter('translate')('global.literals.no') }],
                        false,
                        'radio'
                    ),
                    data
                );
            form.addField(
                    'required',
                    'annexaRadioCheckboxRow',
                    'col-sm-12',
                    new AnnexaFormlyFieldSelectTemplateOptions(
                        'global.literals.isRequiredField',
                        'value',
                        'label',
                        [{ value: true, label: $filter('translate')('global.literals.yes') }, { value: false, label: $filter('translate')('global.literals.no') }],
                        false,
                        'radio'
                    ),
                    data
                );
            form.model = angular.copy(confValue);

            configurationModal.annexaFormly = form;

            var saveConfiguration = function() { 
            	var self = this;
            	var modelModal = this.annexaFormly.model;
            	var keysModal = Object.keys(modelModal);
            	var sendValue = angular.copy(modelModal);
            	_.forEach(keysModal, function(key){
            		sendValue[key] = modelModal[key];
            	});
            	sendValue = JSON.stringify(sendValue)
            	AdminFactory.updateConfiguration('tram_classification_dossier', sendValue, undefined)
	                .then(function(data) {
	                    self.close();
	                    var indexConfig = $linq(AdminFactory.configurations).indexOf("x => x.key === 'tram_classification_dossier'");
	                    if(indexConfig != -1) {
	                    	GlobalDataFactory.configuration = [];
	                    	if(CacheFactory.get('globalDataCache') && CacheFactory.get('globalDataCache').get('configuration')){
	                        	CacheFactory.get('globalDataCache').remove('configuration')
	                        }
	                        GlobalDataFactory.loadGlobalData();
	                    }
	
	                }).catch(function (error) {
	                    self.alerts.push({ msg: 'global.errors.errorUpdateConfigValue' });
	            });
			}
	
	        AnnexaFormlyFactory.showModal('configurationModal', configurationModal, saveConfiguration, false);
    	}
        
        $scope.procedure_number_count_type =  function(){
			var title = $linq($scope.form.fields).singleOrDefault({ templateOptions: { label: '' } }, "x => x.key == 'procedure_number_count_type'");
            var conf = $linq(AdminFactory.configurations).singleOrDefault(undefined, "x => x.key === 'procedure_number_count_type'");
            var confValue = JSON.parse(conf.value.replace(/\r?\n|\r/g, " "));
            var configurationModal = {
                title: title.templateOptions.label,
                icon: 'fa fa-gear',
                annexaFormly: {},
                alerts: [],
                submitModal: function () {}
            };
            var typesAssignOptions = [
            	{id:'global', name:$filter('translate')('global.configuration.procedure_number_count_type.global')},
            	{id:'global_all', name:$filter('translate')('global.configuration.procedure_number_count_type.global_all')},
            	{id:'procedure', name:$filter('translate')('global.configuration.procedure_number_count_type.procedure')},
            	{id:'procedure_all', name:$filter('translate')('global.configuration.procedure_number_count_type.procedure_all')},
            	{id:'procedure_global', name:$filter('translate')('global.configuration.procedure_number_count_type.procedure_global')},
            	{id:'procedure_global_all', name:$filter('translate')('global.configuration.procedure_number_count_type.procedure_global_all')}
            ];
            
            var form = new AnnexaFormly();
            form.model = {};
            form.model = angular.copy(confValue);
            var keys = Object.keys(confValue);
            if(confValue && keys.length > 0){
            	_.forEach(keys, function(key){
            		if(key == "type"){
            			form.addField(
		            		key,
		                    'annexaSelectRow',
		                    'col-sm-12',
		                    new AnnexaFormlyFieldSelectTemplateOptions(
	                    		'global.configuration.procedure_number_count_type.'+key,
		                        'id',
		                        'name',
		                        typesAssignOptions,
		                        true
		                    ),
		                    data
		                );
            		} else if(key == "leadingZeros"){
            			form.addField(
                            key,
                            'annexaInputRow',
                            'col-sm-12',
                            new AnnexaFormlyFieldTemplateOptions(
                                'number',
                                'global.configuration.procedure_number_count_type.'+key,
                                false
                            ),
                            data
                        );
            			form.model[key] = Number(form.model[key])
            		} else if(key == "numberPattern"){
            			form.addField(
                            key,
                            'annexaInputRow',
                            'col-sm-12',
                            new AnnexaFormlyFieldTemplateOptions(
                            	'text',
                            	'global.configuration.procedure_number_count_type.'+key,
                                false
                            ),
                            data
                       );
            		} 
            	});
            }
            
            configurationModal.annexaFormly = form;

            var saveConfiguration = function() { 
            	var self = this;
            	var modelModal = this.annexaFormly.model;
            	var keysModal = Object.keys(modelModal);
            	var sendValue = angular.copy(modelModal);
            	_.forEach(keysModal, function(key){
            		if(key == "leadingZeros"){
            			sendValue[key] = modelModal[key] + '';
            		} else {
            			sendValue[key] = modelModal[key];
            		}
            	});
            	sendValue = JSON.stringify(sendValue)
            	AdminFactory.updateConfiguration('procedure_number_count_type', sendValue, undefined)
	                .then(function(data) {
	                    self.close();
	                    var indexConfig = $linq(AdminFactory.configurations).indexOf("x => x.key === 'procedure_number_count_type'");
	                    if(indexConfig != -1) {
	                    	GlobalDataFactory.configuration = [];
	                    	if(CacheFactory.get('globalDataCache') && CacheFactory.get('globalDataCache').get('configuration')){
	                        	CacheFactory.get('globalDataCache').remove('configuration')
	                        }
	                        GlobalDataFactory.loadGlobalData();
	                    }
	
	                }).catch(function (error) {
	                    self.alerts.push({ msg: 'global.errors.errorUpdateConfigValue' });
	            });
			}
            AnnexaFormlyFactory.showModal('configurationModal', configurationModal, saveConfiguration, false);
    	};
        
        //endregion

        
        //region General
        
        $scope.booksConfiguration =  function(){
			var title = $linq($scope.form.fields).singleOrDefault({ templateOptions: { label: '' } }, "x => x.key == 'booksConfiguration'");
            var conf = $linq(AdminFactory.configurations).singleOrDefault(undefined, "x => x.key === 'booksConfiguration'");
            var confValue = JSON.parse(conf.value.replace(/\r?\n|\r/g, " "));
            var configurationModal = {
                title: title.templateOptions.label,
                icon: 'fa fa-gear',
                annexaFormly: {},
                alerts: [],
                submitModal: function () {}
            };

            var form = new AnnexaFormly();
            form.model = {};
            var keys = Object.keys(confValue);
            if(confValue && keys.length > 0){
            	_.forEach(keys, function(key){
            		form.addField(
                            key,
                            'annexaRadioCheckboxRow',
                            'col-sm-12',
                            new AnnexaFormlyFieldSelectTemplateOptions(
                                'global.literals.'+key,
                                'value',
                                'label',
                                [{ value: true, label: $filter('translate')('global.literals.yes') }, { value: false, label: $filter('translate')('global.literals.no') }],
                                false,
                                'radio'
                            ),
                            data
                        );
            	});
            }
            
            form.model = angular.copy(confValue);

            configurationModal.annexaFormly = form;

            var saveConfiguration = function() { 
            	var self = this;
            	var modelModal = this.annexaFormly.model;
            	var keysModal = Object.keys(modelModal);
            	var sendValue = angular.copy(modelModal);
            	_.forEach(keysModal, function(key){
            		sendValue[key] = modelModal[key];
            	});
            	sendValue = JSON.stringify(sendValue)
            	AdminFactory.updateConfiguration('booksConfiguration', sendValue, undefined)
	                .then(function(data) {
	                    self.close();
	                    var indexConfig = $linq(AdminFactory.configurations).indexOf("x => x.key === 'booksConfiguration'");
	                    if(indexConfig != -1) {
	                    	GlobalDataFactory.configuration = [];
	                    	if(CacheFactory.get('globalDataCache') && CacheFactory.get('globalDataCache').get('configuration')){
	                        	CacheFactory.get('globalDataCache').remove('configuration')
	                        }
	                        GlobalDataFactory.loadGlobalData();
	                    }
	
	                }).catch(function (error) {
	                    self.alerts.push({ msg: 'global.errors.errorUpdateConfigValue' });
	            });
			}
	
	        AnnexaFormlyFactory.showModal('configurationModal', configurationModal, saveConfiguration, false);
    	}
        
        $scope.document_repository_alfresco_ws =  function(){
			var title = $linq($scope.form.fields).singleOrDefault({ templateOptions: { label: '' } }, "x => x.key == 'document_repository_alfresco_ws'");
            var conf = $linq(AdminFactory.configurations).singleOrDefault(undefined, "x => x.key === 'document_repository_alfresco_ws'");
            var confValue = JSON.parse(conf.value.replace(/\r?\n|\r/g, " "));
            var configurationModal = {
                title: title.templateOptions.label,
                icon: 'fa fa-gear',
                annexaFormly: {},
                alerts: [],
                submitModal: function () {}
            };

            var form = new AnnexaFormly();
            form.model = {};
            var keys = Object.keys(confValue);
            if(confValue && keys.length > 0){
            	_.forEach(keys, function(key){
            		if(key != "tenant"){
            			form.addField(
                                key,
                                'annexaInputRow',
                                'col-sm-12',
                                new AnnexaFormlyFieldTemplateOptions(
                                	'text',
                                	'global.literals.'+key,
                                    false
                                ),
                                data
                            );
            		}
            	});
            }
            
            form.model = angular.copy(confValue);

            configurationModal.annexaFormly = form;

            var saveConfiguration = function() { 
            	var self = this;
            	var modelModal = this.annexaFormly.model;
            	var keysModal = Object.keys(modelModal);
            	var sendValue = angular.copy(modelModal);
            	_.forEach(keysModal, function(key){
            		sendValue[key] = modelModal[key];
            	});
            	sendValue = JSON.stringify(sendValue)
            	AdminFactory.updateConfiguration('document_repository_alfresco_ws', sendValue, undefined)
	                .then(function(data) {
	                    self.close();
	                    var indexConfig = $linq(AdminFactory.configurations).indexOf("x => x.key === 'document_repository_alfresco_ws'");
	                    if(indexConfig != -1) {
	                    	GlobalDataFactory.configuration = [];
	                    	if(CacheFactory.get('globalDataCache') && CacheFactory.get('globalDataCache').get('configuration')){
	                        	CacheFactory.get('globalDataCache').remove('configuration')
	                        }
	                        GlobalDataFactory.loadGlobalData();
	                    }
	
	                }).catch(function (error) {
	                    self.alerts.push({ msg: 'global.errors.errorUpdateConfigValue' });
	            });
			}
	
	        AnnexaFormlyFactory.showModal('configurationModal', configurationModal, saveConfiguration, false);
    	}
        
        $scope.psis_validate_certificate =  function(){
			var title = $linq($scope.form.fields).singleOrDefault({ templateOptions: { label: '' } }, "x => x.key == 'psis_validate_certificate'");
            var conf = $linq(AdminFactory.configurations).singleOrDefault(undefined, "x => x.key === 'psis_validate_certificate'");
            var confValue = JSON.parse(conf.value.replace(/\r?\n|\r/g, " "));
            var configurationModal = {
                title: title.templateOptions.label,
                icon: 'fa fa-gear',
                annexaFormly: {},
                alerts: [],
                submitModal: function () {}
            };

            var form = new AnnexaFormly();
            form.model = {};
			form.addField(
                    'url',
                    'annexaInputRow',
                    'col-sm-12',
                    new AnnexaFormlyFieldTemplateOptions(
                    	'global.literals.URL',
                    	'text',
                        false
                    ),
                    data
               );
            form.model = angular.copy(confValue);

            configurationModal.annexaFormly = form;

            var saveConfiguration = function() { 
            	var self = this;
            	var modelModal = this.annexaFormly.model;
            	var keysModal = Object.keys(modelModal);
            	var sendValue = angular.copy(modelModal);
            	_.forEach(keysModal, function(key){
            		sendValue[key] = modelModal[key];
            	});
            	sendValue = JSON.stringify(sendValue)
            	AdminFactory.updateConfiguration('psis_validate_certificate', sendValue, undefined)
	                .then(function(data) {
	                    self.close();
	                    var indexConfig = $linq(AdminFactory.configurations).indexOf("x => x.key === 'psis_validate_certificate'");
	                    if(indexConfig != -1) {
	                    	GlobalDataFactory.configuration = [];
	                    	if(CacheFactory.get('globalDataCache') && CacheFactory.get('globalDataCache').get('configuration')){
	                        	CacheFactory.get('globalDataCache').remove('configuration')
	                        }
	                        GlobalDataFactory.loadGlobalData();
	                    }
	
	                }).catch(function (error) {
	                    self.alerts.push({ msg: 'global.errors.errorUpdateConfigValue' });
	            });
			}
	
	        AnnexaFormlyFactory.showModal('configurationModal', configurationModal, saveConfiguration, false);
    	}
        
        $scope.publish_carpeta_ciutadana =  function(){
			var title = $linq($scope.form.fields).singleOrDefault({ templateOptions: { label: '' } }, "x => x.key == 'publish_carpeta_ciutadana'");
            var conf = $linq(AdminFactory.configurations).singleOrDefault(undefined, "x => x.key === 'publish_carpeta_ciutadana'");
            var confValue = JSON.parse(conf.value.replace(/\r?\n|\r/g, " "));
            var configurationModal = {
                title: title.templateOptions.label,
                icon: 'fa fa-gear',
                annexaFormly: {},
                alerts: [],
                submitModal: function () {}
            };

            var form = new AnnexaFormly();
            form.model = {};
			form.addField(
                    'id',
                    'annexaInputRow',
                    'col-sm-12',
                    new AnnexaFormlyFieldTemplateOptions(
                    	'text',
                    	'global.literals.identification_admin_config',
                        false
                    ),
                    data
               );
            form.model = angular.copy(confValue);

            configurationModal.annexaFormly = form;

            var saveConfiguration = function() { 
            	var self = this;
            	var modelModal = this.annexaFormly.model;
            	var keysModal = Object.keys(modelModal);
            	var sendValue = angular.copy(modelModal);
            	_.forEach(keysModal, function(key){
            		sendValue[key] = modelModal[key];
            	});
            	sendValue = JSON.stringify(sendValue)
            	AdminFactory.updateConfiguration('publish_carpeta_ciutadana', sendValue, undefined)
	                .then(function(data) {
	                    self.close();
	                    var indexConfig = $linq(AdminFactory.configurations).indexOf("x => x.key === 'publish_carpeta_ciutadana'");
	                    if(indexConfig != -1) {
	                    	GlobalDataFactory.configuration = [];
	                    	if(CacheFactory.get('globalDataCache') && CacheFactory.get('globalDataCache').get('configuration')){
	                        	CacheFactory.get('globalDataCache').remove('configuration')
	                        }
	                        GlobalDataFactory.loadGlobalData();
	                    }
	
	                }).catch(function (error) {
	                    self.alerts.push({ msg: 'global.errors.errorUpdateConfigValue' });
	            });
			}
	
	        AnnexaFormlyFactory.showModal('configurationModal', configurationModal, saveConfiguration, false);
    	}
        
        $scope.publish_semic_seu_electronica =  function(){
			var title = $linq($scope.form.fields).singleOrDefault({ templateOptions: { label: '' } }, "x => x.key == 'publish_semic_seu_electronica'");
            var conf = $linq(AdminFactory.configurations).singleOrDefault(undefined, "x => x.key === 'publish_semic_seu_electronica'");
            var confValue = JSON.parse(conf.value.replace(/\r?\n|\r/g, " "));
            var configurationModal = {
                title: title.templateOptions.label,
                icon: 'fa fa-gear',
                annexaFormly: {},
                alerts: [],
                submitModal: function () {}
            };

            var form = new AnnexaFormly();
            form.model = {};
            var replaceConfValue = {};
            var keys = Object.keys(confValue);
            if(confValue && keys.length > 0){
            	_.forEach(keys, function(key){
            		var rKey = key.replace(/ws\./, "ws_");
            		replaceConfValue[rKey] = confValue[key];
            		if(rKey != "ws_tipusNormativaMillorada" && rKey != "ws_tipusTransparencia"){
	        			form.addField(
	                            rKey,
	                            'annexaInputRow',
	                            'col-sm-12',
	                            new AnnexaFormlyFieldTemplateOptions(
	                            	'text',
	                            	'global.literals.'+rKey,
	                                false
	                            ),
	                            data
	                        );
            		}
            	});
            }
            
            form.model = angular.copy(replaceConfValue);

            configurationModal.annexaFormly = form;

            var saveConfiguration = function() { 
            	var self = this;
            	var modelModal = this.annexaFormly.model;
            	var keysModal = Object.keys(modelModal);
            	var sendValue = {};
            	_.forEach(keysModal, function(key){
            		sendValue[key.replace(/ws_/, "ws.")] = modelModal[key];
            	});
            	sendValue = JSON.stringify(sendValue);
            	AdminFactory.updateConfiguration('publish_semic_seu_electronica', sendValue, undefined)
	                .then(function(data) {
	                    self.close();
	                    var indexConfig = $linq(AdminFactory.configurations).indexOf("x => x.key === 'publish_semic_seu_electronica'");
	                    if(indexConfig != -1) {
	                    	GlobalDataFactory.configuration = [];
	                    	if(CacheFactory.get('globalDataCache') && CacheFactory.get('globalDataCache').get('configuration')){
	                        	CacheFactory.get('globalDataCache').remove('configuration')
	                        }
	                        GlobalDataFactory.loadGlobalData();
	                    }
	
	                }).catch(function (error) {
	                    self.alerts.push({ msg: 'global.errors.errorUpdateConfigValue' });
	            });
			}
	
	        AnnexaFormlyFactory.showModal('configurationModal', configurationModal, saveConfiguration, false);
    	}
        
        $scope.valids_origin_register_organ =  function(){
			var title = $linq($scope.form.fields).singleOrDefault({ templateOptions: { label: '' } }, "x => x.key == 'valids_origin_register_organ'");
            var conf = $linq(AdminFactory.configurations).singleOrDefault(undefined, "x => x.key === 'valids_origin_register_organ'");
            var confValue = JSON.parse(conf.value.replace(/\r?\n|\r/g, " "));
            var configurationModal = {
                title: title.templateOptions.label,
                icon: 'fa fa-gear',
                annexaFormly: {},
                alerts: [],
                submitModal: function () {}
            };
            confValue = {organs:confValue.organs.toString()};
            var form = new AnnexaFormly();
            form.model = {};
			form.addField(
                    'organs',
                    'annexaInputRow',
                    'col-sm-12',
                    new AnnexaFormlyFieldTemplateOptions(
                    	'text',
                    	'global.literals.valids_origin_register_organ',
                        false
                    ),
                    data
               );
            form.model = angular.copy(confValue);

            configurationModal.annexaFormly = form;

            var saveConfiguration = function() { 
            	var self = this;
            	var modelModal = this.annexaFormly.model;
            	var keysModal = Object.keys(modelModal);
            	var sendValue = angular.copy(modelModal);
            	_.forEach(keysModal, function(key){
            		var ammendKeys = [];
            		if(modelModal[key] != ""){
            			sendValue[key] = modelModal[key].split(",");
            			_.forEach(sendValue[key], function(keys){
            				ammendKeys.push(keys.trim());
            			});
            			sendValue[key] = ammendKeys;
            		}else {
            			sendValue[key] = [];
            		}
            	});
            	sendValue = JSON.stringify(sendValue)
            	AdminFactory.updateConfiguration('valids_origin_register_organ', sendValue, undefined)
	                .then(function(data) {
	                    self.close();
	                    var indexConfig = $linq(AdminFactory.configurations).indexOf("x => x.key === 'valids_origin_register_organ'");
	                    if(indexConfig != -1) {
	                    	GlobalDataFactory.configuration = [];
	                    	if(CacheFactory.get('globalDataCache') && CacheFactory.get('globalDataCache').get('configuration')){
	                        	CacheFactory.get('globalDataCache').remove('configuration')
	                        }
	                        GlobalDataFactory.loadGlobalData();
	                    }
	
	                }).catch(function (error) {
	                    self.alerts.push({ msg: 'global.errors.errorUpdateConfigValue' });
	            });
			}
	
	        AnnexaFormlyFactory.showModal('configurationModal', configurationModal, saveConfiguration, false);
    	}
        
        $scope.url_editable_inputs =  function(){
			var title = $linq($scope.form.fields).singleOrDefault({ templateOptions: { label: '' } }, "x => x.key == 'url_editable_inputs'");
            var conf = $linq(AdminFactory.configurations).singleOrDefault(undefined, "x => x.key === 'url_editable_inputs'");
            var confValue = JSON.parse(conf.value.replace(/\r?\n|\r/g, " "));
            var configurationModal = {
                title: title.templateOptions.label,
                icon: 'fa fa-gear',
                annexaFormly: {},
                alerts: [],
                submitModal: function () {}
            };
            confValue = {urls:confValue.urls.toString()};
            var form = new AnnexaFormly();
            form.model = {};
			form.addField(
                    'urls',
                    'annexaInputRow',
                    'col-sm-12',
                    new AnnexaFormlyFieldTemplateOptions(
                    	'text',
                    	'global.literals.url_editable_inputs',
                        false
                    ),
                    data
               );
            form.model = angular.copy(confValue);

            configurationModal.annexaFormly = form;

            var saveConfiguration = function() { 
            	var self = this;
            	var modelModal = this.annexaFormly.model;
            	var keysModal = Object.keys(modelModal);
            	var sendValue = angular.copy(modelModal);
            	_.forEach(keysModal, function(key){
            		var ammendKeys = [];
            		if(modelModal[key] != ""){
            			sendValue[key] = modelModal[key].split(",");
            			_.forEach(sendValue[key], function(keys){
            				ammendKeys.push(keys.trim());
            			});
            			sendValue[key] = ammendKeys;
            		}else {
            			sendValue[key] = [];
            		}
            	});
            	sendValue = JSON.stringify(sendValue)
            	AdminFactory.updateConfiguration('url_editable_inputs', sendValue, undefined)
	                .then(function(data) {
	                    self.close();
	                    var indexConfig = $linq(AdminFactory.configurations).indexOf("x => x.key === 'url_editable_inputs'");
	                    if(indexConfig != -1) {
	                    	GlobalDataFactory.configuration = [];
	                    	if(CacheFactory.get('globalDataCache') && CacheFactory.get('globalDataCache').get('configuration')){
	                        	CacheFactory.get('globalDataCache').remove('configuration')
	                        }
	                        GlobalDataFactory.loadGlobalData();
	                    }
	
	                }).catch(function (error) {
	                    self.alerts.push({ msg: 'global.errors.errorUpdateConfigValue' });
	            });
			}
	
	        AnnexaFormlyFactory.showModal('configurationModal', configurationModal, saveConfiguration, false);
    	}
        
        $scope.angular_monitor_http =  function(){
			var title = $linq($scope.form.fields).singleOrDefault({ templateOptions: { label: '' } }, "x => x.key == 'angular_monitor_http'");
            var conf = $linq(AdminFactory.configurations).singleOrDefault(undefined, "x => x.key === 'angular_monitor_http'");
            var confValue = JSON.parse(conf.value.replace(/\r?\n|\r/g, " "));
            var configurationModal = {
                title: title.templateOptions.label,
                icon: 'fa fa-gear',
                annexaFormly: {},
                alerts: [],
                submitModal: function () {}
            };

            var form = new AnnexaFormly();
            form.model = {};
            var keys = Object.keys(confValue);
            if(confValue && keys.length > 0){
            	_.forEach(keys, function(key){
            		if(key == "active" || key == "monitor_counters"){
            			form.addField(
        					key,
                            'annexaRadioCheckboxRow',
                            'col-sm-12',
                            new AnnexaFormlyFieldSelectTemplateOptions(
                                'global.literals.'+key+"_angular",
                                'value',
                                'label',
                                [{ value: true, label: $filter('translate')('global.literals.yes') }, { value: false, label: $filter('translate')('global.literals.no') }],
                                false,
                                'radio'
                            ),
                            data
            			);
            		}else if(key == "group"){
            			form.addField(
                                key,
                                'annexaInputRow',
                                'col-sm-12',
                                new AnnexaFormlyFieldTemplateOptions(
                                	'text',
                                	'global.literals.'+key,
                                    false
                                ),
                                data
                           );
            		}else if(key == "allowed_states"){
            			confValue[key] = confValue[key].toString();
            			form.addField(
                                key,
                                'annexaInputRow',
                                'col-sm-12',
                                new AnnexaFormlyFieldTemplateOptions(
                                	'text',
                                	'global.literals.'+key,
                                    false
                                ),
                                data
                           );
            		}
            		
            	});
            }
            
            form.model = angular.copy(confValue);

            configurationModal.annexaFormly = form;

            var saveConfiguration = function() { 
            	var self = this;
            	var modelModal = this.annexaFormly.model;
            	var keysModal = Object.keys(modelModal);
            	var sendValue = angular.copy(modelModal);
            	_.forEach(keysModal, function(key){
            		var ammendKeys = [];
            		if(key == "allowed_states" && modelModal[key] != ""){
            			sendValue[key] = modelModal[key].split(",");
            			_.forEach(sendValue[key], function(keys){
            				ammendKeys.push(keys.trim());
            			});
            			sendValue[key] = ammendKeys;
            		}else if(key == "allowed_states" && modelModal[key] == ""){
            			sendValue[key] = [];
            		}else{
            			sendValue[key] = modelModal[key];
            		}
            	});
            	sendValue = JSON.stringify(sendValue)
            	AdminFactory.updateConfiguration('angular_monitor_http', sendValue, undefined)
	                .then(function(data) {
	                    self.close();
	                    var indexConfig = $linq(AdminFactory.configurations).indexOf("x => x.key === 'angular_monitor_http'");
	                    if(indexConfig != -1) {
	                    	GlobalDataFactory.configuration = [];
	                    	if(CacheFactory.get('globalDataCache') && CacheFactory.get('globalDataCache').get('configuration')){
	                        	CacheFactory.get('globalDataCache').remove('configuration')
	                        }
	                        GlobalDataFactory.loadGlobalData();
	                    }
	
	                }).catch(function (error) {
	                    self.alerts.push({ msg: 'global.errors.errorUpdateConfigValue' });
	            });
			}
	
	        AnnexaFormlyFactory.showModal('configurationModal', configurationModal, saveConfiguration, false);
    	}
        
        $scope.internal_control_properties_client =  function(){
			var title = $linq($scope.form.fields).singleOrDefault({ templateOptions: { label: '' } }, "x => x.key == 'internal_control_properties_client'");
            var conf = $linq(AdminFactory.configurations).singleOrDefault(undefined, "x => x.key === 'internal_control_properties_client'");
            var confValue = JSON.parse(conf.value.replace(/\r?\n|\r/g, " "));
            var configurationModal = {
                title: title.templateOptions.label,
                icon: 'fa fa-gear',
                annexaFormly: {},
                alerts: [],
                submitModal: function () {}
            };

            var form = new AnnexaFormly();
            form.model = {};
            var keys = Object.keys(confValue);
            if(confValue && keys.length > 0){
            	_.forEach(keys, function(key){
            		if(key == "credentials"){
            			var internKeys = Object.keys(confValue.credentials);
            			_.forEach(internKeys, function(internKey){
            				form.addField(
                                'credentials.'+internKey,
                                'annexaInputRow',
                                'col-sm-12',
                                new AnnexaFormlyFieldTemplateOptions(
                                	'text',
                                	'global.literals.credentials.'+internKey,
                                    false
                                ),
                                data
                            );	
            			});
            		}else{
            			form.addField(
                                key,
                                'annexaInputRow',
                                'col-sm-12',
                                new AnnexaFormlyFieldTemplateOptions(
                                	'text',
                                	'global.literals.'+key,
                                    false
                                ),
                                data
                            );
            		}
        			
            	});
            }
            
            form.model = angular.copy(confValue);

            configurationModal.annexaFormly = form;

            var saveConfiguration = function() { 
            	var self = this;
            	var modelModal = this.annexaFormly.model;
            	var keysModal = Object.keys(modelModal);
            	var sendValue = angular.copy(modelModal);
            	_.forEach(keysModal, function(key){
        			sendValue[key] = modelModal[key];
            	});
            	sendValue = JSON.stringify(sendValue);
            	AdminFactory.updateConfiguration('internal_control_properties_client', sendValue, undefined)
	                .then(function(data) {
	                    self.close();
	                    var indexConfig = $linq(AdminFactory.configurations).indexOf("x => x.key === 'internal_control_properties_client'");
	                    if(indexConfig != -1) {
	                    	GlobalDataFactory.configuration = [];
	                    	if(CacheFactory.get('globalDataCache') && CacheFactory.get('globalDataCache').get('configuration')){
	                        	CacheFactory.get('globalDataCache').remove('configuration')
	                        }
	                        GlobalDataFactory.loadGlobalData();
	                    }
	
	                }).catch(function (error) {
	                    self.alerts.push({ msg: 'global.errors.errorUpdateConfigValue' });
	            });
			}
	
	        AnnexaFormlyFactory.showModal('configurationModal', configurationModal, saveConfiguration, false);
    	}
        $scope.close_and_archive =  function(){
			var title = $linq($scope.form.fields).singleOrDefault({ templateOptions: { label: '' } }, "x => x.key == 'close_and_archive'");
            var conf = $linq(AdminFactory.configurations).singleOrDefault(undefined, "x => x.key === 'close_and_archive'");
            var confValue = JSON.parse(conf.value.replace(/\r?\n|\r/g, " "));
            var typesArchiveOptions = [
            	{id:'WITHOUT_ARCHIVE', name:$filter('translate')('global.literals.typesCloseAndArchive.WITHOUT_ARCHIVE')},
            	{id:'ARCHIVE_WITH_TOOL', name:$filter('translate')('global.literals.typesCloseAndArchive.ARCHIVE_WITH_TOOL')},
            	{id:'ARCHIVE_WITH_GEE', name:$filter('translate')('global.literals.typesCloseAndArchive.ARCHIVE_WITH_GEE')}
            ];
            var typesDeadlinesUnits = [
            	{id:'BUSINESS', name:$filter('translate')('global.literals.typesDeadlinesUnits.BUSINESS')},
            	{id:'NATURAL', name:$filter('translate')('global.literals.typesDeadlinesUnits.NATURAL')},
            	{id:'MONTH', name:$filter('translate')('global.literals.typesDeadlinesUnits.MONTH')},
            	{id:'YEARS', name:$filter('translate')('global.literals.typesDeadlinesUnits.YEARS')}
            ];
            var configurationModal = {
                title: title.templateOptions.label,
                icon: 'fa fa-gear',
                annexaFormly: {},
                alerts: [],
                submitModal: function () {}
            };

            var form = new AnnexaFormly();
            form.model = {};
            var keys = Object.keys(confValue);
            if(confValue && keys.length > 0){
            	_.forEach(keys, function(key){
            		if(key == "type"){
            			form.addField(
    		            		key,
    		                    'annexaSelectRow',
    		                    'col-sm-12',
    		                    new AnnexaFormlyFieldSelectTemplateOptions(
    		                    	'global.literals.'+key+'_closeAndArchive',
    		                        'id',
    		                        'name',
    		                        typesArchiveOptions,
    		                        true
    		                    ),
    		                    data
    		                );
            		}else if(key =="deadlines" || key=="automaticJob"){
            			if(key =="deadlines"){
            				var internKeys = Object.keys(confValue.deadlines);
            			}else{
            				var internKeys = Object.keys(confValue.automaticJob);
            			}
            			var index = 0;
            			_.forEach(internKeys, function(prop){
            				var formsDeadLinesAutJob = function(key,prop){
            					if(key=="deadlines"){
        							var capProp = prop.replace(/^./, prop[0].toUpperCase());
        							confValue['deadlines'+capProp+'Type'] = confValue.deadlines[prop].type;
                					form.addField(
                	                        'deadlines.'+prop+'.quantity',
                	                        'annexaInputRow',
                	                        'col-sm-12',
                	                        new AnnexaFormlyFieldTemplateOptions(
                	                            'number',
                	                            'global.literals.deadlines.'+prop+'.quantity',
                	                            false
                	                        ),
                	                        data
                	                    );
                					form.addField(
                							'deadlines'+capProp+'Type',
                		                    'annexaSelectRow',
                		                    'col-sm-12',
                		                    new AnnexaFormlyFieldSelectTemplateOptions(
                		                    	'global.literals.deadlines.'+prop+'.type',
                		                        'id',
                		                        'name',
                		                        typesDeadlinesUnits,
                		                        true
                		                    ),
                		                    data
                		                );
                				}else{
                					form.addField(
                        					"automaticJob."+prop,
                                            'annexaRadioCheckboxRow',
                                            'col-sm-12',
                                            new AnnexaFormlyFieldSelectTemplateOptions(
                                                'global.literals.automaticJob.'+prop,
                                                'value',
                                                'label',
                                                [{ value: true, label: $filter('translate')('global.literals.yes') }, { value: false, label: $filter('translate')('global.literals.no') }],
                                                false,
                                                'radio'
                                            ),
                                            data
                            			);
                				}
            				};
            				if(prop == "close"){
            					formsDeadLinesAutJob(key,prop);
                			}else if(prop == "transfer"){
                				formsDeadLinesAutJob(key,prop);
                			}else if(prop == "send"){
                				formsDeadLinesAutJob(key,prop);
                			}
            			});
            		}else if(key=="showArchivedDocument"){
            			form.addField(
            					key,
                                'annexaRadioCheckboxRow',
                                'col-sm-12',
                                new AnnexaFormlyFieldSelectTemplateOptions(
                                    'global.literals.'+key,
                                    'value',
                                    'label',
                                    [{ value: true, label: $filter('translate')('global.literals.yes') }, { value: false, label: $filter('translate')('global.literals.no') }],
                                    false,
                                    'radio'
                                ),
                                data
                			);
            		}
            	});
            }
            
            form.model = angular.copy(confValue);
            form.model.deadlines.close.quantity =  Number(form.model.deadlines.close.quantity);
            form.model.deadlines.transfer.quantity =  Number(form.model.deadlines.transfer.quantity);
            form.model.deadlines.send.quantity =  Number(form.model.deadlines.send.quantity);
            configurationModal.annexaFormly = form;

            var saveConfiguration = function() { 
            	var self = this;
            	var modelModal = this.annexaFormly.model;
            	var keysModal = Object.keys(modelModal);
            	var sendValue = angular.copy(modelModal);
            	_.forEach(keysModal, function(key){
            		if(key == 'deadlines'){
            			var internKeysModal = Object.keys(modelModal.deadlines);
            			_.forEach(internKeysModal, function(propModal){
            					var capProp = propModal.replace(/^./, propModal[0].toUpperCase());
            					sendValue.deadlines[propModal].type = modelModal['deadlines'+capProp+'Type'];
            					sendValue.deadlines[propModal].quantity = modelModal.deadlines[propModal].quantity.toString();
            			});
            		}else{
            			sendValue[key] = modelModal[key];
            		}
        			
            	});
            	delete sendValue.deadlinesCloseType;
            	delete sendValue.deadlinesTransferType;
            	delete sendValue.deadlinesSendType;
            	sendValue = JSON.stringify(sendValue);
            	AdminFactory.updateConfiguration('close_and_archive', sendValue, undefined)
	                .then(function(data) {
	                    self.close();
	                    var indexConfig = $linq(AdminFactory.configurations).indexOf("x => x.key === 'close_and_archive'");
	                    if(indexConfig != -1) {
	                    	GlobalDataFactory.configuration = [];
	                    	if(CacheFactory.get('globalDataCache') && CacheFactory.get('globalDataCache').get('configuration')){
	                        	CacheFactory.get('globalDataCache').remove('configuration')
	                        }
	                        GlobalDataFactory.loadGlobalData();
	                    }
	
	                }).catch(function (error) {
	                    self.alerts.push({ msg: 'global.errors.errorUpdateConfigValue' });
	            });
			}
	
	        AnnexaFormlyFactory.showModal('configurationModal', configurationModal, saveConfiguration, false);
    	}
        
        $scope.authentic_copy_document_configuration =  function(){
			var title = $linq($scope.form.fields).singleOrDefault({ templateOptions: { label: '' } }, "x => x.key == 'authentic_copy_document_configuration'");
            var conf = $linq(AdminFactory.configurations).singleOrDefault(undefined, "x => x.key === 'authentic_copy_document_configuration'");
            var confValue = JSON.parse(conf.value.replace(/\r?\n|\r/g, " "));
            var configurationModal = {
                title: title.templateOptions.label,
                icon: 'fa fa-gear',
                annexaFormly: {},
                alerts: [],
                submitModal: function () {}
            };

            var form = new AnnexaFormly();
            form.model = {};
            var keys = Object.keys(confValue);
            if(confValue && keys.length > 0){
            	_.forEach(keys, function(key){
            		if(key == "value"){
            			form.addField(
                                key,
                                'annexaInputRow',
                                'col-sm-12',
                                new AnnexaFormlyFieldTemplateOptions(
                                	'text',
                                	'global.literals.'+key,
                                    false
                                ),
                                data
                           );
            		}else if(key == "keys"){
            			confValue[key] = JSON.stringify(confValue[key]);
            			form.addField(
                                key,
                                'annexaInputRow',
                                'col-sm-12',
                                new AnnexaFormlyFieldTemplateOptions(
                                	'text',
                                	'global.literals.'+key,
                                    false
                                ),
                                data
                           );
            		}
            		
            	});
            }
            
            form.model = angular.copy(confValue);

            configurationModal.annexaFormly = form;

            var saveConfiguration = function() { 
            	var self = this;
            	var modelModal = this.annexaFormly.model;
            	var keysModal = Object.keys(modelModal);
            	var sendValue = angular.copy(modelModal);
            	var checkOk = true;
            	_.forEach(keysModal, function(key){
            		if(key == "keys" ){
            			if(modelModal[key].match(/^\[\{"key":"[^"]*"\},\{"key":"[^"]*"\},\{"key":"[^"]*"\}\]$/g)){
            				sendValue[key] = JSON.parse(modelModal[key].replace(/\r?\n|\r/g, " "));
            			}else{
            				self.alerts.push({ msg: 'global.errors.errorCheckStructureKey' });
            				checkOk=false;
            			}
            		}else{
            			sendValue[key] = modelModal[key];
            		}
            	});
            	if(checkOk){
            		sendValue = JSON.stringify(sendValue)
            		AdminFactory.updateConfiguration('authentic_copy_document_configuration', sendValue, undefined)
	                .then(function(data) {
	                    self.close();
	                    var indexConfig = $linq(AdminFactory.configurations).indexOf("x => x.key === 'authentic_copy_document_configuration'");
	                    if(indexConfig != -1) {
	                    	GlobalDataFactory.configuration = [];
	                    	if(CacheFactory.get('globalDataCache') && CacheFactory.get('globalDataCache').get('configuration')){
	                        	CacheFactory.get('globalDataCache').remove('configuration')
	                        }
	                        GlobalDataFactory.loadGlobalData();
	                    }
	
	                }).catch(function (error) {
	                    self.alerts.push({ msg: 'global.errors.errorUpdateConfigValue' });
	            });
            	}
            	
			}
	
	        AnnexaFormlyFactory.showModal('configurationModal', configurationModal, saveConfiguration, false);
    	}

		$scope.decreeNumbersConfiguration =  function(){
			var title = $linq($scope.form.fields).singleOrDefault({ templateOptions: { label: '' } }, "x => x.key == 'decreeNumbersConfiguration'");
            var conf = $linq(AdminFactory.configurations).singleOrDefault(undefined, "x => x.key === 'decreeNumbersConfiguration'");
			var confSecretaryProperties = $linq(AdminFactory.configurations).singleOrDefault(undefined, "x => x.key === 'secretary_properties'");
			var sequenceType = 'ANUAL';
			if(confSecretaryProperties && confSecretaryProperties.value){
				var confSecretaryValue = JSON.parse(confSecretaryProperties.value.replace(/\r?\n|\r/g, " "));
				if(confSecretaryValue && confSecretaryValue.decree_number){
					sequenceType = confSecretaryValue.decree_number;
				}
			}
            var confValue = ((conf && conf.value)?JSON.parse(conf.value.replace(/\r?\n|\r/g, " ")):{});
			
            var configurationModal = {
                title: title.templateOptions.label,
                icon: 'fa fa-gear',
                annexaFormly: {},
                alerts: [],
                submitModal: function () {}
            };
			
			var types = [];
			if(GlobalDataFactory.proposalTypes && GlobalDataFactory.proposalTypes.length > 0){
				var typesAux = $linq(GlobalDataFactory.proposalTypes).where("x => x.proposalTypeSubtype == 'DECREE'").toArray();
				types = angular.copy(typesAux);
			}
			
            var form = new AnnexaFormly();
            form.model = {};
			form.fields.push({
                key: conf.key,
                type: 'annexaComponent',
                templateOptions: {
                    type: 'annexa-sequence-configuration'
                },
				data: {
					model: angular.copy(confValue),
					sequenceType: sequenceType,
					types:types,
					typeDescription:Language.getActiveColumn(),
					objectType: 'DECREE'
				}
        	});
            form.model = angular.copy(confValue);

            configurationModal.annexaFormly = form;

            var saveConfiguration = function() { 
            	var self = this;
				if(this.annexaFormly && this.annexaFormly.fields){
					var field = $linq(this.annexaFormly.fields).firstOrDefault(undefined, "x => x.key == '"+conf.key+"'");
					if(field && field.data && field.data.model){
						var sendValue = angular.toJson(field.data.model);
		            	AdminFactory.updateConfiguration('decreeNumbersConfiguration', sendValue, undefined)
			                .then(function(data) {
			                    self.close();
			                    var indexConfig = $linq(AdminFactory.configurations).indexOf("x => x.key === 'decreeNumbersConfiguration'");
			                    if(indexConfig != -1) {
			                    	GlobalDataFactory.configuration = [];
			                    	if(CacheFactory.get('globalDataCache') && CacheFactory.get('globalDataCache').get('configuration')){
			                        	CacheFactory.get('globalDataCache').remove('configuration')
			                        }
			                        GlobalDataFactory.loadGlobalData();
			                    }
			
			                }).catch(function (error) {
			                    self.alerts.push({ msg: 'global.errors.errorUpdateConfigValue' });
			            });
					} 
				}
			}
	
	        AnnexaFormlyFactory.showModal('configurationModal', configurationModal, saveConfiguration, false);
    	}

		$scope.sessionNumbersConfiguration =  function(){
			var title = $linq($scope.form.fields).singleOrDefault({ templateOptions: { label: '' } }, "x => x.key == 'sessionNumbersConfiguration'");
            var conf = $linq(AdminFactory.configurations).singleOrDefault(undefined, "x => x.key === 'sessionNumbersConfiguration'");
			var confSecretaryProperties = $linq(AdminFactory.configurations).singleOrDefault(undefined, "x => x.key === 'secretary_properties'");
			var sequenceType = 'ANUAL';
			if(confSecretaryProperties && confSecretaryProperties.value){
				var confSecretaryValue = JSON.parse(confSecretaryProperties.value.replace(/\r?\n|\r/g, " "));
				if(confSecretaryValue && confSecretaryValue.session_number){
					sequenceType = confSecretaryValue.session_number;
				}
			}
            var confValue = ((conf && conf.value)?JSON.parse(conf.value.replace(/\r?\n|\r/g, " ")):{});
			
            var configurationModal = {
                title: title.templateOptions.label,
                icon: 'fa fa-gear',
                annexaFormly: {},
                alerts: [],
                submitModal: function () {}
            };
			
			var types = [];
			if(GlobalDataFactory.organs && GlobalDataFactory.organs.length > 0){
				types = angular.copy(GlobalDataFactory.organs);
			}
			
            var form = new AnnexaFormly();
            form.model = {};
			form.fields.push({
                key: conf.key,
                type: 'annexaComponent',
                templateOptions: {
                    type: 'annexa-sequence-configuration'
                },
				data: {
					model: angular.copy(confValue),
					sequenceType: sequenceType,
					types:types,
					typeDescription:Language.getActiveColumn(),
					objectType: 'SESSION'
				}
        	});
            form.model = angular.copy(confValue);

            configurationModal.annexaFormly = form;

            var saveConfiguration = function() { 
            	var self = this;
				if(this.annexaFormly && this.annexaFormly.fields){
					var field = $linq(this.annexaFormly.fields).firstOrDefault(undefined, "x => x.key == '"+conf.key+"'");
					if(field && field.data && field.data.model){
						var sendValue = angular.toJson(field.data.model);
		            	AdminFactory.updateConfiguration('sessionNumbersConfiguration', sendValue, undefined)
			                .then(function(data) {
			                    self.close();
			                    var indexConfig = $linq(AdminFactory.configurations).indexOf("x => x.key === 'sessionNumbersConfiguration'");
			                    if(indexConfig != -1) {
			                    	GlobalDataFactory.configuration = [];
			                    	if(CacheFactory.get('globalDataCache') && CacheFactory.get('globalDataCache').get('configuration')){
			                        	CacheFactory.get('globalDataCache').remove('configuration')
			                        }
			                        GlobalDataFactory.loadGlobalData();
			                    }
			
			                }).catch(function (error) {
			                    self.alerts.push({ msg: 'global.errors.errorUpdateConfigValue' });
			            });
					} 
				}
			}
	
	        AnnexaFormlyFactory.showModal('configurationModal', configurationModal, saveConfiguration, false);
    	}
		
		$scope.create_document_from_template_properties =  function(){
			var title = $linq($scope.form.fields).singleOrDefault({ templateOptions: { label: '' } }, "x => x.key == 'create_document_from_template_properties'");
            var conf = $linq(AdminFactory.configurations).singleOrDefault(undefined, "x => x.key === 'create_document_from_template_properties'");
            var confValue = ((conf && conf.value)?JSON.parse(conf.value.replace(/\r?\n|\r/g, " ")):{});
			
            var configurationModal = {
                title: title.templateOptions.label,
                icon: 'fa fa-gear',
                annexaFormly: {},
                alerts: [],
                submitModal: function () {}
            };
			
            var form = new AnnexaFormly();
            form.model = {};
			form.fields.push({
                key: conf.key,
                type: 'annexaComponent',
                templateOptions: {
                    type: 'annexa-create-documents-config'
                },
				data: {
					model: angular.copy(confValue),
				}
        	});
            form.model = angular.copy(confValue);

            configurationModal.annexaFormly = form;

            var saveConfiguration = function() { 
            	var self = this;
				if(this.annexaFormly && this.annexaFormly.fields){
					var field = $linq(this.annexaFormly.fields).firstOrDefault(undefined, "x => x.key == '"+conf.key+"'");
					if(field && field.data && field.data.model){
						var sendValue = angular.toJson(field.data.model);
		            	AdminFactory.updateConfiguration('create_document_from_template_properties', sendValue, undefined)
			                .then(function(data) {
			                    self.close();
			                    var indexConfig = $linq(AdminFactory.configurations).indexOf("x => x.key === 'create_document_from_template_properties'");
			                    if(indexConfig != -1) {
			                    	GlobalDataFactory.configuration = [];
			                    	if(CacheFactory.get('globalDataCache') && CacheFactory.get('globalDataCache').get('configuration')){
			                        	CacheFactory.get('globalDataCache').remove('configuration')
			                        }
			                        GlobalDataFactory.loadGlobalData();
			                    }
			
			                }).catch(function (error) {
			                    self.alerts.push({ msg: 'global.errors.errorUpdateConfigValue' });
			            });
					} 
				}
			}
	
	        AnnexaFormlyFactory.showModal('configurationModal', configurationModal, saveConfiguration, false);
    	}

		$scope.proposalNumbersConfiguration =  function(){
			var title = $linq($scope.form.fields).singleOrDefault({ templateOptions: { label: '' } }, "x => x.key == 'proposalNumbersConfiguration'");
            var conf = $linq(AdminFactory.configurations).singleOrDefault(undefined, "x => x.key === 'proposalNumbersConfiguration'");
			var confSecretaryProperties = $linq(AdminFactory.configurations).singleOrDefault(undefined, "x => x.key === 'secretary_properties'");
			var sequenceType = 'ANUAL';
			if(confSecretaryProperties && confSecretaryProperties.value){
				var confSecretaryValue = JSON.parse(confSecretaryProperties.value.replace(/\r?\n|\r/g, " "));
				if(confSecretaryValue && confSecretaryValue.proposal_number){
					sequenceType = confSecretaryValue.proposal_number;
				}
			}
            var confValue = ((conf && conf.value)?JSON.parse(conf.value.replace(/\r?\n|\r/g, " ")):{});
			
            var configurationModal = {
                title: title.templateOptions.label,
                icon: 'fa fa-gear',
                annexaFormly: {},
                alerts: [],
                submitModal: function () {}
            };
			
			var types = [];
			if(GlobalDataFactory.organs && GlobalDataFactory.organs.length > 0){
				types = angular.copy(GlobalDataFactory.organs);
			}
			
            var form = new AnnexaFormly();
            form.model = {};
			form.fields.push({
                key: conf.key,
                type: 'annexaComponent',
                templateOptions: {
                    type: 'annexa-sequence-configuration'
                },
				data: {
					model: angular.copy(confValue),
					sequenceType: sequenceType,
					types:types,
					typeDescription:Language.getActiveColumn(),
					objectType: 'PROPOSAL'
				}
        	});
            form.model = angular.copy(confValue);

            configurationModal.annexaFormly = form;

            var saveConfiguration = function() { 
            	var self = this;
				if(this.annexaFormly && this.annexaFormly.fields){
					var field = $linq(this.annexaFormly.fields).firstOrDefault(undefined, "x => x.key == '"+conf.key+"'");
					if(field && field.data && field.data.model){
						var sendValue = angular.toJson(field.data.model);
		            	AdminFactory.updateConfiguration('proposalNumbersConfiguration', sendValue, undefined)
			                .then(function(data) {
			                    self.close();
			                    var indexConfig = $linq(AdminFactory.configurations).indexOf("x => x.key === 'proposalNumbersConfiguration'");
			                    if(indexConfig != -1) {
			                    	GlobalDataFactory.configuration = [];
			                    	if(CacheFactory.get('globalDataCache') && CacheFactory.get('globalDataCache').get('configuration')){
			                        	CacheFactory.get('globalDataCache').remove('configuration')
			                        }
			                        GlobalDataFactory.loadGlobalData();
			                    }
			
			                }).catch(function (error) {
			                    self.alerts.push({ msg: 'global.errors.errorUpdateConfigValue' });
			            });
					} 
				}
			}
	
	        AnnexaFormlyFactory.showModal('configurationModal', configurationModal, saveConfiguration, false);
    	}


		$scope.dossierNumbersConfiguration =  function(){
			var title = $linq($scope.form.fields).singleOrDefault({ templateOptions: { label: '' } }, "x => x.key == 'dossierNumbersConfiguration'");
            var conf = $linq(AdminFactory.configurations).singleOrDefault(undefined, "x => x.key === 'dossierNumbersConfiguration'");
			var confDossierProperties = $linq(AdminFactory.configurations).singleOrDefault(undefined, "x => x.key === 'procedure_number_count_type'");
			var sequenceType = 'GLOBAL';
			if(confDossierProperties && confDossierProperties.value){
				var confDossierProperties = JSON.parse(confDossierProperties.value.replace(/\r?\n|\r/g, " "));
				if(confDossierProperties && confDossierProperties.type){
					sequenceType = confDossierProperties.type;
				}
			}
            var confValue = ((conf && conf.value)?JSON.parse(conf.value.replace(/\r?\n|\r/g, " ")):{});
			
            var configurationModal = {
                title: title.templateOptions.label,
                icon: 'fa fa-gear',
                annexaFormly: {},
                alerts: [],
                submitModal: function () {}
            };
			
			var types = [];
			if(GlobalDataFactory.procedures && GlobalDataFactory.procedures.length > 0){
				types = angular.copy(GlobalDataFactory.procedures);
			}
			
            var form = new AnnexaFormly();
            form.model = {};
			form.fields.push({
                key: conf.key,
                type: 'annexaComponent',
                templateOptions: {
                    type: 'annexa-sequence-configuration'
                },
				data: {
					model: angular.copy(confValue),
					sequenceType: sequenceType,
					types:types,
					typeDescription:Language.getActiveColumn(),
					objectType: 'DOSSIER'
				}
        	});
            form.model = angular.copy(confValue);

            configurationModal.annexaFormly = form;

            var saveConfiguration = function() { 
            	var self = this;
				if(this.annexaFormly && this.annexaFormly.fields){
					var field = $linq(this.annexaFormly.fields).firstOrDefault(undefined, "x => x.key == '"+conf.key+"'");
					if(field && field.data && field.data.model){
						var sendValue = angular.toJson(field.data.model);
		            	AdminFactory.updateConfiguration('dossierNumbersConfiguration', sendValue, undefined)
			                .then(function(data) {
			                    self.close();
			                    var indexConfig = $linq(AdminFactory.configurations).indexOf("x => x.key === 'dossierNumbersConfiguration'");
			                    if(indexConfig != -1) {
			                    	GlobalDataFactory.configuration = [];
			                    	if(CacheFactory.get('globalDataCache') && CacheFactory.get('globalDataCache').get('configuration')){
			                        	CacheFactory.get('globalDataCache').remove('configuration')
			                        }
			                        GlobalDataFactory.loadGlobalData();
			                    }
			
			                }).catch(function (error) {
			                    self.alerts.push({ msg: 'global.errors.errorUpdateConfigValue' });
			            });
					} 
				}
			}
	
	        AnnexaFormlyFactory.showModal('configurationModal', configurationModal, saveConfiguration, false);
    	}

		$scope.taskNumbersConfiguration =  function(){
			var openTaskNumbersModal = function(types){
				var title = $linq($scope.form.fields).singleOrDefault({ templateOptions: { label: '' } }, "x => x.key == 'taskNumbersConfiguration'");
	            var conf = $linq(AdminFactory.configurations).singleOrDefault(undefined, "x => x.key === 'taskNumbersConfiguration'");
				var confTaskProperties = $linq(AdminFactory.configurations).singleOrDefault(undefined, "x => x.key === 'task_number_count_type'");
				var sequenceType = 'GLOBAL';
				if(confTaskProperties && confTaskProperties.value){
					var confTaskProperties = JSON.parse(confTaskProperties.value.replace(/\r?\n|\r/g, " "));
					if(confTaskProperties && confTaskProperties.type){
						sequenceType = confTaskProperties.type;
					}
				}
	            var confValue = ((conf && conf.value)?JSON.parse(conf.value.replace(/\r?\n|\r/g, " ")):{});
				
	            var configurationModal = {
	                title: title.templateOptions.label,
	                icon: 'fa fa-gear',
	                annexaFormly: {},
	                alerts: [],
	                submitModal: function () {}
	            };
				
				if(types === undefined){
					types = [];
				}

	            var form = new AnnexaFormly();
	            form.model = {};
				form.fields.push({
	                key: conf.key,
	                type: 'annexaComponent',
	                templateOptions: {
	                    type: 'annexa-sequence-configuration'
	                },
					data: {
						model: angular.copy(confValue),
						sequenceType: sequenceType,
						types:types,
						typeDescription:Language.getActiveColumn(),
						objectType: 'TASK'
					}
	        	});
	            form.model = angular.copy(confValue);
	
	            configurationModal.annexaFormly = form;
	
	            var saveConfiguration = function() { 
	            	var self = this;
					if(this.annexaFormly && this.annexaFormly.fields){
						var field = $linq(this.annexaFormly.fields).firstOrDefault(undefined, "x => x.key == '"+conf.key+"'");
						if(field && field.data && field.data.model){
							var sendValue = angular.toJson(field.data.model);
			            	AdminFactory.updateConfiguration('taskNumbersConfiguration', sendValue, undefined)
				                .then(function(data) {
				                    self.close();
				                    var indexConfig = $linq(AdminFactory.configurations).indexOf("x => x.key === 'taskNumbersConfiguration'");
				                    if(indexConfig != -1) {
				                    	GlobalDataFactory.configuration = [];
				                    	if(CacheFactory.get('globalDataCache') && CacheFactory.get('globalDataCache').get('configuration')){
				                        	CacheFactory.get('globalDataCache').remove('configuration')
				                        }
				                        GlobalDataFactory.loadGlobalData();
				                    }
				
				                }).catch(function (error) {
				                    self.alerts.push({ msg: 'global.errors.errorUpdateConfigValue' });
				            });
						} 
					}
				}
		
		        AnnexaFormlyFactory.showModal('configurationModal', configurationModal, saveConfiguration, false);
			}
			if(GlobalDataFactory.taskTypes && GlobalDataFactory.taskTypes.length > 0){
				openTaskNumbersModal(angular.copy(GlobalDataFactory.taskTypes));
			}else{
				RestService.findAll('TaskType').then(function(data){
					openTaskNumbersModal(JSOG.decode(data).data);
				}).catch(function(error){
					openTaskNumbersModal(angular.copy(GlobalDataFactory.taskTypes));
				});
			}
				
			
		}

		$scope.creation_decree_documents =  function(){
			var title = $linq($scope.form.fields).singleOrDefault({ templateOptions: { label: '' } }, "x => x.key == 'creation_decree_documents'");
            var conf = $linq(AdminFactory.configurations).singleOrDefault(undefined, "x => x.key === 'creation_decree_documents'");
			var confDecPluginProperties = $linq(AdminFactory.configurations).singleOrDefault(undefined, "x => x.key === 'creation_decree_documents_plugin'");
			var types = [];
			if(confDecPluginProperties && confDecPluginProperties.value){
				var confDecPluginProperties = JSON.parse(confDecPluginProperties.value.replace(/\r?\n|\r/g, " "));
				if(confDecPluginProperties && confDecPluginProperties.documentTypes){
					var dtypes = confDecPluginProperties.documentTypes.split(",");
					if(dtypes && dtypes.length > 0){
						_.forEach(dtypes, function(dtype){
							if(GlobalDataFactory.documentTypes && GlobalDataFactory.documentTypes.length > 0){
								var typeAux = $linq(GlobalDataFactory.documentTypes).firstOrDefault(undefined, "x => x.acronym == '"+dtype+"'");
								if(typeAux && typeAux.id){
									var typeAux = angular.copy(typeAux);
									typeAux.showYearConfigPlugin =  ((confDecPluginProperties["numberCountType."+dtype] && confDecPluginProperties["numberCountType."+dtype] !== '')? true:false);
									types.push(typeAux);
								}
							}
						});		
					}
				}
			}
            var confValue = ((conf && conf.value)?JSON.parse(conf.value.replace(/\r?\n|\r/g, " ")):{});
            var configurationModal = {
                title: title.templateOptions.label,
                icon: 'fa fa-gear',
                annexaFormly: {},
                alerts: [],
                submitModal: function () {}
            };
			
            var form = new AnnexaFormly();
            form.model = {};
			form.fields.push({
                key: conf.key,
                type: 'annexaComponent',
                templateOptions: {
                    type: 'annexa-sequence-configuration-plugin-decree'
                },
				data: {
					model: angular.copy(confValue),
					types:types,
					typeDescription:Language.getActiveColumn(),
					objectType: 'DECREE'
				}
        	});
            form.model = angular.copy(confValue);

            configurationModal.annexaFormly = form;

            var saveConfiguration = function() { 
            	var self = this;
				if(this.annexaFormly && this.annexaFormly.fields){
					var field = $linq(this.annexaFormly.fields).firstOrDefault(undefined, "x => x.key == '"+conf.key+"'");
					if(field && field.data && field.data.model){
						var sendValue = angular.toJson(field.data.model);
		            	AdminFactory.updateConfiguration('creation_decree_documents', sendValue, undefined)
			                .then(function(data) {
			                    self.close();
			                    var indexConfig = $linq(AdminFactory.configurations).indexOf("x => x.key === 'creation_decree_documents'");
			                    if(indexConfig != -1) {
			                    	GlobalDataFactory.configuration = [];
			                    	if(CacheFactory.get('globalDataCache') && CacheFactory.get('globalDataCache').get('configuration')){
			                        	CacheFactory.get('globalDataCache').remove('configuration')
			                        }
			                        GlobalDataFactory.loadGlobalData();
			                    }
			
			                }).catch(function (error) {
			                    self.alerts.push({ msg: 'global.errors.errorUpdateConfigValue' });
			            });
					} 
				}
			}
	
	        AnnexaFormlyFactory.showModal('configurationModal', configurationModal, saveConfiguration, false);
    	}

		$scope.registerEntryNumbersConfiguration =  function(){
			var title = $linq($scope.form.fields).singleOrDefault({ templateOptions: { label: '' } }, "x => x.key == 'registerEntryNumbersConfiguration'");
            var conf = $linq(AdminFactory.configurations).singleOrDefault(undefined, "x => x.key === 'registerEntryNumbersConfiguration'");
            var confValue = ((conf && conf.value)?JSON.parse(conf.value.replace(/\r?\n|\r/g, " ")):{});
            var configurationModal = {
                title: title.templateOptions.label,
                icon: 'fa fa-gear',
                annexaFormly: {},
                alerts: [],
                submitModal: function () {}
            };
			
			var types = [];
			if(GlobalDataFactory.registerEntryOffices && GlobalDataFactory.registerEntryOffices.length > 0){
				types = angular.copy(GlobalDataFactory.registerEntryOffices);
			}

            var form = new AnnexaFormly();
            form.model = {};
			form.fields.push({
                key: conf.key,
                type: 'annexaComponent',
                templateOptions: {
                    type: 'annexa-register-entry-sequence-configuration'
                },
				data: {
					model: angular.copy(confValue),
					types:types,
					typeDescription:Language.getActiveColumn(),
					objectType: 'REGISTE_ENTRY'
				}
        	});
            form.model = angular.copy(confValue);

            configurationModal.annexaFormly = form;

            var saveConfiguration = function() { 
            	var self = this;
				if(this.annexaFormly && this.annexaFormly.fields){
					var field = $linq(this.annexaFormly.fields).firstOrDefault(undefined, "x => x.key == '"+conf.key+"'");
					if(field && field.data && field.data.model){
						var sendValue = angular.toJson(field.data.model);
		            	AdminFactory.updateConfiguration('registerEntryNumbersConfiguration', sendValue, undefined)
			                .then(function(data) {
			                    self.close();
			                    var indexConfig = $linq(AdminFactory.configurations).indexOf("x => x.key === 'registerEntryNumbersConfiguration'");
			                    if(indexConfig != -1) {
			                    	GlobalDataFactory.configuration = [];
			                    	if(CacheFactory.get('globalDataCache') && CacheFactory.get('globalDataCache').get('configuration')){
			                        	CacheFactory.get('globalDataCache').remove('configuration')
			                        }
			                        GlobalDataFactory.loadGlobalData();
			                    }
			
			                }).catch(function (error) {
			                    self.alerts.push({ msg: 'global.errors.errorUpdateConfigValue' });
			            });
					} 
				}
			}
	
	        AnnexaFormlyFactory.showModal('configurationModal', configurationModal, saveConfiguration, false);
    	}

		$scope.relate_thirds_users_automatically_creating_tasks =  function(){
			var title = $linq($scope.form.fields).singleOrDefault({ templateOptions: { label: '' } }, "x => x.key == 'relate_thirds_users_automatically_creating_tasks'");
            var conf = $linq(AdminFactory.configurations).singleOrDefault(undefined, "x => x.key === 'relate_thirds_users_automatically_creating_tasks'");
            var confValue = JSON.parse(conf.value.replace(/\r?\n|\r/g, " "));
            var configurationModal = {
                title: title.templateOptions.label,
                icon: 'fa fa-gear',
                annexaFormly: {},
                alerts: [],
                submitModal: function () {}
            };

            var form = new AnnexaFormly();
            form.model = {};
            var keys = Object.keys(confValue);
            if(confValue && keys.length > 0){
            	_.forEach(keys, function(key){
            		if(key == "enabled" || key == "relate_all_found"){
            			form.addField(
            					key,
                                'annexaRadioCheckboxRow',
                                'col-sm-12',
                                new AnnexaFormlyFieldSelectTemplateOptions(
                                    'global.configuration.relatedThirdsUsersAutoCreateTasks.'+key,
                                    'value',
                                    'label',
                                    [{ value: true, label: $filter('translate')('global.literals.yes') }, { value: false, label: $filter('translate')('global.literals.no') }],
                                    false,
                                    'radio'
                                ),
                                data
                			);
            		}
            		
            	});
            }
            
            form.model = angular.copy(confValue);

            configurationModal.annexaFormly = form;

            var saveConfiguration = function() { 
            	var self = this;
            	var modelModal = this.annexaFormly.model;
            	var keysModal = Object.keys(modelModal);
            	var sendValue = angular.copy(modelModal);
            	_.forEach(keysModal, function(key){
           			sendValue[key] = modelModal[key];
            	});
            	sendValue = JSON.stringify(sendValue)
            	AdminFactory.updateConfiguration('relate_thirds_users_automatically_creating_tasks', sendValue, undefined)
	                .then(function(data) {
	                    self.close();
	                    var indexConfig = $linq(AdminFactory.configurations).indexOf("x => x.key === 'relate_thirds_users_automatically_creating_tasks'");
	                    if(indexConfig != -1) {
	                    	GlobalDataFactory.configuration = [];
	                    	if(CacheFactory.get('globalDataCache') && CacheFactory.get('globalDataCache').get('configuration')){
	                        	CacheFactory.get('globalDataCache').remove('configuration')
	                        }
	                        GlobalDataFactory.loadGlobalData();
	                    }
	
	                }).catch(function (error) {
	                    self.alerts.push({ msg: 'global.errors.errorUpdateConfigValue' });
	            });
			}
            AnnexaFormlyFactory.showModal('configurationModal', configurationModal, saveConfiguration, false);
    	}
		
		$scope.assign_responsible_user =  function(){
			var title = $linq($scope.form.fields).singleOrDefault({ templateOptions: { label: '' } }, "x => x.key == 'assign_responsible_user'");
            var conf = $linq(AdminFactory.configurations).singleOrDefault(undefined, "x => x.key === 'assign_responsible_user'");
            var confValue = JSON.parse(conf.value.replace(/\r?\n|\r/g, " "));
            var typesAssignOptions = [
            	{id:'Obligatoria', name:$filter('translate')('global.configuration.typesAssignOptions.obligatory')},
            	{id:'Opcional', name:$filter('translate')('global.configuration.typesAssignOptions.optional')},
            	{id:'Sin definir', name:$filter('translate')('global.configuration.typesAssignOptions.undefined')}
            ];
            var configurationModal = {
                title: title.templateOptions.label,
                icon: 'fa fa-gear',
                annexaFormly: {},
                alerts: [],
                submitModal: function () {}
            };

            var form = new AnnexaFormly();
            form.model = {};
            var keys = Object.keys(confValue);
            if(confValue && keys.length > 0){
            	_.forEach(keys, function(key){
            		if(key == "dossierTransaction" || key == "registerEntryDiligence"){
            			form.addField(
    		            		key,
    		                    'annexaSelectRow',
    		                    'col-sm-12',
    		                    new AnnexaFormlyFieldSelectTemplateOptions(
    		                    	'global.configuration.assignResponsibleUser.'+key,
    		                        'id',
    		                        'name',
    		                        typesAssignOptions,
    		                        true
    		                    ),
    		                    data
    		                );
            		}
            		
            	});
            }
            
            form.model = angular.copy(confValue);

            configurationModal.annexaFormly = form;

            var saveConfiguration = function() { 
            	var self = this;
            	var modelModal = this.annexaFormly.model;
            	var keysModal = Object.keys(modelModal);
            	var sendValue = angular.copy(modelModal);
            	_.forEach(keysModal, function(key){
           			sendValue[key] = modelModal[key];
            	});
            	sendValue = JSON.stringify(sendValue)
            	AdminFactory.updateConfiguration('assign_responsible_user', sendValue, undefined)
	                .then(function(data) {
	                    self.close();
	                    var indexConfig = $linq(AdminFactory.configurations).indexOf("x => x.key === 'assign_responsible_user'");
	                    if(indexConfig != -1) {
	                    	GlobalDataFactory.configuration = [];
	                    	if(CacheFactory.get('globalDataCache') && CacheFactory.get('globalDataCache').get('configuration')){
	                        	CacheFactory.get('globalDataCache').remove('configuration')
	                        }
	                        GlobalDataFactory.loadGlobalData();
	                    }
	
	                }).catch(function (error) {
	                    self.alerts.push({ msg: 'global.errors.errorUpdateConfigValue' });
	            });
			}
            AnnexaFormlyFactory.showModal('configurationModal', configurationModal, saveConfiguration, false);
    	}
		
		$scope.role_semic =  function(){
			var title = $linq($scope.form.fields).singleOrDefault({ templateOptions: { label: '' } }, "x => x.key == 'role_semic'");
            var conf = $linq(AdminFactory.configurations).singleOrDefault(undefined, "x => x.key === 'role_semic'");
            var confValue = JSON.parse(conf.value.replace(/\r?\n|\r/g, " "));
            var configurationModal = {
                title: title.templateOptions.label,
                icon: 'fa fa-gear',
                annexaFormly: {},
                alerts: [],
                submitModal: function () {}
            };

            var form = new AnnexaFormly();
            form.model = {};
            var keys = Object.keys(confValue);
            if(confValue && keys.length > 0){
            	_.forEach(keys, function(key){
            		if(key == "value"){
            			confValue[key] = confValue[key].toString();
            			form.addField(
                                key,
                                'annexaInputRow',
                                'col-sm-12',
                                new AnnexaFormlyFieldTemplateOptions(
                                	'text',
                                	'global.configuration.role_semic.'+key,
                                    false
                                ),
                                data
                           );
            		}
            		
            	});
            }
            
            form.model = angular.copy(confValue);

            configurationModal.annexaFormly = form;

            var saveConfiguration = function() { 
            	var self = this;
            	var modelModal = this.annexaFormly.model;
            	var keysModal = Object.keys(modelModal);
            	var sendValue = angular.copy(modelModal);
            	_.forEach(keysModal, function(key){
            		var ammendKeys = [];
            		if(key == "value" && modelModal[key] != ""){
            			sendValue[key] = modelModal[key].split(",");
            			_.forEach(sendValue[key], function(keys){
            				ammendKeys.push(Number(keys.trim()));
            			});
            			sendValue[key] = ammendKeys;
            		}else if(key == "value" && modelModal[key] == ""){
            			sendValue[key] = [];
            		}else{
            			sendValue[key] = modelModal[key];
            		}
            	});
            	sendValue = JSON.stringify(sendValue)
            	AdminFactory.updateConfiguration('role_semic', sendValue, undefined)
	                .then(function(data) {
	                    self.close();
	                    var indexConfig = $linq(AdminFactory.configurations).indexOf("x => x.key === 'role_semic'");
	                    if(indexConfig != -1) {
	                    	GlobalDataFactory.configuration = [];
	                    	if(CacheFactory.get('globalDataCache') && CacheFactory.get('globalDataCache').get('configuration')){
	                        	CacheFactory.get('globalDataCache').remove('configuration')
	                        }
	                        GlobalDataFactory.loadGlobalData();
	                    }
	
	                }).catch(function (error) {
	                    self.alerts.push({ msg: 'global.errors.errorUpdateConfigValue' });
	            });
			}
            AnnexaFormlyFactory.showModal('configurationModal', configurationModal, saveConfiguration, false);
    	}
		
		$scope.book_params_sicres =  function(){
			var title = $linq($scope.form.fields).singleOrDefault({ templateOptions: { label: '' } }, "x => x.key == 'book_params_sicres'");
            var conf = $linq(AdminFactory.configurations).singleOrDefault(undefined, "x => x.key === 'book_params_sicres'");
            var confValue = JSON.parse(conf.value.replace(/\r?\n|\r/g, " "));
            var configurationModal = {
                title: title.templateOptions.label,
                icon: 'fa fa-gear',
                annexaFormly: {},
                alerts: [],
                submitModal: function () {}
            };
            
            var form = new AnnexaFormly();
            form.model = {};
            form.model = angular.copy(confValue);
            var keys = Object.keys(confValue);
            if(confValue && keys.length > 0){
            	_.forEach(keys, function(key){
            		if(key == "tramitationUnitCode" || key == "tramitationUnitName"|| key == "expose"|| key == "request"){
            			form.addField(
                            key,
                            'annexaInputRow',
                            'col-sm-12',
                            new AnnexaFormlyFieldTemplateOptions(
                            	'text',
                            	'global.configuration.book_params_sicres.'+key,
                                false
                            ),
                            data
                       );
            		}
            	});
            }
            
            configurationModal.annexaFormly = form;

            var saveConfiguration = function() { 
            	var self = this;
            	var modelModal = this.annexaFormly.model;
            	var keysModal = Object.keys(modelModal);
            	var sendValue = angular.copy(modelModal);
            	_.forEach(keysModal, function(key){
            		sendValue[key] = modelModal[key];
            	});
            	sendValue = JSON.stringify(sendValue)
            	AdminFactory.updateConfiguration('book_params_sicres', sendValue, undefined)
	                .then(function(data) {
	                    self.close();
	                    var indexConfig = $linq(AdminFactory.configurations).indexOf("x => x.key === 'book_params_sicres'");
	                    if(indexConfig != -1) {
	                    	GlobalDataFactory.configuration = [];
	                    	if(CacheFactory.get('globalDataCache') && CacheFactory.get('globalDataCache').get('configuration')){
	                        	CacheFactory.get('globalDataCache').remove('configuration')
	                        }
	                        GlobalDataFactory.loadGlobalData();
	                    }
	
	                }).catch(function (error) {
	                    self.alerts.push({ msg: 'global.errors.errorUpdateConfigValue' });
	            });
			}
            AnnexaFormlyFactory.showModal('configurationModal', configurationModal, saveConfiguration, false);
    	}
		
		$scope.merge_document_properties =  function(){
			var title = $linq($scope.form.fields).singleOrDefault({ templateOptions: { label: '' } }, "x => x.key == 'merge_document_properties'");
            var conf = $linq(AdminFactory.configurations).singleOrDefault(undefined, "x => x.key === 'merge_document_properties'");
            var confValue = JSON.parse(conf.value.replace(/\r?\n|\r/g, " "));
            var configurationModal = {
                title: title.templateOptions.label,
                icon: 'fa fa-gear',
                annexaFormly: {},
                alerts: [],
                submitModal: function () {}
            };

            var form = new AnnexaFormly();
            form.model = {};
            var keys = Object.keys(confValue);
            if(confValue && keys.length > 0){
            	_.forEach(keys, function(key){
            		if(key == "mergeFormat" || key == "newLibrary" || key == "newLibraryBooks"){
            			form.addField(
            					key,
                                'annexaRadioCheckboxRow',
                                'col-sm-12',
                                new AnnexaFormlyFieldSelectTemplateOptions(
                                    'global.configuration.merge_document_properties.'+key,
                                    'value',
                                    'label',
                                    [{ value: true, label: $filter('translate')('global.literals.yes') }, { value: false, label: $filter('translate')('global.literals.no') }],
                                    false,
                                    'radio'
                                ),
                                data
                			);
            		}
            		
            	});
            }
            
            form.model = angular.copy(confValue);

            configurationModal.annexaFormly = form;

            var saveConfiguration = function() { 
            	var self = this;
            	var modelModal = this.annexaFormly.model;
            	var keysModal = Object.keys(modelModal);
            	var sendValue = angular.copy(modelModal);
            	_.forEach(keysModal, function(key){
           			sendValue[key] = modelModal[key];
            	});
            	sendValue = JSON.stringify(sendValue)
            	AdminFactory.updateConfiguration('merge_document_properties', sendValue, undefined)
	                .then(function(data) {
	                    self.close();
	                    var indexConfig = $linq(AdminFactory.configurations).indexOf("x => x.key === 'merge_document_properties'");
	                    if(indexConfig != -1) {
	                    	GlobalDataFactory.configuration = [];
	                    	if(CacheFactory.get('globalDataCache') && CacheFactory.get('globalDataCache').get('configuration')){
	                        	CacheFactory.get('globalDataCache').remove('configuration')
	                        }
	                        GlobalDataFactory.loadGlobalData();
	                    }
	
	                }).catch(function (error) {
	                    self.alerts.push({ msg: 'global.errors.errorUpdateConfigValue' });
	            });
			}
            AnnexaFormlyFactory.showModal('configurationModal', configurationModal, saveConfiguration, false);
    	}
		
		$scope.enable_hostname_jobs =  function(){
			var title = $linq($scope.form.fields).singleOrDefault({ templateOptions: { label: '' } }, "x => x.key == 'enable_hostname_jobs'");
            var conf = $linq(AdminFactory.configurations).singleOrDefault(undefined, "x => x.key === 'enable_hostname_jobs'");
            var confValue = JSON.parse(conf.value.replace(/\r?\n|\r/g, " "));
            var configurationModal = {
                title: title.templateOptions.label,
                icon: 'fa fa-gear',
                annexaFormly: {},
                alerts: [],
                submitModal: function () {}
            };

            var form = new AnnexaFormly();
            form.model = {};
            var keys = Object.keys(confValue);
            if(confValue && keys.length > 0){
            	_.forEach(keys, function(key){
            		if(key != "GenerateSupportingEvidenceDocuments"){
            			confValue[key] = confValue[key].toString();
            			form.addField(
                                key,
                                'annexaInputRow',
                                'col-sm-12',
                                new AnnexaFormlyFieldTemplateOptions(
                                	'text',
                                	'global.configuration.enable_hostname_jobs.'+key,
                                    false
                                ),
                                data
                           );
            		}
            	});
            }
            
            form.model = angular.copy(confValue);

            configurationModal.annexaFormly = form;

            var saveConfiguration = function() { 
            	var self = this;
            	var modelModal = this.annexaFormly.model;
            	var keysModal = Object.keys(modelModal);
            	var sendValue = angular.copy(modelModal);
            	_.forEach(keysModal, function(key){
            		var ammendKeys = [];
            		if(modelModal[key] != ""){
            			sendValue[key] = modelModal[key].split(",");
            			_.forEach(sendValue[key], function(keys){
            				ammendKeys.push(keys.trim());
            			});
            			sendValue[key] = ammendKeys;
            		}else if(modelModal[key] == ""){
            			sendValue[key] = [];
            		}
            	});
            	sendValue = JSON.stringify(sendValue)
            	AdminFactory.updateConfiguration('enable_hostname_jobs', sendValue, undefined)
	                .then(function(data) {
	                    self.close();
	                    var indexConfig = $linq(AdminFactory.configurations).indexOf("x => x.key === 'enable_hostname_jobs'");
	                    if(indexConfig != -1) {
	                    	GlobalDataFactory.configuration = [];
	                    	if(CacheFactory.get('globalDataCache') && CacheFactory.get('globalDataCache').get('configuration')){
	                        	CacheFactory.get('globalDataCache').remove('configuration')
	                        }
	                        GlobalDataFactory.loadGlobalData();
	                    }
	
	                }).catch(function (error) {
	                    self.alerts.push({ msg: 'global.errors.errorUpdateConfigValue' });
	            });
			}
            AnnexaFormlyFactory.showModal('configurationModal', configurationModal, saveConfiguration, false);
    	}
		
		$scope.password_user_lock_configuration =  function(){
			var title = $linq($scope.form.fields).singleOrDefault({ templateOptions: { label: '' } }, "x => x.key == 'password_user_lock_configuration'");
            var conf = $linq(AdminFactory.configurations).singleOrDefault(undefined, "x => x.key === 'password_user_lock_configuration'");
            var confValue = JSON.parse(conf.value.replace(/\r?\n|\r/g, " "));
            var configurationModal = {
                title: title.templateOptions.label,
                icon: 'fa fa-gear',
                annexaFormly: {},
                alerts: [],
                submitModal: function () {}
            };

            var form = new AnnexaFormly();
            form.model = {};
            var keys = Object.keys(confValue);
            if(confValue && keys.length > 0){
            	_.forEach(keys, function(key){
            		if(key == "userLocking"){
            			var internKeys = Object.keys(confValue.userLocking);
            			_.forEach(internKeys, function(internKey){
            				form.addField(
                                    'userLocking.'+internKey,
                                    'annexaInputRow',
                                    'col-sm-12',
                                    new AnnexaFormlyFieldTemplateOptions(
                                    	'number',
                                    	'global.configuration.password_user_lock_configuration.userLocking.'+internKey,
                                        false
                                    ),
                                    data
                                );	
            			});
            		}else if(key == "password"){
            			var internKeys = Object.keys(confValue.password);
            			_.forEach(internKeys, function(internKey){
            				form.addField(
                                    'password.'+internKey,
                                    'annexaInputRow',
                                    'col-sm-12',
                                    new AnnexaFormlyFieldTemplateOptions(
                                    	'number',
                                    	'global.configuration.password_user_lock_configuration.password.'+internKey,
                                        false
                                    ),
                                    data
                                );	
            			});
            		}
            	});
            }
            form.model = angular.copy(confValue);

            configurationModal.annexaFormly = form;

            var saveConfiguration = function() { 
            	var self = this;
            	var modelModal = this.annexaFormly.model;
            	var keysModal = Object.keys(modelModal);
            	var sendValue = angular.copy(modelModal);
            	_.forEach(keysModal, function(key){
           			sendValue[key] = modelModal[key];
            	});
            	sendValue = JSON.stringify(sendValue)
            	AdminFactory.updateConfiguration('password_user_lock_configuration', sendValue, undefined)
	                .then(function(data) {
	                    self.close();
	                    var indexConfig = $linq(AdminFactory.configurations).indexOf("x => x.key === 'password_user_lock_configuration'");
	                    if(indexConfig != -1) {
	                    	GlobalDataFactory.configuration = [];
	                    	if(CacheFactory.get('globalDataCache') && CacheFactory.get('globalDataCache').get('configuration')){
	                        	CacheFactory.get('globalDataCache').remove('configuration')
	                        }
	                        GlobalDataFactory.loadGlobalData();
	                    }
	
	                }).catch(function (error) {
	                    self.alerts.push({ msg: 'global.errors.errorUpdateConfigValue' });
	            });
			}
            AnnexaFormlyFactory.showModal('configurationModal', configurationModal, saveConfiguration, false);
    	}
		
		$scope.api_rest_information =  function(){
			var title = $linq($scope.form.fields).singleOrDefault({ templateOptions: { label: '' } }, "x => x.key == 'api_rest_information'");
            var conf = $linq(AdminFactory.configurations).singleOrDefault(undefined, "x => x.key === 'api_rest_information'");
            var confValue = JSON.parse(conf.value.replace(/\r?\n|\r/g, " "));
            var configurationModal = {
                title: title.templateOptions.label,
                icon: 'fa fa-gear',
                annexaFormly: {},
                alerts: [],
                submitModal: function () {}
            };
           
            var form = new AnnexaFormly();
            form.model = {};
            form.model = angular.copy(confValue);
            var keys = Object.keys(confValue);
            if(confValue && keys.length > 0){
            	_.forEach(keys, function(key){
            		if(key == "enabled"){
            			form.addField(
	    					key,
	                        'annexaRadioCheckboxRow',
	                        'col-sm-12',
	                        new AnnexaFormlyFieldSelectTemplateOptions(
	                            'global.configuration.api_rest_information.'+key,
	                            'value',
	                            'label',
	                            [{ value: true, label: $filter('translate')('global.literals.yes') }, { value: false, label: $filter('translate')('global.literals.no') }],
	                            false,
	                            'radio'
	                        ),
	                        data
                       );
            		} else if(key == "time"){
            			form.addField(
                            key,
                            'annexaInputRow',
                            'col-sm-12',
                            new AnnexaFormlyFieldTemplateOptions(
                                'number',
                                'global.configuration.api_rest_information.'+key,
                                false
                            ),
                            data
                        );
            			form.model[key] = Number(form.model[key])
            		}
            	});
            }
            
            configurationModal.annexaFormly = form;

            var saveConfiguration = function() { 
            	var self = this;
            	var modelModal = this.annexaFormly.model;
            	var keysModal = Object.keys(modelModal);
            	var sendValue = angular.copy(modelModal);
            	_.forEach(keysModal, function(key){
            		if(key == "time"){
            			sendValue[key] = modelModal[key] + '';
            		} else {
            			sendValue[key] = modelModal[key];
            		}
            	});
            	sendValue = JSON.stringify(sendValue)
            	AdminFactory.updateConfiguration('api_rest_information', sendValue, undefined)
	                .then(function(data) {
	                    self.close();
	                    var indexConfig = $linq(AdminFactory.configurations).indexOf("x => x.key === 'api_rest_information'");
	                    if(indexConfig != -1) {
	                    	GlobalDataFactory.configuration = [];
	                    	if(CacheFactory.get('globalDataCache') && CacheFactory.get('globalDataCache').get('configuration')){
	                        	CacheFactory.get('globalDataCache').remove('configuration')
	                        }
	                        GlobalDataFactory.loadGlobalData();
	                    }
	
	                }).catch(function (error) {
	                    self.alerts.push({ msg: 'global.errors.errorUpdateConfigValue' });
	            });
			}
            AnnexaFormlyFactory.showModal('configurationModal', configurationModal, saveConfiguration, false);
    	};
    	
    	$scope.enable_jobs =  function(){
			var title = $linq($scope.form.fields).singleOrDefault({ templateOptions: { label: '' } }, "x => x.key == 'enable_jobs'");
            var conf = $linq(AdminFactory.configurations).singleOrDefault(undefined, "x => x.key === 'enable_jobs'");
            var confValue = JSON.parse(conf.value.replace(/\r?\n|\r/g, " "));
            var configurationModal = {
                title: title.templateOptions.label,
                icon: 'fa fa-gear',
                annexaFormly: {},
                alerts: [],
                submitModal: function () {}
            };
           
            var form = new AnnexaFormly();
            form.model = {};
            form.model = angular.copy(confValue);
            var keys = Object.keys(confValue);
            if(confValue && keys.length > 0){
            	_.forEach(keys, function(key){
            		if(key != "GenerateSupportingEvidenceDocuments" && key != "sendMailExpiredCertificates"){
	        			form.addField(
	    					key,
	                        'annexaRadioCheckboxRow',
	                        'col-sm-12',
	                        new AnnexaFormlyFieldSelectTemplateOptions(
	                            'global.configuration.enable_jobs.'+key,
	                            'value',
	                            'label',
	                            [{ value: true, label: $filter('translate')('global.literals.yes') }, { value: false, label: $filter('translate')('global.literals.no') }],
	                            false,
	                            'radio'
	                        ),
	                        data
	                   );
            		}
            	});
            }
            
            configurationModal.annexaFormly = form;

            var saveConfiguration = function() { 
            	var self = this;
            	var modelModal = this.annexaFormly.model;
            	var keysModal = Object.keys(modelModal);
            	var sendValue = angular.copy(modelModal);
            	_.forEach(keysModal, function(key){
        			sendValue[key] = modelModal[key];
            	});
            	sendValue = JSON.stringify(sendValue)
            	AdminFactory.updateConfiguration('enable_jobs', sendValue, undefined)
	                .then(function(data) {
	                    self.close();
	                    var indexConfig = $linq(AdminFactory.configurations).indexOf("x => x.key === 'enable_jobs'");
	                    if(indexConfig != -1) {
	                    	GlobalDataFactory.configuration = [];
	                    	if(CacheFactory.get('globalDataCache') && CacheFactory.get('globalDataCache').get('configuration')){
	                        	CacheFactory.get('globalDataCache').remove('configuration')
	                        }
	                        GlobalDataFactory.loadGlobalData();
	                    }
	
	                }).catch(function (error) {
	                    self.alerts.push({ msg: 'global.errors.errorUpdateConfigValue' });
	            });
			}
            AnnexaFormlyFactory.showModal('configurationModal', configurationModal, saveConfiguration, false);
    	};
    	
    	$scope.decree_document_type_custom_field =  function(){
			var title = $linq($scope.form.fields).singleOrDefault({ templateOptions: { label: '' } }, "x => x.key == 'decree_document_type_custom_field'");
            var conf = $linq(AdminFactory.configurations).singleOrDefault(undefined, "x => x.key === 'decree_document_type_custom_field'");
            var confValue = ((conf && conf.value)?JSON.parse(conf.value.replace(/\r?\n|\r/g, " ")):{});

            var configurationModal = {
                title: title.templateOptions.label,
                icon: 'fa fa-gear',
                annexaFormly: {},
                alerts: [],
                submitModal: function () {}
            };

            var form = new AnnexaFormly();
            form.model = {};
			form.fields.push({
                key: conf.key,
                type: 'annexaComponent',
                templateOptions: {
                    type: 'annexa-decree-document'
                },
				data: {
					model: angular.copy(confValue),
				}
        	});
            form.model = angular.copy(confValue);

 

            configurationModal.annexaFormly = form;

 

            var saveConfiguration = function() { 
            	var self = this;
				if(this.annexaFormly && this.annexaFormly.fields){
					var field = $linq(this.annexaFormly.fields).firstOrDefault(undefined, "x => x.key == '"+conf.key+"'");
					if(field && field.data && field.data.model){
						var sendValue = angular.toJson(field.data.model);
		            	AdminFactory.updateConfiguration('decree_document_type_custom_field', sendValue, undefined)
			                .then(function(data) {
			                    self.close();
			                    var indexConfig = $linq(AdminFactory.configurations).indexOf("x => x.key === 'decree_document_type_custom_field'");
			                    if(indexConfig != -1) {
			                    	GlobalDataFactory.configuration = [];
			                    	if(CacheFactory.get('globalDataCache') && CacheFactory.get('globalDataCache').get('configuration')){
			                        	CacheFactory.get('globalDataCache').remove('configuration')
			                        }
			                        GlobalDataFactory.loadGlobalData();
			                    }

			                }).catch(function (error) {
			                    self.alerts.push({ msg: 'global.errors.errorUpdateConfigValue' });
			            });
					} 
				}
			}

	        AnnexaFormlyFactory.showModal('configurationModal', configurationModal, saveConfiguration, false);
    	}
    	
    	$scope.send_to_sign_documents_properties =  function(){
			var title = $linq($scope.form.fields).singleOrDefault({ templateOptions: { label: '' } }, "x => x.key == 'send_to_sign_documents_properties'");
            var conf = $linq(AdminFactory.configurations).singleOrDefault(undefined, "x => x.key === 'send_to_sign_documents_properties'");
            var confValue = ((conf && conf.value)?JSON.parse(conf.value.replace(/\r?\n|\r/g, " ")):{});
			
            var configurationModal = {
                title: title.templateOptions.label,
                icon: 'fa fa-gear',
                annexaFormly: {},
                alerts: [],
                submitModal: function () {}
            };
			
            var form = new AnnexaFormly();
            form.model = {};
			form.fields.push({
                key: conf.key,
                type: 'annexaComponent',
                templateOptions: {
                    type: 'annexa-send-to-sign-documents-config'
                },
				data: {
					model: angular.copy(confValue),
				}
        	});
            form.model = angular.copy(confValue);

            configurationModal.annexaFormly = form;

            var saveConfiguration = function() { 
            	var self = this;
				if(this.annexaFormly && this.annexaFormly.fields){
					var field = $linq(this.annexaFormly.fields).firstOrDefault(undefined, "x => x.key == '"+conf.key+"'");
					if(field && field.data && field.data.model){
						var sendValue = angular.toJson(field.data.model);
		            	AdminFactory.updateConfiguration('send_to_sign_documents_properties', sendValue, undefined)
			                .then(function(data) {
			                    self.close();
			                    var indexConfig = $linq(AdminFactory.configurations).indexOf("x => x.key === 'send_to_sign_documents_properties'");
			                    if(indexConfig != -1) {
			                    	GlobalDataFactory.configuration = [];
			                    	if(CacheFactory.get('globalDataCache') && CacheFactory.get('globalDataCache').get('configuration')){
			                        	CacheFactory.get('globalDataCache').remove('configuration')
			                        }
			                        GlobalDataFactory.loadGlobalData();
			                    }
			
			                }).catch(function (error) {
			                    self.alerts.push({ msg: 'global.errors.errorUpdateConfigValue' });
			            });
					} 
				}
			}
	
	        AnnexaFormlyFactory.showModal('configurationModal', configurationModal, saveConfiguration, false);
    	}

    	$scope.generate_agreements_from_proposals =  function(){
			var title = $linq($scope.form.fields).singleOrDefault({ templateOptions: { label: '' } }, "x => x.key == 'generate_agreements_from_proposals'");
            var conf = $linq(AdminFactory.configurations).singleOrDefault(undefined, "x => x.key === 'generate_agreements_from_proposals'");
            var confValue = ((conf && conf.value)?JSON.parse(conf.value.replace(/\r?\n|\r/g, " ")):{});
			
            var configurationModal = {
                title: title.templateOptions.label,
                icon: 'fa fa-gear',
                annexaFormly: {},
                alerts: [],
                submitModal: function () {}
            };
			
            var form = new AnnexaFormly();
            form.model = {};
            form.fields.push({
        	    key: 'active',
                type: 'annexaRadioCheckboxRow',
                className: 'col-sm-12',
                templateOptions: {
                    type: 'radio',
                    optionsAttr: 'bs-options',
                    ngOptions: 'option[to.valueProp] as option in to.options | filter: $select.search',
                    label: 'global.literals.active',
                    valueProp: 'value',
                    labelProp: 'label',
                    placeholder: '',
                    options: [],
                    required: true
                },
                data: {
                    row: true,
                    informed: true,
                    colClass: ' col-xs-12',
                    labelClass: 'label-strong'
                },
                controller: function ($scope, $filter) {
                	$scope.options.templateOptions.options = [{ value: true, label: $filter('translate')('global.literals.yes') }, { value: false, label: $filter('translate')('global.literals.no') }];
                }
            });
            form.fields.push({
            	key: 'agreementTemplateId',
                type: 'annexaSelectRow',
                className: 'col-sm-12',
                templateOptions: {
                    optionsAttr: 'bs-options',
                    ngOptions: 'option[to.valueProp] as option in to.options | filter: $select.search',
                    label: 'global.sec.literals.templateAgreementDocument',
                    valueProp: 'id',
                    labelProp: 'language1',
                    placeholder: '',
                    options: [],
                    required: true
                },
                controller: ['$scope', 'SecFactory', 'Language', '$filter', '$rootScope', 'GlobalDataFactory', function($scope, SecFactory, Language, $filter, $rootScope, GlobalDataFactory) {
                	$scope.to.labelProp = Language.getActiveColumn();
                    $scope.to.options = ((GlobalDataFactory.secTemplates && GlobalDataFactory.secTemplates.length > 0)?angular.copy(GlobalDataFactory.secTemplates):[]);
                }],
                data: {
                    row: true,
                    informed: true,
                    colClass: ' col-sm-12',
                    labelClass: 'label-strong',
                    clear: function($event,model,key, $select) {
                        $event.stopPropagation();
                        model[key] = undefined;
                        if($select) {
                            $select.selected = undefined;
                            $select.search = undefined;
                        }
                    }
                }
            });
            form.model = angular.copy(confValue);

            configurationModal.annexaFormly = form;

            var saveConfiguration = function() { 
            	var self = this;
            	var modelModal = this.annexaFormly.model;
            	var keysModal = Object.keys(modelModal);
            	var sendValue = angular.copy(modelModal);
            	_.forEach(keysModal, function(key){
        			sendValue[key] = modelModal[key];
            	});
            	sendValue = JSON.stringify(sendValue)
            	AdminFactory.updateConfiguration('generate_agreements_from_proposals', sendValue, undefined)
	                .then(function(data) {
	                    self.close();
	                    var indexConfig = $linq(AdminFactory.configurations).indexOf("x => x.key === 'generate_agreements_from_proposals'");
	                    if(indexConfig != -1) {
	                    	GlobalDataFactory.configuration = [];
	                    	if(CacheFactory.get('globalDataCache') && CacheFactory.get('globalDataCache').get('configuration')){
	                        	CacheFactory.get('globalDataCache').remove('configuration')
	                        }
	                        GlobalDataFactory.loadGlobalData();
	                    }
	
	                }).catch(function (error) {
	                    self.alerts.push({ msg: 'global.errors.errorUpdateConfigValue' });
	            });
			}
	        AnnexaFormlyFactory.showModal('configurationModal', configurationModal, saveConfiguration, false);
    	}

    	$scope.generate_certificates_background =  function(){
			var title = $linq($scope.form.fields).singleOrDefault({ templateOptions: { label: '' } }, "x => x.key == 'generate_certificates_background'");
            var conf = $linq(AdminFactory.configurations).singleOrDefault(undefined, "x => x.key === 'generate_certificates_background'");
            var confValue = ((conf && conf.value)?JSON.parse(conf.value.replace(/\r?\n|\r/g, " ")):{});
			
            var configurationModal = {
                title: title.templateOptions.label,
                icon: 'fa fa-gear',
                annexaFormly: {},
                alerts: [],
                submitModal: function () {}
            };
			
            var form = new AnnexaFormly();
            form.model = {};
            form.fields.push({
        	    key: 'active',
                type: 'annexaRadioCheckboxRow',
                className: 'col-sm-12',
                templateOptions: {
                    type: 'radio',
                    optionsAttr: 'bs-options',
                    ngOptions: 'option[to.valueProp] as option in to.options | filter: $select.search',
                    label: 'global.literals.active',
                    valueProp: 'value',
                    labelProp: 'label',
                    placeholder: '',
                    options: [],
                    required: true
                },
                data: {
                    row: true,
                    informed: true,
                    colClass: ' col-xs-12',
                    labelClass: 'label-strong'
                },
                controller: function ($scope, $filter) {
                	$scope.options.templateOptions.options = [{ value: true, label: $filter('translate')('global.literals.yes') }, { value: false, label: $filter('translate')('global.literals.no') }];
                }
            });
            form.fields.push({
            	key: 'timeToExecuteProcess',
                type: 'annexaInputRow',
                className: 'col-lg-12',
                templateOptions: {
                    required: true,
                    type: 'number',
                    label: 'global.literals.timeToExecuteProcess',
                    maxlength: 20
                },
                data: {
                    row: true,
                    informed: true,
                    colClass: ' col-lg-12',
                    labelClass: 'label-strong'
                }
            });
            form.fields.push({
            	key: 'numberCertificatesSameTime',
                type: 'annexaInputRow',
                className: 'col-lg-12',
                templateOptions: {
                    required: true,
                    type: 'number',
                    label: 'global.literals.numberCertificatesSameTime',
                    maxlength: 20
                },
                data: {
                    row: true,
                    informed: true,
                    colClass: ' col-lg-12',
                    labelClass: 'label-strong'
                }
            });
            form.model = angular.copy(confValue);

            configurationModal.annexaFormly = form;

            var saveConfiguration = function() { 
            	var self = this;
            	var modelModal = this.annexaFormly.model;
            	var keysModal = Object.keys(modelModal);
            	var sendValue = angular.copy(modelModal);
            	_.forEach(keysModal, function(key){
        			sendValue[key] = modelModal[key];
            	});
            	sendValue = JSON.stringify(sendValue)
            	AdminFactory.updateConfiguration('generate_certificates_background', sendValue, undefined)
	                .then(function(data) {
	                    self.close();
	                    var indexConfig = $linq(AdminFactory.configurations).indexOf("x => x.key === 'generate_certificates_background'");
	                    if(indexConfig != -1) {
	                    	GlobalDataFactory.configuration = [];
	                    	if(CacheFactory.get('globalDataCache') && CacheFactory.get('globalDataCache').get('configuration')){
	                        	CacheFactory.get('globalDataCache').remove('configuration')
	                        }
	                        GlobalDataFactory.loadGlobalData();
	                    }
	
	                }).catch(function (error) {
	                    self.alerts.push({ msg: 'global.errors.errorUpdateConfigValue' });
	            });
			}
	        AnnexaFormlyFactory.showModal('configurationModal', configurationModal, saveConfiguration, false);
    	}
    	
        $scope.alerts = [];

        HeaderService.onChangeState($scope, function (message) {
            if(message.state.name == 'annexa.admin.configuration') {
                $rootScope.subHeadButtons = [
                    new HeadButton('btn primary breadDivButton',undefined,'#admin-general-configuration','fa-floppy-o','global.literals.save',undefined,'saveConfiguration'),
                ];
            }
        });
        
        HeaderService.changeState($state.current);

        $scope.saveConfiguration = function() {
            $timeout(function () {
                angular.element('#smConfiguration').trigger('click');
            })
        };

        $scope.closeAlert = function(index) {
            $scope.alerts.splice(index, 1);
        };



        var getValue = function(confValue) {
            var value = '';

            if(confValue.hasOwnProperty('value')) {
                value = confValue.value;
            } else if(confValue.hasOwnProperty('id')) {
                value = confValue.id;
            }

            return value;
        }

        $scope.form = new AnnexaFormly();
        $scope.form.model = {};

        var confGroups = $linq(AdminFactory.configurationGroups).orderBy("x => x.viewOrder").toArray();

        _.forEach(confGroups, function(group) {
            $scope.form.addField(
                'GROUP' + group.id,
                'annexaTitle',
                '',
                new AnnexaFormlyFieldTemplateOptions(undefined,group[Language.getActiveColumn()])
            );


            var confs = $linq(AdminFactory.configurations).where("x => x.group.id == " + group.id + " && (x.frontendType != 'NONE' || x.frontendType != 'CUSTOM')").orderBy("x => x.viewOrder").toArray();

            _.forEach(confs, function(conf) {
                var confValue = JSON.parse(conf.value.replace(/\r?\n|\r/g, " "));

                switch (conf.frontendType) {
                    case 'TEXT':
                        $scope.form.addField(
                            conf.key,
                            'annexaInputRow',
                            'col-sm-12',
                            new AnnexaFormlyFieldTemplateOptions(
                                'text',
                                conf[Language.getActiveColumn()],
                                false
                            ),
                            data
                        );

                        $scope.form.model[conf.key] = getValue(confValue);
                        break;
                    case 'BOOLEAN':
                        $scope.form.addField(
                            conf.key,
                            'annexaRadioCheckboxRow',
                            'col-sm-12',
                            new AnnexaFormlyFieldSelectTemplateOptions(
                                conf[Language.getActiveColumn()],
                                'value',
                                'label',
                                [{ value: 'true', label: $filter('translate')('global.literals.yes') }, { value: 'false', label: $filter('translate')('global.literals.no') }],
                                false,
                                'radio'
                            ),
                            data
                        );
                        var value = getValue(confValue);

                        if(value) {
                            $scope.form.model[conf.key] = "true";
                        } else {
                            $scope.form.model[conf.key] = "false";
                        }
                        break;
                    case 'NUMBER':
                        $scope.form.addField(
                            conf.key,
                            'annexaInputRow',
                            'col-sm-12',
                            new AnnexaFormlyFieldTemplateOptions(
                                'number',
                                conf[Language.getActiveColumn()],
                                false
                            ),
                            data
                        );
                        $scope.form.model[conf.key] = getValue(confValue);
                        break;
                    case 'CUSTOM':
                        $scope.form.addField(
                            conf.key,
                            'annexaLabelButton',
                            'col-sm-12',
                            new AnnexaFormlyFieldLabelButtonTemplateOptions(
                                'text',
                                conf[Language.getActiveColumn()],
                                'global.literals.configure',
                                undefined,undefined,undefined,undefined,undefined,undefined,
                                $scope[conf.key]
                            ),
                            data
                        )
                        break;
                    case 'LANGUAGE':
                        $scope.form.addField(
                            conf.key + '1',
                            'annexaInputLanguage',
                            'col-sm-12',
                            new AnnexaFormlyFieldLanguageTemplateOptions(
                                conf.key,
                                conf.key,
                                conf[Language.getActiveColumn()],
                                'horizontal',
                                false,
                                false,
                                false,
                                $rootScope.app.languagedef
                            ),
                            data
                        )

                        $scope.form.model[conf.key + '1'] = confValue.language1;
                        $scope.form.model[conf.key + '2'] = confValue.language2;
                        $scope.form.model[conf.key + '3'] = confValue.language3;
                        break;
                }
            });
        });



        var submitConfiguration = function() {
            $scope.form.form.$setSubmitted();

            if($scope.form.form.$valid) {
                var model = $scope.form.model;
                var update = [];

                var pendingLanguage = '{';

                _.forEach(model, function(value, key) {
                    if(!key.startsWith('GROUP')) {
                        var languageKey = false;

                        if (key.endsWith('1')) {
                            pendingLanguage += '"language1":"' + value + '"';
                            languageKey = true;
                        } else if (key.endsWith('2')) {
                            pendingLanguage += ',"language2":"' + value + '"';
                            languageKey = true;
                        } else if (key.endsWith('3')) {
                            pendingLanguage += ',"language3":"' + value + '"}';
                            languageKey = true;
                        }

                        if (languageKey) {
                            key = key.substr(0, key.length - 1);
                        }

                        var conf = $linq(AdminFactory.configurations).singleOrDefault(undefined, "x => x.key == '" + key + "'");

                        if (conf) {
                            switch (conf.frontendType) {
                                case 'TEXT':
                                    conf.value = '{"value":"' + value + '"}';
                                    update.push(conf);
                                    break;
                                case 'BOOLEAN':
                                    conf.value = '{"value":' + value + '}';
                                    update.push(conf);
                                    break;
                                case 'NUMBER':
                                    conf.value = '{"value":' + value + '}';
                                    update.push(conf);
                                    break;
                                case 'LANGUAGE':
                                    if (pendingLanguage.endsWith('}')) {
                                        conf.value = pendingLanguage;
                                        update.push(conf);
                                        pendingLanguage = "{";
                                    }
                                    break;
                            }
                        }
                    }
                });

                AdminFactory.updateConfigurations(update)
                    .then(function (data) {
                        var ok = dialogs.notify(
                            '',
                            $filter('translate')('global.literals.configurationOk'),
                            {
                                animation: true,
                                backdrop: 'static',
                                keyboard: true,
                                size: ''
                            }
                        );
                        GlobalDataFactory.configuration = [];
                        if(CacheFactory.get('globalDataCache') && CacheFactory.get('globalDataCache').get('configuration')){
                        	CacheFactory.get('globalDataCache').remove('configuration')
                        }
                        GlobalDataFactory.loadGlobalData();
                    }).catch(function (error) {
                        $scope.alerts.push({ msg: 'global.errors.errorConfiguration'})
                });
            }
        };

        $scope.form.addSubmitCallback(submitConfiguration);

        //endregion

    }]);